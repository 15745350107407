import React, { useState } from 'react';
import { useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { postData } from '../api/api';
import { ACCOUNT, AMOUNTDEPOSITREQUEST } from '../helpers/Constant';
import { useNavigate } from 'react-router-dom';
import { HiOutlineArrowRight } from "react-icons/hi";
import { getUserID } from '../utils/common';
import { useTranslation } from 'react-i18next';

function PaymentSuccess() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentFail, setPaymentFail] = useState(false);

    const params = new URLSearchParams(new URL(window.location).search);
    const paymentId = params.get('payment_id');

    useEffect(() => {
        const getDepositRequestResponse = async () => {
            if (paymentId) {
                const data = {
                    userId: getUserID(),
                    paymentId: paymentId
                }
                const response = await postData(AMOUNTDEPOSITREQUEST, data);
                if (response.status === 200) {
                    const timer = setTimeout(() => {
                        setIsLoading(false);
                        setPaymentSuccess(true);
                        setPaymentFail(false);
                        return clearTimeout(timer);
                    }, 3000);
                } else {
                    setIsLoading(false);
                    setPaymentSuccess(false);
                    setPaymentFail(true);
                }
            } else {
                navigate(`/${ACCOUNT}`)
            }
        }
        getDepositRequestResponse();
    }, []);

    return (
        <div className='wrapper h-100'>
            <div className="loader d-flex flex-column justify-content-center gap-4">
                {(isLoading) &&
                    <>
                        <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                        <div className='text-center'>
                            <h5 className='mb-1 text-warning'>{t('Please wait ...')}</h5>
                            <p className='mb-0 text-white opacity-75'>{t('Your payment is under process.')}</p>
                        </div>
                    </>
                }
                {(paymentSuccess) &&
                    <div className="d-flex flex-column align-items-center justify-content-center gap-4">
                        <div className="success-icon">
                            <div className="success-icon__tip"></div>
                            <div className="success-icon__long"></div>
                        </div>
                        <h5 className='mb-0 text-warning text-center'>{t('Amount successfully')} <br /> {t('added to wallet.')}</h5>
                        <button type='button' className='go_wallet btn text-white d-flex align-items-center p-0 gap-2' onClick={() => navigate(`/${ACCOUNT}`)}><span>{t('Go to Wallet')}</span>< HiOutlineArrowRight size={16} /></button>
                    </div>
                }
                {(paymentFail) &&
                    <div className="d-flex flex-column align-items-center justify-content-center gap-4">
                        <div className="fail-icon">
                            <div className="fail-icon__tip"></div>
                            <div className="fail-icon__long"></div>
                        </div>
                        <h5 className='mb-0 text-red text-center'>{t('Your request is failed')} <br /> {t('please try again.')}</h5>
                        <button type='button' className='go_wallet btn text-white d-flex align-items-center p-0 gap-2' onClick={() => navigate(`/${ACCOUNT}`)}><span>{t('Go to Wallet')}</span>< HiOutlineArrowRight size={16} /></button>
                    </div>
                }
            </div>
        </div>
    );
}

export default PaymentSuccess;
