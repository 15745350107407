import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { CREATEPROFILE, GETTRIALINFO, GETUSERINFO, HOME } from '../helpers/Constant';
import { useNavigate } from 'react-router-dom';
import { getData, getDataWithToken } from '../api/api';
import { useTranslation } from 'react-i18next';
import DotsLoading from '../common/DotsLoading';
import StepProgressBar from '../common/stepBar';

function TargetResult() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const activeStep = 3;
    const [trailInfoHTML, setTrailInfoHTML] = useState(null);
    const [userData, setUserData] = useState(null);
    const [bmiCategory, setBmiCategory] = useState(null);

    useEffect(() => {
        const handleTrailInfo = async () => {
            const response = await getData(GETTRIALINFO);
            if (response.status === 200) {
                var value = (response.data && response.data.value) ? response.data.value : ''
                setTrailInfoHTML(value);
            } else {
                console.log(response.message);
            }
        }
        handleTrailInfo();
        const getUserData = async () => {
            const res = await getDataWithToken(GETUSERINFO);
            if (res) {
                setUserData(res);
            } else {
                setUserData(null);
                console.log(res.message);
            }
        }
        getUserData();
    }, []);

    const navigateDecision = () => {
        if (userData.mobile) {
            navigate(`/${HOME}`);
        } else {
            navigate(`/${CREATEPROFILE}`);
        }
    }

    useEffect(() => {
        if (userData !== null) {
            if (userData.bmi < 18.5) {
                setBmiCategory(t('You are Underweight'));
            } else if (userData.bmi >= 18.5 && userData.bmi < 24.9) {
                setBmiCategory('good');
            } else if (userData.bmi >= 25 && userData.bmi < 29.9) {
                setBmiCategory(t('You are Overweight'));
            } else {
                setBmiCategory(t('You are Obese'));
            }
        }
    }, [userData]);

    return (
        <div className='wrapper bg_paper h-100 px-0 pb-0'>
            <div className='d-flex gap-3 flex-column h-100'>
                <div className='px-3'>
                    <div className='d-flex justify-content-center'>
                        <StepProgressBar activeStep={activeStep} />
                    </div>
                    <div className='your_target_wrapper text-center'>
                        <h3 className='fw-bold mb-0 text-white opacity-75'>
                            {bmiCategory === null ?
                                <>
                                    {t('Calculating')}
                                    <DotsLoading />
                                </> :
                                bmiCategory !== 'good' ?
                                    bmiCategory :
                                    <span>{t('GOOD, Keep it up')}<img src='../images/thumbIcon.png' alt='Thumb Icon' /></span>
                            }
                        </h3>
                        <p className='mb-4 mt-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim</p>
                        <div className='your_target'>
                            <h5 className='text-white'>{t('Your Daily Target')}</h5>
                            <div className='border-bottom border-white opacity-25 mb-2'/>
                            <span className='text-white'><img src='../images/fireicon.svg' height={51} width={35} className='me-2' alt='Fire Icon' />{userData ? Math.round(userData.dailyTarget) : '0'}</span>
                        </div>
                    </div>
                </div>
                <div className='how_to_section p-3 d-flex flex-column'>
                    <div dangerouslySetInnerHTML={{ __html: trailInfoHTML }} />
                    <button
                        className='next_button bg-info fw-bold text-white d-block ms-auto'
                        onClick={() => navigateDecision()} >
                        <span className='me-3'>{t('Next')}</span>
                        <FaArrowRight className='position-absolute next_arrow text-white' size={14} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TargetResult;

