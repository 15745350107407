import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { getUserTrial, getUserType } from '../utils/common';
import { HOME } from '../helpers/Constant';

function Bmiroute() {
    const navigate = useNavigate();
    let checklogin = getUserTrial();
    let userType = getUserType();

    useEffect(() => {
        if (checklogin === 'true' || userType === "guest_user" ) {
            navigate(`/${HOME}`);
        }
    }, [navigate]);

    if (checklogin === 'false' && userType === "real_user") {
        return <Outlet />;
    }
}

export default Bmiroute;
