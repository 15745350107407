import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GrFormNext } from 'react-icons/gr';
import { getDataWithToken, postDataWithToken } from '../api/api';
import { GAMEUPDATESTATUS, TOURNAMENTUPDATE, TOURNAMENTJOIN, GETTOURNAMENTUSERBYID, ACCOUNT, HOME, JOINCONTESTWALLET, GETUSERACCOUNTHISTORIES } from '../helpers/Constant';
import { getUserInfo } from '../utils/common';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BackButton from '../common/backButton';

function Tournament() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [showPaymentSection, setShowPaymentSection] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pricePool, setPricePool] = useState([]);
    const [prizeAmount, setPrizeAmount] = useState(0);
    const [tournamentFee, setTournamentFee] = useState(0);
    const [tournamentStartTime, setTournamentStartTime] = useState();
    const [tournamentEndTime, setTournamentEndTime] = useState();
    const [tournamentStatus, setTournamentStatus] = useState();
    const [tournamentId, setTournamentId] = useState();
    const [isDisabled, setIsDisabeld] = useState(true);
    const [startTime, setStartTime] = useState();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [leaderBoardUser, setLeaderBoardUser] = useState();
    const [gameName, setGameName] = useState(calculateTimeLeft());
    const [isJoin, setIsJoin] = useState(false);
    const [walletPaymentErrorRes, setWalletPaymentErrorRes] = useState({});
    const [insufficientBalance, setInsufficientBalance] = useState();
    const location = useLocation();
    const tournamentIs = location.state.tournamentIs;
    const tournament = location.state.tournamentData;
    const groupGameId = localStorage.getItem('groupGameId');
    const userData = getUserInfo();

    useEffect(() => {
        async function fetchData() {
            try {
                if (tournament) {
                    checkUser();
                    setGameName(tournament.game.title);
                    setPrizeAmount(tournament.prizeAmount);
                    setTournamentFee(tournament.tournamentFee);
                    setPricePool(tournament.prizePool);
                    setTournamentId(tournament._id);
                    setTournamentStartTime(moment(tournament.startTime).format('ddd, DD/MM | hh:mm A'));
                    setTournamentEndTime(moment(tournament.endTime).format('ddd, DD/MM | hh:mm A'));
                    setStartTime(moment(tournament.startTime));
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setLoading(true);
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            if (newTimeLeft.total <= 0) {
                clearInterval(timer);
                setIsDisabeld(false);
                setTournamentStatus('Live');
                handleBack();
            } else {
                setTournamentStatus('Upcoming');
            }
            setLoading(false);
        }, 1000);

        return () => clearInterval(timer);
    }, [startTime]);

    async function checkUser() {
        const tournamentId = tournament._id;
        const tournamentUser = await getDataWithToken(GETTOURNAMENTUSERBYID + tournamentId);
        if (tournamentUser && tournamentUser.users && tournamentUser.users.length > 0) {
            tournamentUser.users.forEach((element, index) => {
                if (element.user === userData._id) {
                    setIsJoin(true);
                    return true;
                }
            })
            setLeaderBoardUser(tournamentUser.users);
        }
    }
    function calculateTimeLeft() {
        const now = new Date().getTime();
        const difference = startTime - now;

        if (difference <= 0) {
            return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const seconds = Math.floor((difference / 1000) % 60);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));

        return { total: difference, days, hours, minutes, seconds };
    }

    const handlePayClick = () => {
        setShowPaymentSection(true);
    }

    const handleBack = () => {
        setShowPaymentSection(false);
        setWalletPaymentErrorRes({});
    }

    function getPositionSuffix(position) {
        const suffixes = ['st', 'nd', 'rd'];
        const remainder = position % 10;

        if (position === 11 || position === 12 || position === 13) {
            return `${position}th`;
        }
        return `${position}${suffixes[remainder - 1] || 'th'}`;
    }

    const handleGoBurnClick = async () => {
        setLoading(true);
        // Group Status Update
        var data = {
            "gameId": groupGameId,
            "status": 'Playing'
        }
        await postDataWithToken(GAMEUPDATESTATUS, data);
        window.location.href = `uniwebview://openGame?name=${gameName}`;
    }

    // End of the Game
    window.onGameClosed = async function (score, time, heartbeat, accuracy) {
        handleGameClosed(score);
    };
    const handleGameClosed = async (score, time, heartbeat, accuracy) => {
        try {
            //  Tournament Score Update
            const payloadTournamentData = {
                tournamentId: tournament._id,
                user: {
                    id: userData._id,
                    burnedCal: score
                }
            }
            await postDataWithToken(TOURNAMENTUPDATE, payloadTournamentData);

            // Group Status Update
            var data = {
                "gameId": groupGameId,
                "status": 'Online'
            }
            await postDataWithToken(GAMEUPDATESTATUS, data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const walletPaymentWithJoin = async () => {
        const data = { amount: tournamentFee, tournamentId: tournament._id }
        const response = await postDataWithToken(JOINCONTESTWALLET, data);
        if (response.status == 200) {
            handleJoinTournament();
        } else {
            setWalletPaymentErrorRes(response);
        }
    }

    const handleJoinTournament = async () => {
        setLoading(true);
        var data = {
            "tournamentId": tournament._id
        }
        await postDataWithToken(TOURNAMENTJOIN, data);
        checkUser();
        handleBack();
        setLoading(false);
    }

    const handleInsufficientResponse = async () => {
        const userAccounts = await postDataWithToken(GETUSERACCOUNTHISTORIES);
        if (userAccounts.status == 200) {
            const data = userAccounts['data'];
            if (data.length > 0) {
                let balance = tournamentFee - data[0].realAmount;
                setInsufficientBalance(balance);
            }
        }
    }

    useEffect(() => {
        if (tournamentIs === 'paid' && walletPaymentErrorRes.status === 422) {
            handleInsufficientResponse();
        }
    }, [walletPaymentErrorRes]);

    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            {!showPaymentSection ? <BackButton label={t('Home')} className="position-relative" closeFunction={() => navigate(`/${HOME}`)} /> : <BackButton label={t('Tournament')} className="position-relative" closeFunction={handleBack} />}
            <div className='tournamentpaid_wrapper'>
                <div className='buddy_tournament_wrapper'>
                    <h3 className='fw-bold text-white opacity-75 my-2 pb-1 text-capitalize'>{t('Tournament')} ({tournamentIs === 'paid' ? t('paid') : t('free')})</h3>
                    <div className='prize_pool text-center bg-secondary mb-4 p-2'>
                        <p className='mb-1 text-white opacity-75'>{t('Prize Pool')}</p>
                        <h2 className='text-white mb-0 fw-bold'>${prizeAmount}</h2>
                    </div>
                </div>
                <div className='tournament_info'>
                    <h4 className='text-white pb-1 opacity-75'>{t('Tournament time info')}</h4>
                    <div className='p-3 px-2 bg-secondary text-center tournament_status mb-4'>
                        <p className='text-warning mb-2'>{tournamentStartTime} <span className='px-1'>-</span> {tournamentEndTime}</p>
                        {
                            tournamentStatus === 'Live' ?
                                <p className="mb-0 position-relative status">{t('Live')}</p>
                                : <div id="countdown">
                                    <span className='text-white mb-0'>{isNaN(timeLeft.days) ? '0' : timeLeft.days} <span className='me-1'>day</span> </span>
                                    <span className='text-white mb-0'>{isNaN(timeLeft.hours) ? '0' : timeLeft.hours} <span className='me-1'>hr</span> </span>
                                    <span className='text-white mb-0'>{isNaN(timeLeft.minutes) ? '0' : timeLeft.minutes} <span className='me-1'>min</span> </span>
                                    <span className='text-white mb-0'>{isNaN(timeLeft.seconds) ? '0' : timeLeft.seconds} <span>sec</span> </span>
                                </div>
                        }

                    </div>
                </div>
                <div className='tournament_info'>
                    <h4 className='text-white pb-1 opacity-75'>{t('Tournament info')}</h4>
                    <div className="tabs bg-transparent mb-0 mw-100">
                        <button className={`tab rounded-3 border-0 ${activeTab === 0 ? 'active' : 'text-white bg-transparent '}`} onClick={() => setActiveTab(0)}>{t('Winnings')}</button>
                        <button className={`tab rounded-3 border-0 ${activeTab === 1 ? 'active' : 'text-white bg-transparent'}`} onClick={() => setActiveTab(1)} disabled={((tournamentIs === 'paid' && isJoin) || (isJoin)) ? false : true}>{t('Leaderboard')}</button>
                    </div>
                    <ul className='place_list pt-2'>
                        {(activeTab === 0) &&
                            <li className='d-flex align-items-center justify-content-between px-3'>
                                <p className='mb-0 text-white opacity-50'>{t('Rank')}</p>
                                <p className='mb-0 text-white opacity-50'>{t('Winnings')}</p>
                            </li>}
                        {(activeTab === 0) && pricePool.map((place, index) => {
                            return (
                                <li key={index} className="item d-flex align-items-center justify-content-between">
                                    <p className='mb-0 text-white opacity-75'>{place.startRank === place.endRank ? getPositionSuffix(place.startRank) + " " : getPositionSuffix(place.startRank) + ' - ' + getPositionSuffix(place.endRank) + " "}place</p>
                                    <p className='mb-0 fs-6 text-warning fw-bold'>${place.winningAmount}</p>
                                </li>
                            )
                        })}

                        {(activeTab === 1) &&
                            <li className='d-flex align-items-center justify-content-between gap-2 mb-2 px-3'>
                                <div className='w-50'>
                                    <p className='mb-0 text-white opacity-50'>{t('Players')}</p>
                                </div>
                                {
                                    (tournamentStatus === 'Live') &&
                                    <div className='d-flex align-items-center justify-content-end gap-2 w-50 numbers_data'>
                                        <p className="mb-0 text-white opacity-50">{t('Burn Cal.')}</p>
                                        <p className="mb-0 text-white opacity-50">{t('Rank')}</p>
                                    </ div>
                                }
                            </li>}
                        {(activeTab === 1 && leaderBoardUser) && leaderBoardUser.map((player, index) => {
                            return (
                                <li key={index} className="item d-flex align-items-center justify-content-between gap-2">
                                    <div className='w-50'>
                                        <p className='mb-0 text-white opacity-75'>{player.name}</p>
                                    </div>
                                    {
                                        (tournamentStatus === 'Live') &&
                                        <div className='d-flex align-items-center justify-content-end gap-2 w-50 numbers_data'>
                                            <p className="mb-0 text-warning fs-6">{player.burnedCal}</p>
                                            <p className="mb-0 text-warning fs-6">{player.rank === null ? 0 : player.rank}</p>
                                        </ div>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {(tournamentStatus !== 'Live') &&
                    (<button type='button'
                        className='mx-auto cta_button bg-warning d-flex align-items-center justify-content-center position-relative border-0'
                        disabled={(!isJoin) ? false : isDisabled}
                        onClick={(isJoin) ? handleGoBurnClick : handlePayClick}>
                        <span
                            className='text-dark fw-bold'> {(isJoin) ? t('Go Burn') : t('Join Tournament')}
                        </span>
                        <GrFormNext className='next-icon' />
                    </button>)}
            </div>

            {(showPaymentSection) && (
                <div className={`modal ${showPaymentSection ? 'show' : ''}`} style={{ display: showPaymentSection ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content modal_content confirm_modal p-3">
                            <div className="modal-body p-0">
                                {(tournamentIs === 'paid' && walletPaymentErrorRes.status === 422) ?
                                    <div className='my-3 pb-3'>
                                        <h5 className='text-white text-center mb-3'>{t('Insufficient balance')}</h5>
                                        <p className='text-white text-center mb-0'>
                                            <span className='opacity-50'>{t('You need to add')}</span>
                                            <span> {`$${insufficientBalance}`} </span>
                                            <span className='opacity-50'>{t('in your wallet for join this tournament.')}</span>
                                        </p>
                                    </div>
                                    :
                                    <h5 className='text-white text-center my-3 pb-3'>{`${t('Are you sure to join this')} ${tournamentIs === "paid" ? t("paid") : t("free")} ${t("tournament?")}`}</h5>
                                }

                                <div className='payment_btn d-flex justify-content-center mb-3 gap-3'>
                                    <button type='button' className='cta_button bg-info d-flex align-items-center justify-content-center position-relative border-0' onClick={handleBack}>
                                        <span className='fw-bold text-white'>{t('Cancel')}</span>
                                    </button>
                                    {tournamentIs === "paid" ?
                                        (tournamentIs === "paid" && walletPaymentErrorRes.status === 422) ?
                                            <button type='button' className='cta_button bg-warning d-flex align-items-center justify-content-center position-relative border-0' onClick={() => { navigate(`/${ACCOUNT}`, { state: { tournamentIs: true, amount: insufficientBalance } }); }}>
                                                <span className='fw-bold'>{t('Go to Wallet')}</span>
                                            </button> :
                                            <button type='button' className='cta_button bg-warning d-flex align-items-center justify-content-center position-relative border-0' onClick={() => walletPaymentWithJoin()}>
                                                <span className='fw-bold'>{`${t('Pay')} $${tournamentFee}`}</span>
                                            </button> :
                                        <button type='button' className='cta_button bg-warning d-flex align-items-center justify-content-center position-relative border-0' onClick={() => handleJoinTournament()}>
                                            <span className='fw-bold'>{t('Yes')}</span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )};
            {(showPaymentSection) && <div className="modal-backdrop show"></div>}
        </div >
    );
}

export default Tournament;