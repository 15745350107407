import React from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function BackButton({ label, closeFunction ,className }) {
    const navigate = useNavigate();

    function handleClick() {
        if (closeFunction) {
            closeFunction();
        } else {
            navigate(-1);
        }
    }
    return (
        <div className={`screenBackBtn ${className ? className : ''}`}>
            <button onClick={handleClick}><MdOutlineArrowBackIosNew /></button>
            {
                label ? <span className='ms-2'>{label}</span> : null
            }
        </div>
    );
}

export default BackButton;
