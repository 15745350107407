import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserType, getUserInfo } from '../utils/common';
import { getDataWithToken, postDataWithToken } from '../api/api';
import { VERSUSFINDPLAYER, GAMEUPDATESTATUS, GETGAMES, VERSUSCANCEL, HOME, VERSUSSCOREUPDATE, GETVERSESGAME, GAMEUSER } from '../helpers/Constant';
import { Oval } from 'react-loader-spinner';
import { FaCrown } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import BackButton from '../common/backButton';
import DotsLoading from '../common/DotsLoading';


function Versus() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [playerData, setPlayerData] = useState();
    const [gamesData, setGameData] = useState([]);
    const [showGameModal, setShowGameModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [game, setGame] = useState('');
    const [gameLevel, setGameLevel] = useState('');
    const [isCompleted, setIsCompleted] = useState();
    const [versesUsers, setVersesUsers] = useState([]);

    const IMAGE_API_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/games/`;
    const AVTAR_IMAGE_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/avtar/`;
    const userType = getUserType();
    const userData = getUserInfo();
    const groupGameId = localStorage.getItem("groupGameId");
    let isResult = false;

    const fetchGameData = async () => {
        const response = await getDataWithToken(GETGAMES);
        setGameData(response);
        setShowGameModal(true);
    }

    const updateGroupStatus = async (groupGameId, status) => {
        const data = { gameId: groupGameId, status: status };
        await postDataWithToken(GAMEUPDATESTATUS, data);
    };

    const updateGroupUserAvatars = async (groupsUser) => {
        const userIds = groupsUser.map(obj => obj.user);
        const userGroupsAvtar = await postDataWithToken(GAMEUSER, { users: userIds });
        if (userGroupsAvtar.status === 200 && userGroupsAvtar.data.length > 0) {
            for (const element of userGroupsAvtar.data) {
                const avtarIndex = groupsUser.findIndex(ele => ele.user === element._id.toString());
                if (avtarIndex > -1) {
                    const userAvtar = { avtar: element.avtar };
                    Object.assign(groupsUser[avtarIndex], userAvtar);
                }
            }
        }
        return groupsUser;
    };

    const fetchVersesGameData = async () => {
        try {
            const versesId = localStorage.getItem('versesId');
            if (versesId) {
                const versesUserData = await getDataWithToken(GETVERSESGAME + versesId);
                if (versesUserData) {
                    const users = versesUserData['users'];
                    const isCompleted = users.every(player => player.hasOwnProperty('status') && player.status === "Completed");

                    if (!versesUserData.isCompleted && isCompleted) {
                        const versesData = {
                            versusGameId: versesId,
                            isCompleted: isCompleted
                        }
                        await postDataWithToken(VERSUSSCOREUPDATE, versesData);
                    }

                    const finderPlayerIndex = users.findIndex(ele => ele.user !== userData._id.toString());
                    var finderPlayer = users[finderPlayerIndex];

                    const userAvtar = await postDataWithToken(GAMEUSER, { users: finderPlayer.user });
                    if (userAvtar.status === 200 && userAvtar.data.length > 0) {
                        const avtar = userAvtar.data[0].avtar;
                        Object.assign(finderPlayer, { avtar: avtar });
                    }
                    setPlayerData(finderPlayer);
                    setIsCompleted(isCompleted);

                    if (isCompleted) {
                        const highestScore = users.reduce((prevUser, currentUser) => {
                            return currentUser.burnedCal > prevUser.burnedCal ? currentUser : prevUser;
                        }, users[0]);

                        const updatedUsers = users.map(user => ({
                            ...user,
                            highest: user === highestScore,
                        }));
                        const avtarPlayerUser = await updateGroupUserAvatars(updatedUsers);
                        setVersesUsers(avtarPlayerUser);
                        localStorage.removeItem('versesId');
                        isResult = true;
                    }
                }
            } else {
                fetchGameData();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchVersesGameData();
        const intervalId = setInterval(function () {
            const versesId = localStorage.getItem('versesId');

            if (versesId && !isResult) {
                fetchVersesGameData();
            } else if (isResult) {
                return () => {
                    clearInterval(intervalId);
                };
            }
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const findPlayer = async (gameId, gameLevel, gameTitle) => {
        try {
            closeGameModal();
            if (gameId) {
                setGame(gameId);
                setGameLevel(gameLevel);

                var gameData = {
                    "game": gameId
                };

                const playerData = await postDataWithToken(VERSUSFINDPLAYER, gameData);
                if (playerData.status === 200) {
                    if (playerData.data) {
                        const player = playerData.data.users;
                        const finderPlayerIndex = player.findIndex(ele => ele.user !== userData._id.toString());
                        var finderPlayer = playerData.data.users[finderPlayerIndex];
                        setPlayerData(finderPlayer);
                        localStorage.setItem('versesId', playerData.data._id);
                    }
                    setTimeout(() => {
                        handleGoBurn(gameTitle);
                    }, 3000);
                } else {
                    console.log(playerData.message);
                }
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    const handleGoBurn = async (gameTitle) => {

        setLoading(true);
        await updateGroupStatus(groupGameId, 'Playing');
        handleGameClosed(20, 10, 50, 30, 45);
        // window.location.href = `uniwebview://openGame?name=${gameTitle}`;
    }

    // window.onGameClosed = async function (score, time, heartbeat, accuracy) {
    //     handleGameClosed(score);
    // };

    const handleGameClosed = async (score , time, calories, heartbeat, accuracy) => {
        try {
            var users = [];
            const versesId = localStorage.getItem('versesId');

            if (versesId) {
                const versesUserData = await getDataWithToken(GETVERSESGAME + versesId);
                versesUserData['users'].forEach((user) => {
                    let playerScore;
                    let status = 'Ready';
                    if (user.user === null) {
                        var gameLevel = document.getElementById('gameLevel').value;
                        if (gameLevel == 'Bronze' || gameLevel == 'Copper') {
                            const min = Math.ceil(0);
                            const max = Math.floor(score - 1);
                            playerScore = Math.floor(Math.random() * (max - min + 1)) + min;
                        } else {
                            const range = 50;
                            const min = Math.max(0, score - range);
                            const max = score + range;
                            playerScore = Math.floor(Math.random() * (max - min + 1)) + min;
                        }
                        status = 'Completed';
                    } else if (user.user === userData._id) {
                        playerScore = score;
                        status = 'Completed';
                    }

                    if (playerScore) {
                        users.push({
                            'id': user._id,
                            "score": score,
                            'burnedCal': calories,
                            "accuracy" : accuracy,
                            "time" : time,
                            "heartbeat" : heartbeat,
                            'status': status
                        });
                    }
                });

                const versesData = {
                    versusGameId: versesId,
                    users: users
                }
                await postDataWithToken(VERSUSSCOREUPDATE, versesData);

                // Group Status Update
                await updateGroupStatus(groupGameId, 'Online');
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const closeGameModal = () => {
        setShowGameModal(false);
    };

    const handleButtonClick = async () => {
        if (game !== null) {
            const data = {
                "game": game
            };

            await postDataWithToken(VERSUSCANCEL, data);
        }
    };

    return (
        <div className='wrapper position-relative h-100 versus_main_wrapper'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}

            {showGameModal ?
                <BackButton label={t('Home')} />
                : ""}

            <div className='d-flex flex-column justify-content-between h-100 gap-4'>
                <div className='versus_info_wrapper pt-4'>
                    {playerData ? (
                        <h5 className='fw-bold m-auto w-75 text-center text-white opacity-75'>{t('You are matched with your opponent')}<DotsLoading width={18} /></h5>
                    ) : (
                        <>
                            <h3 className='fw-bold text-white opacity-75'>{t('Versus game')}</h3>
                            <p className='m-0 text-white opacity-50'>
                                The standard Lorem Ipsum passage, used since the 1500s"Lorem ipsum dolor sit amet, consectetur adipiscing elit, The standard Ipsum passage, used since the 1500s
                            </p>
                            <img src='../images/versus1V1.png' alt='1v1' className='position-absolute top-0 end-0 pt-2 pe-2' />
                        </>
                    )}
                </div>
                <div className='versus_wrapper position-relative mx-auto'>
                    <img src='../images/versusbackground.png' className='w-100' alt='Versus tournament' />
                    <div className='player position-absolute top-0 start-0'>
                        {userData && userData.avtar ?
                            <div className='img_box d-flex justify-content-center align-items-center rounded-circle bg-white'>
                                <img src={AVTAR_IMAGE_URL + userData._id + "/" + userData.avtar} alt='Profile' className='h-100 w-100' />
                            </div>
                            :
                            <div className='img_box d-flex justify-content-center align-items-center versus_avtar_alternate rounded-circle'>
                                <h6 className='mb-0 text-white fw-bold text-uppercase'>
                                    {userData.fname[0] + userData.lname[0]}
                                </h6>
                            </div>
                        }
                        <h5 className='text-white mb-0 mt-2 position-absolute start-50 text-nowrap top-100 fs-6'>{(userType) ? (userType === 'real_user' ? userData.fname + ' ' + userData.lname : 'Guest') : ''}</h5>
                    </div>
                    <div className='player player_invited position-absolute bottom-0 end-0 mt-0 ms-0'>
                        {playerData ? playerData.avtar ?
                            <div className='img_box d-flex justify-content-center align-items-center bg-white rounded-circle opponent_founded'>
                                <img src={AVTAR_IMAGE_URL + playerData.user + "/" + playerData.avtar} alt='Profile' className='h-100 w-100' />
                            </div>
                            :
                            <div className='img_box d-flex justify-content-center align-items-center versus_avtar_alternate rounded-circle opponent_founded'>
                                <h6 className='mb-0 text-white fw-bold text-uppercase'>
                                    {playerData.user !== null ? playerData.name.split(" ").slice(0, 2).map(word => word[0].toUpperCase()).join("") : 'P'}
                                </h6>
                            </div>
                            :
                            <div className='img_box d-flex justify-content-center align-items-center rounded-circle'>
                                <img src='../images/opponent-finding-icon.svg' alt='Opponent finding' className='h-100 w-100' />
                            </div>}
                        <h5 className={`text-white mb-0 position-absolute start-50 text-nowrap top-100 fs-6 ${playerData ? 'mt-2' : 'mt-4 pt-3'}`}>{playerData ? playerData.name : <DotsLoading className={'ms-0'} width={'20'} />}</h5>
                    </div>
                </div>
                <div className='loading_text text-center'>
                    <h5 className='mb-0 fw-bold text-warning fs-6'>
                        {(isCompleted === false) ? t('Waiting for result') : playerData ? t('Finding Game') : t('Finding Opponent')}
                        <DotsLoading color={'#daff00'} />
                    </h5>
                    {!playerData ? <button type='button' className='cta_button bg-danger border-0 mx-auto text-white fw-bold mt-4 w-75' onClick={() => { handleButtonClick(); navigate(`/${HOME}`); }}>{t('Cancel')}</button> : ''}
                </div>
            </div>
            {/* GAME RESULT  */}
            <div className={`modal ${isCompleted ? 'show' : ''}`} style={{ display: isCompleted ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className='members_list px-4 py-3'>
                        {
                            versesUsers.map((user, index) => {
                                return (
                                    <div key={user._id} className={`item d-flex align-items-center gap-2 ${user.highest ? 'toper_user' : ''}`}>
                                        <div className='member_profile rounded-circle position-relative'>
                                            {user.avtar ? <img src={AVTAR_IMAGE_URL + user.user + "/" + user.avtar} alt='Profile' className='rounded-circle h-100 w-100 ' />
                                                : <h6 className='text-white mb-0'>{user.user !== null ? user.name.split(" ").slice(0, 2).map(word => word[0].toUpperCase()).join("") : 'P'}</h6>
                                            }
                                        </div>
                                        <p className='mb-0 text-white user_name'>{user.name}</p>
                                        <p className='mb-0 ms-auto user_status'>{user.burnedCal}</p>
                                        {(user.highest) ? <FaCrown className='position-absolute' /> : ''}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {/* GAME modal */}
            <div className={`modal ${showGameModal ? 'show' : ''}`} style={{ display: showGameModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content tournament_modal">
                        <div className="modal-body p-3">
                            <h3 className='mb-3 fw-bold text-white'>{t('Game List')}</h3>
                            {gamesData.map((game, index) => (
                                userData != null && userData.levelData != null && ((userData.levelData.current == 'Copper' && game.minlevel === 'Copper') || (userData.levelData.current === 'Bronze' && (game.minlevel === 'Copper' || game.minlevel === 'Bronze')) || (userData.levelData.current === 'Silver' && (game.minlevel !== 'Gold' || game.minlevel !== 'Platinum')) || (userData.levelData.current === 'Gold' && game.minlevel !== 'Platinum') || (userData.levelData.current === 'Platinum')) ?
                                    <div key={game._id} className={`d-flex game_wrapper align-items-start justify-content-end flex-wrap px-0 ${index + 1 < gamesData.length ? '' : 'border-0'}`}>
                                        <img src={(IMAGE_API_URL + game.image)} alt="Logo" />
                                        <div className='game_info w-100'>
                                            <h5 className='mb-0 text-capitalize'>{game.title}</h5>
                                            <h6 className={`text-white mb-1
                                            ${(game.minlevel === 'Bronze' || game.minlevel === 'Copper') ? 'verus_tournament'
                                                    : game.minlevel === 'Silver' ? 'paid_tournament'
                                                        : game.minlevel === 'Gold' ? 'free_tornament'
                                                            : game.minlevel === 'Platinum' ? 'achievement_tournament'
                                                                : 'bg-primary'}`}>{t(game.minlevel)}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: game.description }}></div>
                                        </div>
                                        <div className='play_button_Wrapper'>
                                            <button type='button' onClick={() => findPlayer(game._id, game.minlevel, game.title)}>{t('Play')}< IoIosArrowForward /></button>
                                        </div>
                                    </div>
                                    : ''
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <input type='hidden' id='gameLevel' value={gameLevel} />
            {(showGameModal || isCompleted) && <div className="modal-backdrop show"></div>}
            {/* <button className='justify-content-center w-100 mt-3 fs-6 fw-bold border-0' onClick={handleGoBurn}>submit</button> */}
        </div>
    );
}

export default Versus;