// StepProgressBar.js
import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';

const StepProgressBar = ({ activeStep, pendingStep }) => {
  const stepPercentages = [33.33, 66.66, 99.99, 100];

  return (
    <div className="step-progress-bar border bg-primary w-100">
      <ProgressBar percent={stepPercentages[activeStep - 1]}>
        {stepPercentages.map((percentage, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div className={`step ${accomplished ? 'active' : ''} ${index === pendingStep - 1 ? 'pending' : ''} d-flex align-items-center justify-content-center`}>
                {index !== 0 && <div className="line"></div>}
                <div className="dot rounded-circle"></div>
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};

export default StepProgressBar;