import React, { useState, useEffect } from 'react';
import StepProgressBar from '../common/stepBar';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { RiTimerLine } from 'react-icons/ri';
import { AiOutlineAim } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ADDTRAILASSESMENTDATA, TARGETRESULT } from '../helpers/Constant';
import { getUserID } from '../utils/common';
import { postDataWithToken } from '../api/api';
import { FaAngleRight } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

function TraillAssessment() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [totalTime, setTimeLeft] = useState(1200);
    const [showTrailModal, setShowTrailModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [trialAssessment, setTrialAssessment] = useState({});
    const [trailData, setTrailData] = useState({
        labels: Array.from({ length: 20 }, (_, index) => index + 1),
        datasets: [
            {
                data: [],
                borderRadius: 20,
                borderSkipped: false,
            },
        ],
    });
    const activeStep = 2;
    const pendingStep = activeStep + 1;

    useEffect(() => {
        handleStartGame();
    }, []);

    const handleStartGame = async () => {
        window.location.href = "uniwebview://openGame?name=trial";
    }

    window.onGameClosed = async function (score, time, heartbeat, accuracy) {
        const setTrialAssessmentData = {
            'accuracy': accuracy,
            'burnedCal': score,
            'heartbeat': heartbeat,
            'assesmenttime': time
        }
        setTrialAssessment(setTrialAssessmentData);
        setShowTrailModal(true);
    };
    const goToDailyTarget = async () => {
        const userId = getUserID();
        let data = {
            "user": userId,
            "burnedCal": (trialAssessment.burnedCal).toString(),
            "accuracy": (trialAssessment.accuracy).toString(),
            "assesmenttime": (trialAssessment.assesmenttime).toString(),
            "missedcount": (trialAssessment.heartbeat).toString(),
        }

        try {
            const response = await postDataWithToken(ADDTRAILASSESMENTDATA, data);
            if (response.status === 200) {
                setShowTrailModal(false);
                navigate(`/${TARGETRESULT}`);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const imageUrls = [
        '../images/trailassesmentImage.png',
        '../images/trailassesmentImage.png',
    ];

    const formatTime = (seconds) => {
        const minutes = Math.floor(parseFloat(seconds) / 60);
        const secondsRemaining = seconds % 60;
        return `${minutes}:${secondsRemaining < 10 ? '0' : ''}${secondsRemaining}`;
    };

    return (
        <div className='wrapper bg_paper px-0 pb-0 h-100'>
            <div className='d-flex flex-column h-100'>
                <div className='px-3'>
                    <div className='d-flex justify-content-center'>
                        <StepProgressBar activeStep={activeStep} pendingStep={pendingStep} />
                    </div>
                    <h6 className='trail_title text-white fw-bold mt-3'>{t('Trail Assessment')}</h6>
                    <div className='image_wrapper text-center'>
                        <img src={imageUrls[currentImageIndex]} className="h-100 w-100" alt="heroimage" />
                    </div>
                </div>
                <div className='trail_result p-3 pb-4'>
                    <div className='trail_result_inner p-2 pb-3 mb-2 rounded-4'>
                        <div className='d-flex px-2 text-white justify-content-between align-items-center'>
                            <div className='content_wrapper d-flex gap-1 align-items-center'>
                                <RiTimerLine color='#888A99' size={26} />
                                <h2 className='mb-0 text-whtie fw-bold'>{formatTime(totalTime)}</h2>
                            </div>
                            <h1 className='fw-bold mb-0'>{t('GOOD')}</h1>
                            <div className='content_wrapper d-flex gap-1 align-items-center'>
                                <AiOutlineAim color='#888A99' size={26} />
                                <h2 className='mb-0 text-white fw-bold'>{(trialAssessment && trialAssessment.accuracy) ? trialAssessment.accuracy : 0}%</h2>
                            </div>
                        </div>
                        <div className='trail_chart mt-1'>
                            <Bar data={trailData} options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltips: {
                                        enabled: false,
                                    },
                                },
                                elements: {
                                    bar: {
                                        backgroundColor: (context) => {
                                            const accuracyPercentage = trailData.datasets[0].data[context.dataIndex];
                                            if (accuracyPercentage < 33) {
                                                return '#F55A68';
                                            } else if (accuracyPercentage > 66) {
                                                return '#10FF88';
                                            } else {
                                                return '#FFD35D';
                                            }
                                        },
                                    },
                                },
                                scales: {
                                    y: {
                                        display: false,
                                        ticks: {
                                            display: false
                                        }
                                    },
                                    x: {
                                        display: false,
                                        ticks: {
                                            display: false
                                        }
                                    }
                                },
                            }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* trail modal */}
            <div className={`modal ${showTrailModal ? 'show' : ''}`} style={{ display: showTrailModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered trail_modal m-auto">
                    <div className="modal-content trail_modal_content">
                        <div className="modal-header py-3 justify-content-center border-0">
                            <h5 className="modal-title fw-bold" id="exampleModalLabel">{t('Trail assessment Result')}</h5>
                        </div>
                        <div className="modal-body trail_modal_body py-0">
                            <ul className='d-flex justify-content-center px-3 py-2 m-0'>
                                <li className='col-4 text-center'>
                                    <h4 className='my-2'>{t('Timer')}</h4>
                                    <h2 className='mb-2'>{(trialAssessment && trialAssessment.assesmenttime) ? formatTime(trialAssessment.assesmenttime) : 0}{(trialAssessment && trialAssessment.assesmenttime && trialAssessment.assesmenttime < 300) ? <IoIosArrowDown /> : (trialAssessment && trialAssessment.assesmenttime) ? <IoIosArrowUp /> : null}</h2>
                                </li>
                                <li className='col-4 text-center'>
                                    <h4 className='my-2'>{t('Accuracy')}</h4>
                                    <h2 className='mb-2'>{(trialAssessment && trialAssessment.accuracy) ? trialAssessment.accuracy : 0}%{(trialAssessment && trialAssessment.accuracy && trialAssessment.accuracy < 50) ? <IoIosArrowDown /> : (trialAssessment && trialAssessment.accuracy) ? <IoIosArrowUp /> : null}</h2>
                                </li>
                                <li className='col-4 text-center'>
                                    <h4 className='my-2'>{t('Burned')}</h4>
                                    <h2 className='mb-2 arrow_down'>{(trialAssessment && trialAssessment.burnedCal) ? trialAssessment.burnedCal : 0}{(trialAssessment && trialAssessment.burnedCal) ? trialAssessment.burnedCal : 0}%{(trialAssessment && trialAssessment.burnedCal && trialAssessment.burnedCal < 800) ? <IoIosArrowDown /> : (trialAssessment && trialAssessment.burnedCal) ? <IoIosArrowUp /> : null}</h2>
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer border-0 p-4 pe-3 align-items-center justify-content-between">
                            <div className='d-flex align-items-center m-0'>
                                <span className='ring_loading small'></span>
                                <p className='m-0 ms-3 ps-1'>{t('Calculating your daily target')}</p>
                            </div>
                            <button onClick={goToDailyTarget} className='bg-transparent p-0 m-0 border-0 fw-bold d-flex align-items-center'><span className='d-inline'>{t('Go')}</span><FaAngleRight /></button>
                        </div>
                    </div>
                </div>
            </div>
            {showTrailModal && <div className="modal-backdrop show"></div>}
        </div>
    )
}

export default TraillAssessment;