import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserType, setSession, getLanguage, getSyncapp } from '../utils/common';
import { postDataWithToken, getData, getSyncDataWithToken } from '../api/api';
import { DELETE, LOGIN, LOGOUT, UPDATEUSERDETAIL, GOOGLEFITAUTH, GOOGLEFITDATA, FITBITAUTH, FITBITDATA, STARVADATA, STARVAAUTH,GARMINAUTH, GARMINDATA } from '../helpers/Constant';
import { IoChevronDown } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import Bottombar from '../common/bottombar';
import BackButton from '../common/backButton';
import { Oval } from 'react-loader-spinner';

function Setting() {
    const { t, i18n } = useTranslation();
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [showFAQModal, setShowFAQModal] = useState(false);
    const [userType, setUserType] = useState(null);
    const [userLanguage, setUserLanguage] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [dataFetched, setDataFetched] = useState(false);

    const languageMapping = {
        'English': 'en',
        'Tamil': 'ta',
        'Telugu': 'te',
        'Malayalam': 'ml',
        'Hindi': 'hi',
        'Bengali': 'bn',
    };

    const changeLanguage = async (newLang) => {
        i18n.changeLanguage(newLang);

        let data = {
            "lng": newLang,
        }
        const response = await postDataWithToken(UPDATEUSERDETAIL, data);
        if (response.status === 200) {
            setUserLanguage(response);
        } else {
            console.log(response.message);
        }
    };

    useEffect(() => {
        const type = getUserType();
        setUserType(type);
        const language = getLanguage();
        setUserLanguage(language);
    }, []);

    const closeLogoutModal = () => {
        setShowLogoutModal(false);
    };

    const closeDeleteAccountModal = () => {
        setShowDeleteAccountModal(false);
    };

    const handleLogoutAuth = async () => {
        const userType = getUserType();
        if (userType === 'guest_user') {
            setSession();
            setShowLogoutModal(false);
            navigate(`/${LOGIN}`);
        } else {
            const isLoggedOut = await postDataWithToken(LOGOUT);
            if (isLoggedOut.status === 200) {
                setSession();
                setShowLogoutModal(false);
                navigate(`/${LOGIN}`);
            } else {
                console.log(isLoggedOut.message);
            }
        }
    }

    const handleDeleteAuth = async () => {
        const userType = getUserType();
        if (userType === 'guest_user') {
            setSession();
            setShowDeleteAccountModal(false);
            navigate(`/${LOGIN}`);
        } else {
            const isDeleted = await postDataWithToken(DELETE);
            if (isDeleted.status === 200) {
                setSession();
                setShowDeleteAccountModal(false);
                navigate(`/${LOGIN}`);
            } else {
                console.log(isDeleted.message)
            }
        }
    }
   
    const handleGuestLogin = () => {
        setSession();
        navigate(`/${LOGIN}`);
    }

    const handleSyncGooglefit = async () => {
        try {
            const response = await getData(GOOGLEFITAUTH);
            window.location.href = response;
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const handleSyncFitbit = async () => {
        try {
            const response = await getData(FITBITAUTH);
            window.location.href = response;
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };


    const handleSyncStarva = async () => {
        try {
            const response = await getData(STARVAAUTH);
            window.location.href = response;
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const handleSyncGarmin = async () => {
        try {
            const response = await getData(GARMINAUTH);
            window.location.href = response;
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const syncedApp = localStorage.getItem('syncedApp');
        
        if (syncedApp === 'Google Fit' && !dataFetched) {
            const handleSyncGooglefitAuth = async () => {
                try {
                    const response = await getSyncDataWithToken(GOOGLEFITDATA);
                    console.log(response);
                    setDataFetched(true);
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            handleSyncGooglefitAuth();
        }
        if (syncedApp === 'Fitbit' && !dataFetched) {
            const handleSyncFitbitAuth = async () => {
                try {
                    const response = await getSyncDataWithToken(FITBITDATA);
                    console.log(response);
                    setDataFetched(true);
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            handleSyncFitbitAuth()
        }
        if (syncedApp === 'Garmin' && !dataFetched) {
            const handleSyncGarminAuth = async () => {
                try {
                    const response = await getSyncDataWithToken(GARMINDATA);
                    console.log(response);
                    setDataFetched(true);
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            handleSyncGarminAuth()
        } else if (syncedApp === 'Strava' && !dataFetched) {
            const handleSyncStravaApi = async () => {
                try {
                    const response = await getSyncDataWithToken(STARVADATA);
                    console.log(response);
                    setDataFetched(true);
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            };
            handleSyncStravaApi()
        }
        setLoading(false)
    }, [dataFetched]);

    const syncAppsData = [
        { logoSrc: '../images/apple-health.png', appName: 'Apple Health' },
        { logoSrc: '../images/google-fit.png', appName: 'Google Fit' },
        { logoSrc: '../images/fitbit.png', appName: 'Fitbit' },
        // { logoSrc: '../images/wahoo.png', appName: 'Wahoo' },
        { logoSrc: '../images/garmin.png', appName: 'Garmin' },
        { logoSrc: '../images/starava.png', appName: 'Starava' },
    ];

    const syncFunctions = {
        'Apple Health': () => {
            console.log('Syncing data for Apple Health');
        },
        'Google Fit': () => {
            handleSyncGooglefit();
        },
        'Fitbit': () => {
            handleSyncFitbit();
        },
        'Garmin': () => {
            handleSyncGarmin();
        },
        'Starava': () => {
            handleSyncStarva();
        },
    }

    const [syncedApp, setSyncedApp] = useState(
        () => localStorage.getItem('syncedApp') || null
    );

    const handleToggleChange = async (e, appName) => {
        const isChecked = e.target.checked;
        setLoading(true);
        if (isChecked) {
            syncFunctions[appName]();
            localStorage.setItem('syncedApp', appName);
        } else {
            setSyncedApp(null);
            localStorage.removeItem('syncedApp');
            setDataFetched(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        let data = {
            "synceApp": syncedApp !== null ? syncedApp : '',
        }
        const fetchData = async () => {
            try {
                const response = await postDataWithToken(UPDATEUSERDETAIL, data);
                console.log(response);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData()
    }, [syncedApp]);
 
    return (
        <div className='wrapper h-100 px-0'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            {userType === 'real_user' &&
                <>
                    <div className='setting_Wrapper px-3' >
                        <h5 className='mb-4 text-white text-capitalize'>{t('Sync with Health apps')}</h5>
                        <div className='setting_content'>
                            {syncAppsData.map((app, index) => (
                                <div className={`d-flex align-items-center app_content ${index + 1 === syncAppsData.length ? 'border-0 pt-2 ' : 'py-2 '}`} key={index}>
                                    <img className='rounded' src={app.logoSrc} height={45} width={45} alt='App Logo' />
                                    <h6 className='mb-0 ps-3 text-white'>{app.appName}</h6>
                                    <label className="toggle-switch ms-auto position-relative d-inline-block">
                                        <input type="checkbox" className='d-none' checked={syncedApp === app.appName}
                                            onChange={(e) => handleToggleChange(e, app.appName)} disabled={syncedApp !== null && syncedApp !== app.appName}
                                            />
                                        <span className="slider position-absolute top-0 bottom-0 start-0 end-0"></span>
                                    </label>
                                </div>
                            ))}
                            <div className='d-flex flex-column mt-5 setting_button_Wrapper'>
                                <button type='button' onClick={ () => setShowFAQModal(true)}>{t('FAQ')}</button>
                                <button type='button' onClick={() => setShowLanguageModal(true)}>{t('Language')}</button>
                                <button type='button' >{t('Contact Us')}</button>
                                <button type='button' className=' bg-danger' onClick={ () => setShowLogoutModal(true)}>{t('Log Out')}</button>
                                <button type='button' className=' bg-danger' onClick={ () =>  setShowDeleteAccountModal(true)}>{t('Delete Account')}</button> 
                            </div>
                        </div>
                    </div>

                    {/* FAQ  modal */}
                    <div className={`modal faq_modal ${showFAQModal ? 'show' : ''}`} style={{ display: showFAQModal ? 'block' : 'none' }}>
                        <BackButton className='position-absolute pb-0' label={t('Settings')} closeFunction={ () => setShowFAQModal(false)} />
                        <div className="modal-dialog modal-dialog-centered p-0 align-items-start" role="document">
                            <div className="modal-content modal_content px-3 account_modal">
                                <div className="modal-body p-0">
                                    <h4 className='fw-bold mt-2 mb-0 text-white opacity-75'>{t('FAQs')}</h4>
                                    <div className="accordion" id="accordionExample">
                                        <div className="items">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button py-3 px-0 opacity-75" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    How League works?
                                                    < IoChevronDown size={20} />
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse pb-3 show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <p className="text-white opacity-50" >The standard Lorem Ipsum passage, used since the 1500s
                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                                                <p className="text-white opacity-50" > Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                                            </div>
                                        </div>
                                        <div className="items">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button py-3 px-0 opacity-75 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How League works?
                                                    < IoChevronDown size={20} />
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse pb-3" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <p className="text-white opacity-50" >The standard Lorem Ipsum passage, used since the 1500s
                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                                                <p className="text-white opacity-50" > Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                                            </div>
                                        </div>
                                        <div className="items">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button py-3 px-0 opacity-75 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    How League works?
                                                    < IoChevronDown size={20} />
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse pb-3" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <p className="text-white opacity-50" >The standard Lorem Ipsum passage, used since the 1500s
                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                                                <p className="text-white opacity-50" > Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                                            </div>
                                        </div>
                                        <div className="items">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button py-3 px-0 opacity-75 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    How League works?
                                                    < IoChevronDown size={20} />
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse pb-3" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <p className="text-white opacity-50" >The standard Lorem Ipsum passage, used since the 1500s
                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                                                <p className="text-white opacity-50" > Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                                            </div>
                                        </div>
                                        <div className="items">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button py-3 px-0 opacity-75 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    How League works?
                                                    < IoChevronDown size={20} />
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse pb-3" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <p className="text-white opacity-50" >The standard Lorem Ipsum passage, used since the 1500s
                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                                                <p className="text-white opacity-50" > Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
                                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* language  modal */}
                    <div className={`modal ${showLanguageModal ? 'show' : ''}`} style={{ display: showLanguageModal ? 'block' : 'none' }}>
                        <BackButton className='position-absolute pb-0' label={t('Settings')} closeFunction={ () => setShowLanguageModal(false) } />
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content modal_content p-3">
                                <div className="modal-body p-0">
                                    <h4 className="fw-bold mb-3 text-white text-center">{t('Language')}</h4>

                                    <div className="language-options d-flex flex-column">
                                        {Object.keys(languageMapping).map(lang => (
                                            <label className="d-flex justify-content-between px-2 py-3" key={lang}>
                                                <span>{lang}</span>
                                                <input
                                                    type="radio"
                                                    name="language"
                                                    value={languageMapping[lang]}
                                                    onChange={() => changeLanguage(languageMapping[lang])}
                                                    defaultChecked={i18n.language === languageMapping[lang]}
                                                />
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* logout  modal */}
                    <div className={`modal ${showLogoutModal ? 'show' : ''}`} style={{ display: showLogoutModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <BackButton className='position-absolute pb-0' label={t('Settings')} closeFunction={closeLogoutModal} />
                            <div className="modal-content modal_content p-3 account_modal">
                                <div className="modal-body p-0 text-center">
                                    <h4 className="fw-bold mb-0 text-white">{t('Log Out')}</h4>
                                    <h4 className='mt-5 mb-1 text-white opacity-75'>{t('Are you Sure ?')}</h4>
                                    <p className='mb-5 text-white opacity-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <button type='button' className='bg-danger w-100 text-white' onClick={handleLogoutAuth}>{t('Yes, Log out')}</button>
                                        <button type='button' className='bg-info w-100 text-white' onClick={closeLogoutModal}>{t("No, don't Log out")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* delete  modal */}
                    <div className={`modal ${showDeleteAccountModal ? 'show' : ''}`} style={{ display: showDeleteAccountModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <BackButton className='position-absolute pb-0' label={t('Settings')} closeFunction={closeDeleteAccountModal} />
                            <div className="modal-content modal_content p-3 account_modal">
                                <div className="modal-body p-0 text-center">
                                    <h4 className="fw-bold mb-0 text-white">{t('Delete Account')}</h4>
                                    <h4 className='mt-5 mb-1 text-white opacity-75'>{t('Are you Sure ?')}</h4>
                                    <p className='mb-5 text-white opacity-50'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <button type='button' className='bg-danger w-100 text-white' onClick={handleDeleteAuth}>{t('Yes, Delete')}</button>
                                        <button type='button' className='bg-info w-100 text-white' onClick={closeDeleteAccountModal}>{t("No, don't Delete")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {(showLanguageModal || showLogoutModal || showDeleteAccountModal) && <div className="modal-backdrop show"></div>}
                </>
            }
            {userType === 'guest_user' &&
                <div className='guest_login_cta d-flex align-items-center justify-content-center h-100'>
                    <button className='mx-3 mb-5 mt-0 fw-bold text-secondary bg-warning' type='button' onClick={handleGuestLogin}>{t('Login / Sign Up')}</button>
                </div>}
            <Bottombar />
        </div>
    )
}

export default Setting