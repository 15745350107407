import axios from "axios";
import { API_URL, LOGIN } from "../helpers/Constant";
import { getToken } from "../utils/common";

export const postData = async (api, data) => {
    try {
        var response = await axios({
            method: 'POST',
            url: API_URL + api,
            data: data
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const postDataWithToken = async (api, data) => {
    try {
        var response = await axios({
            method: 'POST',
            url: API_URL + api,
            data: data,
            headers: {
                Authorization: getToken()
            }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getDataWithToken = async (api) => {
    try {
        var response = await axios({
            method: 'GET',
            url: API_URL + api,
            headers: {
                Authorization: getToken()
            }
        })
        return response.data.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getSyncDataWithToken = async (api) => {
    try {
        var response = await axios({
            method: 'GET',
            url: API_URL + api,
            headers: {
                Authorization: getToken()
            }
        })
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getUser = async (api) => {
    try {
        var response = await axios({
            method: 'GET',
            url: API_URL + api,
            headers: {
                Authorization: getToken()
            }
        })
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getData = async (api) => {
    try {
        var response = await axios({
            method: 'GET',
            url: API_URL + api
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const updateData = async (api, data) => {
    try {
        var response = await axios({
            method: 'PUT',
            url: API_URL + api,
            data: data,
            headers: {
                Authorization: getToken()
            }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}