import React from 'react';

function splash() {
  return (
    <div className='wrapper h-100 bg_splash'>
      <div className='splash-container d-flex align-items-center justify-content-center position-fixed top-0 start-0 h-100 w-100'>
        <img src='../images/fitxlogo.png' alt='fitxlogo' />
      </div>
    </div>
  )
}

export default splash