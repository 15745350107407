import React, { useState, useEffect } from 'react';
import Bottombar from '../common/bottombar';
import { Bar, Line } from "react-chartjs-2";
import { IoIosArrowForward } from 'react-icons/io'
import { BiInfoCircle } from 'react-icons/bi';
import { getUserInfo } from '../utils/common';
import { GETDAILYBMI, GETDAILYREPORT, GETUSERACCOUNTHISTORIES, BMICALCULATOR, ACCOUNT } from '../helpers/Constant';
import { postDataWithToken, getDataWithToken } from '../api/api';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import BackButton from '../common/backButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Detailed() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showBmiInfoModal, setShowBmiInfoModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bmiAverage, setBMIAverage] = useState([]);
    const [stepsData, setSteps] = useState([]);
    const [accountHistories, setAccountHistories] = useState([]);

    const [physicalActivity, setPhysicalActivity] = useState([1, 100, 500, 1000]);
    const [currentSteps, setCurrentSteps] = useState(0);
    const [currentAmount, setCurrentAmount] = useState(0);
    const [currentActivity, setCurrentActivity] = useState(0);

    const currentDate = moment().format('D');
    const currentMonth = moment().format('MMMM');
    const currentYear = moment().format('YYYY');
    const currentMonthNumber = moment().format('M');
    const lastDate = moment().endOf('month').format('D');

    const user = getUserInfo();
    const bmiLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        async function fetchData() {
            if (user) {
                const bamiData = await getDataWithToken(GETDAILYREPORT);
                if (bamiData.length > 0) {
                    var averageBMI = [];
                    for (var i = 0; i < currentMonthNumber; i++) {
                        var sumOfBMI = 0;
                        var bmiSum = 0;
                        var firstDateOfMonth = moment({ year: currentYear, month: i }).format('MM-DD-YYYY');
                        bamiData.forEach((data) => {
                            const date = moment(data.createdAt).format('MM-DD-YYYY');
                            const isCurrentMonthAndYear = moment(date).isSame(firstDateOfMonth, 'month') && moment(date).isSame(firstDateOfMonth, 'year');
                            if (isCurrentMonthAndYear) {
                                sumOfBMI += data.bmi;
                                bmiSum += 1;
                            }
                        });

                        const average = bmiSum === 0 ? 0 : (sumOfBMI / bmiSum).toFixed(2);
                        averageBMI.push(average);
                    }
                    setBMIAverage(averageBMI);
                }

                const data = {
                    "date": moment().format('MM-DD-YYYY')
                };

                const currentSteps = await postDataWithToken(GETDAILYBMI, data);
                if (currentSteps.status == 200) {
                    if (currentSteps.data) {
                        setCurrentSteps(currentSteps.data.steps);
                        const physicalActivity = currentSteps.data.physicalActivity;
                        const hours = Math.floor(physicalActivity / 3600);
                        if (hours !== 0) {
                            var activityTimeFormat = (hours <= 1) ? `${hours} hr` : `${hours} hrs`;
                        } else {
                            var activityTimeFormat = (physicalActivity <= 1) ? `${physicalActivity} min` : `${physicalActivity} mins`;
                        }
                        setCurrentActivity(activityTimeFormat);
                    }
                } else {
                    console.log(currentSteps.message);
                }

                const stepsData = await postDataWithToken(GETDAILYBMI);
                if (stepsData.status == 200) {
                    if (stepsData.data.length > 0) {
                        var stepsArr = [];
                        var activityArr = [];
                        for (var i = 1; i <= currentDate; i++) {
                            let steps = 0;
                            let physicalActivity = 0;
                            stepsData.data.forEach((data) => {
                                const date = moment(data.createdAt).format('MM-DD-YYYY');
                                var dateOfCurrent = moment({ year: currentYear, month: (currentMonthNumber - 1), day: i }).format('MM-DD-YYYY');
                                const isCurrentMonthAndYear = moment(date).isSame(dateOfCurrent);
                                if (isCurrentMonthAndYear) {
                                    steps += data.steps;
                                    physicalActivity += data.physicalActivity;
                                }
                            });
                            stepsArr.push(steps);
                            activityArr.push(physicalActivity);
                        }
                        setSteps(stepsArr);
                        setPhysicalActivity(activityArr);
                    }
                } else {
                    console.log(stepsData.message);
                }

                const limit = { limit: 10 };
                const userAccounts = await postDataWithToken(GETUSERACCOUNTHISTORIES, limit);
                if (userAccounts.status == 200) {
                    const data = userAccounts['data'];
                    if (data.length > 0) {
                        setCurrentAmount(data[0]['realAmount']);
                        const userAccountHistories = data[0]['userAccountHistoryData'];
                        if (userAccountHistories.length > 0) {
                            setAccountHistories(userAccountHistories);
                        }
                    }
                }

                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const openBmiInfoModal = () => {
        setShowBmiInfoModal(true);
    };

    const closeBmiInfoModal = () => {
        setShowBmiInfoModal(false);
    };

    const bmiData = {
        labels: bmiLabels,
        datasets: [
            {
                data: bmiAverage,
            },
        ],
    };

    const month = Array.from({ length: lastDate }, (_, index) => index + 1);

    const stepData = {
        labels: month,
        datasets: [
            {
                data: stepsData,
            },
        ],
    };

    const activityData = {
        labels: month,
        datasets: [
            {
                data: physicalActivity,
                barThickness: 4,
                borderRadius: 20
            },
        ],
    };

    const calculateHoursMinutes = (value) => {
        const hours = Math.floor(value / 60);
        if (hours !== 0) {
            return `${hours} hr`;
        } else {
            return `${value} min`;
        }
    };

    const handleCalibrateTarget = () => {
        navigate(`/${BMICALCULATOR}`);
    }

    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            {showBmiInfoModal ? (
                <BackButton label={t('Profile')} className="position-relative" closeFunction={closeBmiInfoModal} />
            ) : (
                <BackButton label={t('Profile')} className="position-relative" />
            )}

            <div className='detailed_screen_wrapper'>
                <div className='profile_content_wrapper your_daily_target position-relative bg-secondary mb-3'>
                    <div className='d-flex align-items-start justify-content-between gap-3'>
                        <div className='mb-3'>
                            <h5 className='mb-1 text-white fw-bold opacity-75 '>{t('Your BMI')} <BiInfoCircle className='text-light ms-1' onClick={openBmiInfoModal} /></h5>
                            <h6 className='text-white opacity-75 px-2 mb-0'>{currentYear}</h6>
                        </div>
                        <span className='m-0 text-warning fw-bold text_shadow_green text-nowrap'>{user.bmi}</span>
                    </div>

                    <Line data={bmiData} options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                enabled: false,
                            },
                        },
                        elements: {
                            point: {
                                radius: 4,
                                backgroundColor: '#daff00'
                            },
                            line: {
                                backgroundColor: "black",
                                borderColor: "#daff00",
                                borderWidth: 2
                            }
                        },
                        scales: {
                            y: {
                                border: {
                                    width: 1,
                                    color: '#dedede',
                                },
                                ticks: {
                                    color: '#fff'
                                },
                            },
                            x: {
                                border: {
                                    width: 1,
                                    color: '#dedede',
                                },
                                ticks: {
                                    color: (label, index) => {
                                        return (currentMonthNumber > label.index) ? '#fff' : '#707070';
                                    },
                                },
                            }
                        }

                    }} />
                    <button type='button' className='go_button text-white border-0 d-block position-relative w-100 fw-bold text-center mt-3 px-5' disabled={(user.isTrialComplete) ? true : false} onClick={handleCalibrateTarget}>{t('Calibrate BMI')}<IoIosArrowForward color="white" size={22} className='arrow-icon position-absolute' /></button>
                </div>
                <div className='profile_content_wrapper position-relative bg-secondary mb-3'>
                    <div className='d-flex align-items-start justify-content-between gap-3'>
                        <div className='mb-3'>
                            <h5 className='mb-1 text-white fw-bold opacity-75 '>{t('Avg Active time')}</h5>
                            <h6 className='text-white opacity-75 px-2 mb-0'>{`${currentMonth} - ${currentYear}`}</h6>
                        </div>
                        <span className='m-0 text-warning fw-bold text_shadow_green text-nowrap'>{currentActivity}</span>
                    </div>
                    <Bar data={activityData} options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (context) => {
                                        const dataValue = context.parsed.y;
                                        return calculateHoursMinutes(dataValue);
                                    },
                                },
                            },
                        },
                        elements: {
                            bar: {
                                backgroundColor: (label, index) => {
                                    const value = label.raw;
                                    const hours = Math.floor(value / 60);
                                    if (hours !== 0) {
                                        if (hours <= 1) {
                                            return `#FFD35D`;
                                        } else {
                                            return `#10FF88`;
                                        }
                                    } else {
                                        if (value <= 30) {
                                            return `#F55A68`;
                                        } else {
                                            return `#FFD35D`;
                                        }
                                    }
                                    return (label.raw < 4000) ? '#F55A68' : (label.raw >= 4000 && label.raw <= 6000) ? '#FFD35D' : '#10FF88';
                                },
                                width: '2px'
                            },
                        },

                        scales: {
                            y: {
                                border: {
                                    width: 1,
                                    color: '#dedede',
                                },
                                ticks: {
                                    min: 1,
                                    callback: (value) => calculateHoursMinutes(value),
                                    color: '#fff'
                                },
                            },
                            x: {
                                border: {
                                    width: 1,
                                    color: '#dedede',
                                },
                                ticks: {
                                    color: (label, index) => {
                                        return (currentDate > label.index) ? '#fff' : '#707070';
                                    },
                                },
                            },
                        }

                    }} />
                </div>
                <div className='profile_content_wrapper position-relative bg-secondary mb-3'>
                    <div className='d-flex align-items-start justify-content-between gap-3'>
                        <div className='mb-3'>
                            <h5 className='mb-1 text-white fw-bold opacity-75'>{t('Avg Steps')}</h5>
                            <h6 className='text-white opacity-75 px-2 mb-0'>{`${currentMonth} - ${currentYear}`}</h6>
                        </div>
                        <span className='m-0 text-warning fw-bold text_shadow_green text-nowrap'>{currentSteps}</span>
                    </div>
                    <Bar data={stepData} options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                enabled: false,
                            },
                        },
                        elements: {
                            bar: {
                                backgroundColor: (label, index) => {
                                    return (label.raw < 4000) ? '#F55A68' : (label.raw >= 4000 && label.raw <= 6000) ? '#FFD35D' : '#10FF88';
                                },
                            },
                        },
                        scales: {
                            y: {
                                border: {
                                    width: 1,
                                    color: '#dedede',
                                },
                                ticks: {
                                    color: '#fff'
                                },
                            },

                            x: {
                                border: {
                                    width: 1,
                                    color: '#dedede',
                                },
                                ticks: {
                                    color: (label, index) => {
                                        return (currentDate > label.index) ? '#fff' : '#707070';
                                    },
                                },
                            }
                        }

                    }} />
                </div>
                <div className='profile_content_wrapper withdraw_section p-0'>
                    <div className='d-flex align-items-center justify-content-center flex-wrap overflow-hidden'>
                        <div className='p-3 text-center'>
                            <h4 className='text-warning fw-bold text_shadow_green'>${(currentAmount).toFixed(2)}</h4>
                            <p className='text-white opacity-75 mb-0'>{t('Your Balance')}</p>
                        </div>
                        <div className='p-3 withdraw_content position-relative'>
                            <p className='text-white opacity-50'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <button type='button' className='go_button text-white border-0 d-block position-relative text-center mt-3 py-2 w-100 shadow-none' onClick={() => navigate(`/${ACCOUNT}`)}>{t('Go to Wallet')}<IoIosArrowForward color="white" size={22} className='arrow-icon position-absolute' /></button>
                        </div>
                    </div>
                </div>
                <div className='bg-secondary mt-0 rounded-bottom-4 transaction_section p-3'>
                    {
                        (accountHistories.length > 0) ?
                            <>
                                <h6 className='text-white mb-3 opacity-75'>{t('Last 10 Transaction')}</h6>
                                <div className="bg-secondary rounded-top-4">
                                    {accountHistories.map((accountHistory) => (
                                        <div key={accountHistory._id} className="d-flex gap-1 rounded mb-2">
                                            <span className="text-white opacity-25">{moment(accountHistory.createdAt).format('h:mm A')}</span>
                                            <span className="text-white opacity-25">{moment(accountHistory.createdAt).format('DD/MM/YYYY')}</span>
                                            <span className="text-white opacity-50 transaction_detail">${accountHistory.amount} {t(accountHistory.description)}</span>
                                            <span className={`text-end ms-auto text-capitalize ${accountHistory.status === 'Failed' ? 'failed' : accountHistory.status === 'Pending' ? 'pending' : 'text-success'}`}>{t(accountHistory.status)}</span>
                                        </div>
                                    ))}
                                </div>
                            </>
                            :
                            <h6 className='text-light my-0 text-center'>{t("You haven't made any transactions yet.")}</h6>
                    }
                </div>
            </div>
            <Bottombar />

            {/* bmi info modal */}
            <div className={`modal ${showBmiInfoModal ? 'show' : ''}`} style={{ display: showBmiInfoModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content info_modal bg-secondary p-3">
                        <div className="modal-body p-0 text-center ">
                            <h4 className='fw-bold mb-2 text-white'>{t('How BMI work?')}</h4>
                            <div className='info_wrapper text-start'>
                                <p className='text-light'>The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showBmiInfoModal && (
                <div className="modal-backdrop show"></div>
            )}
        </div>
    )
}

export default Detailed;