import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import StepProgressBar from '../common/stepBar';
import { getDataWithToken, postDataWithToken, updateData } from '../api/api';
import { GETUSERINFO, HOME, UPDATEAVTAR, UPDATEUSER } from '../helpers/Constant';
import { getUserID } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

function CreateProfile() {
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState();
    const [userData, setUserData] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const activeStep = 3;
    const pendingStep = activeStep + 1;

    useEffect(() => {
        const getUserInformation = async () => {
            const userInfo = await getDataWithToken(GETUSERINFO);
            setUserData(userInfo);
            setLoading(false);
        }
        getUserInformation();
    }, []);

    const mobileNumberRef = document.getElementById("mobileNumber");
    const locationRef = document.getElementById("location");

    const handleChange = (event, field) => {
        const targetData = event.target;
        const newFormErrors = { ...formErrors };

        if (field === "mobileNumber") {
            if (targetData.value === null || targetData.value === "") {
                newFormErrors["mobileNumber"] = t('Mobile number is required.');
            } else if (/^[6-9]\d{9}$/.test(targetData.value)) {
                delete newFormErrors["mobileNumber"];
            } else {
                newFormErrors["mobileNumber"] = t('Enter valid mobile number.');
            }
        } else if (field === "location") {
            if (targetData.value === null || targetData.value === "") {
                newFormErrors["location"] = t('Location is required.');
            } else {
                delete newFormErrors["location"];
            }
        } else if (field === "profileImage") {
            setSelectedImage(targetData.files[0]);
        }
        setFormErrors(newFormErrors);
    };

    const handleCreateProfileForm = async (e) => {
        e.preventDefault();

        const formFields = [
            { ref: mobileNumberRef, name: mobileNumberRef.name, errorMsgName: t('Mobile number') },
            { ref: locationRef, name: locationRef.name, errorMsgName: t('Location') },
        ];

        const errors = {};

        formFields.forEach((field) => {
            if (field.ref.value === "" || field.ref.value === null) {
                errors[field.name] = `${field.errorMsgName} ${t('is required.')}`;
            }
        });

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            if (selectedImage) {
                const avtarData = new FormData();
                avtarData.append('avtar', selectedImage);

                await postDataWithToken(UPDATEAVTAR + getUserID(), avtarData);
            }
            let data = {
                "user": getUserID(),
                "mobile": mobileNumberRef.value,
                "address1": locationRef.value
            }
            const dataRes = await updateData(UPDATEUSER, data);
            if (dataRes.status === 200) {
                setFormErrors({});
                navigate(`/${HOME}`);
                localStorage.setItem("user_Trial", true);
                setLoading(false);
            } else {
                setLoading(false);
                console.logs(dataRes.message);
            }
        }
    };

    return (
        <div className='wrapper d-flex flex-column h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            <div className='d-flex justify-content-center'>
                <StepProgressBar activeStep={activeStep} pendingStep={pendingStep} />
            </div>
            <div className='create_profile position-relative'>
                <h6 className='text-start fw-bold mb-5 pt-4 text-white'>{t('Create your profile')}</h6>
                <form className='create_profile_form' onSubmit={handleCreateProfileForm} autoComplete='off'>
                    <div className='form_field position-relative profile_image_label my-4 text-center'>
                        <label htmlFor='profileImage' >
                            <img
                                className="profile_image"
                                src={selectedImage ? URL.createObjectURL(selectedImage) : '../images/profile-user.png'}
                                width={135}
                                height={135}
                                alt="Profile"
                            />
                            <input
                                name='profileImage'
                                type="file"
                                id='profileImage'
                                accept=".jpg, .jpeg, .png, .heic, image/heic"
                                className='d-none'
                                onChange={(event) => handleChange(event, "profileImage")}
                            />
                            <p className='field_error'>{formErrors['profileImage']}</p>
                        </label>
                    </div>
                    <div className='form_field position-relative'>
                        <input type="text" name="userName" id="userName" defaultValue={userData ? userData.fname + " " + userData.lname : ''} className='w-100 px-3' placeholder='user name' readOnly />
                    </div>
                    <div className='form_field position-relative'>
                        <input type="text" name="mobileNumber" id="mobileNumber" onChange={(event) => handleChange(event, "mobileNumber")} className='w-100 px-3' placeholder={t('Mobile number')} />
                        <p className='field_error'>{formErrors['mobileNumber']}</p>
                    </div>
                    <div className='form_field position-relative'>
                        <input type="text" name="email" id="email" defaultValue={userData ? userData.email : ''} className='w-100 px-3' placeholder='Email' readOnly />
                    </div>
                    <div className='form_field mb-4 position-relative'>
                        <select className="form-select form_select w-100 px-3" onChange={(event) => handleChange(event, "location")} id="location" name="location" defaultValue="">
                            <option value="" disabled>{t('Location')}</option>
                            <option value="India">{t('India')}</option>
                        </select>
                        <p className='field_error'>{formErrors['location']}</p>
                    </div>
                    <div className='bmi_data d-flex text-center text-white w-100 px-2 py-3'>
                        <div className='w-50 pe-2'>
                            <h6>{t('BMI')}</h6>
                            <span className='fw-bold'>{userData ? userData.bmi : '0'}</span>
                        </div>
                        <div className='vl'></div>
                        <div className='w-50'>
                            <h6>{t('Your Daily Target')}</h6>
                            <span className='fw-bold'><img src='../images/fireicon.svg' width={24} className='me-2' alt='Fireicon' />{userData ? Math.round(userData.dailyTarget) : '0'}</span>
                        </div>
                    </div>
                    <div className='mt-4 text-end'>
                        <button type='submit' className='next_button bg-warning fw-bold'><span className='me-3'>{t('Continue')}</span><FaArrowRight className='position-absolute next_arrow' size={14} /></button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateProfile