import React, { useState, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Splash from './pages/splash';
import HomeRoutes from './routes';
import { getUserType } from './utils/common';


function App() {
  const islogIn = getUserType();

  const [loading, setLoading] = useState(true);

  function handleContextMenu(e) {
    e.preventDefault();
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      return () => clearTimeout(timer);
    }, 1000);
    const rootElement = document.getElementById('root');
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (loading && !islogIn) {
    return <Splash />;
  } else {
    return (
      <BrowserRouter>
        <HomeRoutes onContextMenu={(e) => e.preventDefault()} />
      </BrowserRouter>
    );
  }
}

export default App;
