import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

function DotsLoading({color, width, className}) {
    return (
        <ThreeDots
            wrapperClass={className ? className : 'ms-1'}
            wrapperStyle={{display : 'inline-flex'}}
            height="5"
            width={width ? width : '15'}
            color={color ? color : '#ffffff'}
        />
    );
}

export default DotsLoading;