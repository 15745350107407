import React, { useState, useEffect } from 'react';
import { GETBADGES, GETUSERBADGES } from '../helpers/Constant';
import { getDataWithToken } from '../api/api';
import BackButton from '../common/backButton';
import { useTranslation } from 'react-i18next';
import { BiInfoCircle } from 'react-icons/bi';


function Achivement() {
    const { t } = useTranslation();
    const [showAchievementInfoModal, setShowAchievementInfoModal] = useState(false);
    const [showSubscribeModal, setShowSubscribeModal] = useState(true);
    const [badgeData, setBadgeData] = useState([]);
    const IMAGE_API_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/badges/`;
    const [clickedBadge, setClickedBadge] = useState(null);


    const openAchievementInfoModal = (badge) => {
        setClickedBadge(badge);
        setShowAchievementInfoModal(true);
    };

    const closeAchievementInfoModal = () => {
        setShowAchievementInfoModal(false);
    };


    const closeSubscribeModal = () => {
        setShowSubscribeModal(false);
    };


    useEffect(() => {
        async function fetchBadgeData() {
            try {
                const badges = await getDataWithToken(GETBADGES);
                const userBadges = await getDataWithToken(GETUSERBADGES);

                const indexed = Object.fromEntries(userBadges.map(o => [o.badges, o]));
                const budgesCombined = badges.map(o => ({ ...o, ...indexed[o._id] }));
                const sortedBadgeData = budgesCombined.sort((a, b) => a.orderNumber - b.orderNumber);
                setBadgeData(sortedBadgeData);
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }
        fetchBadgeData()
    }, []);


    return (
        <>
            <div className='wrapper h-100'>
                {(showSubscribeModal || showAchievementInfoModal) ? (
                    <BackButton label={t('Achievement')} className="position-relative" closeFunction={showSubscribeModal ? closeSubscribeModal : closeAchievementInfoModal} />
                ) : (
                    <BackButton label={t('Home')} className="position-relative" />
                )}

                <div className='buddy_tournament_wrapper mb-0'>
                    <h3 className='fw-bold mb-0 text-white opacity-75 mt-3 achivements_title'>{t('Achivements Badges')}</h3>
                    <p className='text-white py-2 opacity-50 mb-4'>The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, The standard Ipsum passage, used since the 1500s</p>
                    <div className='d-flex flex-wrap gap-3 achievements justify-content-center'>
                        {badgeData.map((badge, index) => (
                            <div className={`achievement_section text-white text-center p-2 pt-4 bg-secondary position-relative ${badge.isCompleted ? "complated" : badge.badges ? '' : 'lock'}`} key={index}>
                                <BiInfoCircle size={18} className='text-white opacity-25 position-absolute top-0 end-0 me-2 mt-2' onClick={() => openAchievementInfoModal(badge)} />
                                <img src={badge.badges ? IMAGE_API_URL + badge.image : '../images/lock-badge.png'} className="mb-4" alt={badge.title + "badge"} />
                                <h5 className='text-white my-1 fw-bold'>{badge.title}</h5>
                                <h6 className='text-white mb-0 opacity-75'>{(badge.userTarget ? badge.userTarget : 0)}/{badge.target}</h6>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* achievement info modal */}
            <div className={`modal ${showAchievementInfoModal ? 'show' : ''}`} style={{ display: showAchievementInfoModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content info_modal achievement_info_modal p-4">
                        {clickedBadge && (
                            <div className="modal-body p-0">
                                <img className='mx-auto d-block' src={IMAGE_API_URL + clickedBadge.image} width={125} alt={clickedBadge.title} />
                                <h4 className='fw-bold my-3 text-white fs-5'>{"How " + clickedBadge.title + " Works?"}</h4>
                                <p className='text-white opacity-50' dangerouslySetInnerHTML={{ __html: clickedBadge.description }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* subscribe modal */}
            <div className={`modal ${showSubscribeModal ? 'show' : ''}`} style={{ display: showSubscribeModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content bg-transparent info_modal">
                        <div className="modal-body p-0">
                            <h4 className='text-white text-center mb-4 mx-4'>{t('Congratulations you unlocked the 5th league')}</h4>
                            <div className='bg-primary rounded-4 p-4'>
                                <h5 className='text-warning fs-1 text-center fw-bold my-3 mt-0'>$4.99<span className='fs-6'>/ {t('Month')}</span></h5>
                                <div className='d-flex justify-content-center ps-4'>
                                    <ul className='benefits_list text-white mw-100 list-unstyled'>
                                        <li className='pb-1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                        <li className='pb-1'>Lorem Ipsum is simply dummy text </li>
                                        <li className='pb-1'>Lorem Ipsum is simply dummy text of the printing </li>
                                        <li className='pb-1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                        <li className='pb-1'>Lorem Ipsum is simply dummy text </li>
                                    </ul>
                                </div>
                                <button type='text' className='bg-warning mw-50 d-block m-auto text-dark cta_button fw-bold fs-6 border-0'>{t('Subscribe')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(showSubscribeModal || showAchievementInfoModal) && <div className="modal-backdrop show"></div>}
        </>
    )
}

export default Achivement