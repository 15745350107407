import React, { useState, useEffect } from 'react'
import axios from 'axios';
import StepProgressBar from '../common/stepBar';
import { BiInfoCircle, BiLogoApple } from 'react-icons/bi';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { BMICALCULATOR, BUDDYGAMEBYID, BUDDYGAMEUPDATE, HOME, LOGINWITHSOCIAL } from '../helpers/Constant';
import { getDataWithToken, postData, postDataWithToken } from '../api/api';
import { getUserTrial, getUserType, setSession, setUserType } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { BiLogoFacebook, BiRightArrowAlt } from 'react-icons/bi';
import { BUDDYGAMEJOIN } from '../helpers/Constant';
import { Oval } from 'react-loader-spinner';
import { FaCrown } from 'react-icons/fa';
import BackButton from '../common/backButton';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Login() {
  const navigate = useNavigate();
  const pendingStep = 1;
  const { t } = useTranslation();
  const [buddyCode, setBuddyCode] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [userGameIsCompleted, setUserGameIsCompleted] = useState(false);
  const [users, setUsers] = useState([]);
  const [showBuddyInfoModal, setshowBuddyInfoModal] = useState(false);
  const loginType = localStorage.getItem('type');

  useEffect(() => {
    const userType = getUserType();
    const userTrial = getUserTrial();
    if (userType === 'real_user' && !userTrial) {
      navigate(`/${BMICALCULATOR}`);
    } else if (userType) {
      navigate(`/${HOME}`);
    };
  }, [navigate]);

  const handleGoBurn = async () => {
    const codeRef = document.getElementById("buddy_code");

    const errors = {};

    if (codeRef.value === "" || codeRef.value === null) {
      errors[codeRef.name] = t('Code is required.');
    }

    if (Object.keys(errors).length === 0) {
      try {
        const gameJoinData = {
          "fname": '',
          "lname": '',
          "code": buddyCode,
          "user": '',
        }
        const buddydata = await postDataWithToken(BUDDYGAMEJOIN, gameJoinData);
        if (buddydata.status === 200) {
          localStorage.setItem('joinGameId', buddydata.data._id);
          localStorage.setItem('guestPlayerId', buddydata.data.currentPlayId);
          setFormErrors({});

          window.location.href = `uniwebview://openGame?name=${buddydata.data.gameData.title}&room=${buddyCode}`;
        } else {
          errors[codeRef.name] = buddydata.message;
        }
      } catch (error) {

        console.log('Error fetching data:', error);
      }
    }
    setFormErrors(errors);
  };

  // End of the Game
  window.onGameClosed = async function (calories, time, score, heartbeat, accuracy) {
    handleGameClosed(calories, time, score, heartbeat, accuracy);
  };

  const handleGameClosed = async (calories, time, score, heartbeat, accuracy) => {
    try {
      const joinBuddyId = localStorage.getItem('joinGameId');
      const currentPlayerId = localStorage.getItem('guestPlayerId');

      const payloadBuddyData = {
        buddyGameId: joinBuddyId,
        user: {
          id: currentPlayerId,
          burnedCal: calories,
          time : time,
          score: score,
          heartbeat : heartbeat,
          accuracy: accuracy,
          status: "Completed"
        }
      }
      setUserGameIsCompleted(true);
      const buddyPlayerUpdateScore = await postDataWithToken(BUDDYGAMEUPDATE, payloadBuddyData);
      const playerData = buddyPlayerUpdateScore.data['users'];
      const allComplete = playerData.every(player => player.status === "Completed");

      if (allComplete) {
        const completedStatus = {
          buddyGameId: joinBuddyId,
          isCompleted: true
        }
        await postDataWithToken(BUDDYGAMEUPDATE, completedStatus);
      }
      const joinPlayer = await getDataWithToken(BUDDYGAMEBYID + joinBuddyId);
      if (joinPlayer) {
        var users = joinPlayer.users;
        if (users) {
          const highestPoint = Math.max(
            ...users
              .filter(user => user.status === 'Completed')
              .map(user => user.burnedCal)
          );

          const updatedUsers = users.map(user => ({
            ...user,
            highest: user.status === 'Completed' && user.burnedCal === highestPoint,
          }));
          setUsers(updatedUsers);
        }
      }
      localStorage.removeItem('joinGameId');
      localStorage.removeItem('guestPlayerId');

    } catch (error) {
      console.error(error);
    }
  }

  const GoogleLogin = () => {
    const clientId = process.env.REACT_APP_AUTH_CLIENT_KEY;

    const googleLoginResponse = useGoogleLogin({
      clientId,
      onSuccess: async (tokenResponse) => {
        const userInfo = await axios
          .get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then((response) => response.data);
        let userData = {
          "fname": userInfo.given_name,
          "lname": userInfo.family_name,
          "email": userInfo.email,
          "social": "Google"
        };

        const response = await postData(LOGINWITHSOCIAL, userData);

        if (response.status === 200) {
          setSession(response.data);
          setUserType("real_user");
          if (!response.data.isTrialComplete) {
            navigate(`/${BMICALCULATOR}`);
          } else {
            navigate(`/${HOME}`);
          };
          setLoading(true);
        } else {
          console.log(response.message);
          setLoading(false);
        };
      }
    });

    return (
      <button onClick={() => googleLoginResponse()} className='bg-white text-primary border-0 rounded-3'><FcGoogle size={24} /> {t('Login with Google')}</button>
    );
  };

  const guestLogin = () => {
    setSession();
    setUserType("guest_user");
    setLoading(true);
    navigate(`/${HOME}`);
  };

  const loginWithSocial = async (socialType) => {
    window.location.href = `uniwebview://loginWithSocial?name=${socialType}`;
    LoggedInSocial(socialType);
  }

  const LoggedInSocial = (socialType) => {
    window.onSocialLoggedIn = async function (firstName, lastName, email) {
      let userData = {
        "fname": firstName,
        "lname": lastName,
        "email": email,
        "social": socialType
      };

      const response = await postData(LOGINWITHSOCIAL, userData);

      if (response.status === 200) {
        setSession(response.data);
        setUserType("real_user");
        if (!response.data.isTrialComplete) {
          navigate(`/${BMICALCULATOR}`);
        } else {
          navigate(`/${HOME}`);
        }
      }
    };
  }
  return (
    <div className='wrapper bg_paper h-100'>
      {loading ?
        <div className="loader">
          <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
        </div>
        : ""}
      {showBuddyInfoModal || userGameIsCompleted ? (
        <BackButton label={t('Log in')} closeFunction={() => { setshowBuddyInfoModal(false); setUserGameIsCompleted(false); }} />
      ) : (
        null
      )}

      <div className='d-flex justify-content-center'>
        <StepProgressBar pendingStep={pendingStep} />
      </div>

      <div className='log_in_wrapper position-absolute d-flex flex-column justify-content-end'>
        <div className='logo_wrapper h-100 d-flex justify-content-center align-items-center'>
          <img src='../images/fitxlogo.png' alt="FitX" />
        </div>
        <div className='login-inner mx-auto'>
          <div className='log_in_section bg-primary d-grid gap-3 p-3 rounded-4 text-center'>
            <h3 className='text-white fw-bold'>{t('Log in')}</h3>
            <div className='d-flex flex-wrap gap-3 justify-content-between'>
              { loginType === 'web' ? (<>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_AUTH_CLIENT_KEY}>
                  <GoogleLogin />
                </GoogleOAuthProvider>
              </>) : null }
              <button onClick={() => loginWithSocial('Google')} className='bg-white text-primary border-0 rounded-3'><FcGoogle size={24} />{t('Login with Google')}</button>
              <button className='text-white bg-info border-0 rounded-3' onClick={() => loginWithSocial('Facebook')}><BiLogoFacebook size={24} />{t('Login with Facebook')}</button>
            </div>
            <button className='bg-black text-white border-0 rounded-3 w-100 position-relative' onClick={() => loginWithSocial('Apple')}><BiLogoApple className="me-1" size={24} />{t('Login with apple')}</button>
            <h6 className='position-relative mb-0 d-flex align-items-center text-light gap-3'>{t('or')}</h6>
            <button className='text-white w-100 guest_button rounded-3' onClick={guestLogin}>{t('Guest Login')}</button>
          </div>         <div className='login_tournament_sec gap-3 rounded-4 d-grid bg-primary p-3 position-relative mt-3'>
            <div className='d-flex gap-2 align-items-center'>
              <h3 className='text-white mb-0 fw-bold'>{t('Buddy Tournament')}</h3>
              <BiInfoCircle className='text-light buddy-tooltip' size={18} onClick={() => setshowBuddyInfoModal(true)} />
            </div>
            <div className='d-flex align-items-center position-relative'>
              <input type='text' id='buddy_code' name='buddy_code' placeholder={t('Paste your buddy ID')} className='w-100 px-3 me-2 bg-transparent' onChange={(e) => (setBuddyCode(e.target.value), setFormErrors({}))} />
              <p className='field_error'>{t(formErrors['buddy_code'])}</p>
              <button type='button' className='bg-info text-white d-flex align-items-center justify-content-center px-2 py-1 border-0' disabled={(buddyCode) ? false : true} onClick={() => handleGoBurn()}>
                {t('Go')}<BiRightArrowAlt size={25} color="white" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal ${showBuddyInfoModal ? 'show' : ''}`} style={{ display: showBuddyInfoModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal_content info_modal bg-secondary p-3 pb-4">
            <div className="modal-body p-0">
              <h4 className='fw-bold mb-3 text-white text-center'>{t('How Buddy Tournament?')}</h4>
              <p className='text-light mb-0'>The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal ${userGameIsCompleted ? 'show' : ''}`} style={{ display: userGameIsCompleted ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className='members_list px-4 py-3'>
            {
              users.map((user, index) => {
                return (
                  <div key={user._id} className={`item d-flex align-items-center gap-2 ${user.highest ? 'toper_user' : ''}`}>
                    <div className='member_profile rounded-circle position-relative'>
                      <h6 className='text-white mb-0'>{user.user !== null ? user.name.split(" ").slice(0, 2).map(word => word[0].toUpperCase()).join("") : 'P'}</h6>
                    </div>
                    <p className='mb-0 text-white user_name'>{user.user !== null ? user.name : 'Player_' + index}</p>
                    {/* <p className='mb-0 mx-auto user_status'>{(user.point !== 0) ? user.point : user.status}</p> */}
                    <p className='mb-0 ms-auto user_status'>{(user.userStatus) ? user.userStatus : ''}</p>
                    <div className={`d-flex align-items-center justify-content-center gap-1 game_status ${(user.status == 'Waiting') ? 'waiting' : ''}`}>
                      {(user.status !== 'Waiting') ? <span className='rounded-circle'>< FaCheck /></span> : null}
                      <p className='mb-0'>{(user.status === 'Waiting') ? 'Waiting...' : user.status}</p>
                    </div>
                    {(user.highest) ? <FaCrown className='position-absolute' /> : ''}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      {(userGameIsCompleted || showBuddyInfoModal) && <div className="modal-backdrop show"></div>}

    </div>
  )
}

export default Login