import React, { useEffect, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { FiArrowUpRight } from 'react-icons/fi';
import { IoIosArrowDropupCircle, IoIosArrowDropdownCircle, IoIosArrowForward } from 'react-icons/io';
import { BiInfoCircle, BiSolidLock, BiRightArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { GETUSERINFO, GAMEUSER, GETGAMES, GAMEUPDATESCORE, DAILYTARGET, GAME, BMIINFOAVERAGE, BUDDYTOURNAMENTCREATE, BUDDYGAMEJOIN, VERSUS, BMIPROGRESSION, BUDDYTOURNAMENTJOIN, GAMEUPDATESTATUS, ACHIEVEMENT, TOURNAMENT, GETTOURNAMENT, GETTOURNAMENTUSERBYID, GETBADGES, GETUSERBADGES } from '../helpers/Constant';
import { getUserType, getUserInfo } from '../utils/common';
import { getDataWithToken, postDataWithToken } from '../api/api';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import Bottombar from '../common/bottombar';
import moment from 'moment';
import DotsLoading from '../common/DotsLoading';
import BackButton from '../common/backButton';

function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [groupsData, setGroupsData] = useState([]);
    const [time, setTime] = useState(calculateInitialTime());
    const [weekDates, setWeekDates] = useState([]);
    const [dailyBurned, setDailyBurned] = useState(0);
    const [targetPercentage, setTargetPercentage] = useState(0);
    const [buddyCode, setBuddyCode] = useState('');
    const [position, setUserPosition] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showGameModal, setShowGameModal] = useState(false);
    const [gamesData, setGameData] = useState([]);
    const [countTournamentPlayer, setCountTournamentPlayer] = useState(0);
    const [tournamentData, setTournamentData] = useState();
    const [currentPoint, setCurrentPointData] = useState(0);
    const [badgeData, setBadgeData] = useState([]);
    const [leagueGameComplated, setLeagueGameComplated] = useState(false);
    const [gameResult, setGameResult] = useState([]);

    const userData = getUserInfo();
    const userType = getUserType();

    const updateGroupStatus = async (groupGameId, status) => {
        const data = { gameId: groupGameId, status };
        const groups = await postDataWithToken(GAMEUPDATESTATUS, data);
        if (groups.status === 200) {
            const groupsUser = groups.data.users;
            groupsUser.sort((a, b) => b.point - a.point);
            const middleIndex = Math.floor(groupsUser.length / 2);
            const middlePoint = groupsUser[middleIndex]['point'];

            for (const element of groupsUser) {
                const avtarIndex = groupsUser.findIndex(ele => ele._id === element._id.toString());
                const point = parseInt(element.point);
                let userPosition;
                if (point < middlePoint) {
                    userPosition = { position: 'Lowest' };
                } else if (point == middlePoint) {
                    userPosition = { position: 'Middle' };
                } else if (point > middlePoint) {
                    userPosition = { position: 'Highest' };
                }
                if (avtarIndex > -1) {
                    Object.assign(groupsUser[avtarIndex], userPosition);
                }
            }
            if (status == 'Online') {
                var avtarGroupsUser = await updateGroupUserAvatars(groupsUser);
            }
            return avtarGroupsUser;
        } else {
            console.log(groups.message);
            return [];
        }
    };

    const getUserPosition = (groupsUser, userDataId) => {
        const userIndex = groupsUser.findIndex(user => user.user === userDataId);
        return userIndex !== -1 ? getPositionSuffix(userIndex + 1) : "";
    };

    const updateGroupUserAvatars = async (groupsUser) => {
        const userIds = groupsUser.map(obj => obj.user);
        const userGroupsAvtar = await postDataWithToken(GAMEUSER, { users: userIds });
        if (userGroupsAvtar.status === 200 && userGroupsAvtar.data.length > 0) {
            for (const element of userGroupsAvtar.data) {
                const avtarIndex = groupsUser.findIndex(ele => ele.user === element._id.toString());
                if (avtarIndex > -1) {
                    const userAvtar = { avtar: element.avtar };
                    Object.assign(groupsUser[avtarIndex], userAvtar);
                }
            }
        }
        return groupsUser;
    };

    const tournament = async () => {
        const tournamentStatus = { tournamentStatus: "Upcoming" };
        const tournament = await postDataWithToken(GETTOURNAMENT, tournamentStatus);
        if (tournament.status == 200) {
            if (tournament.data.length > 0) {
                const tournamentId = tournament.data[0]._id;
                setTournamentData(tournament.data[0]);
                const tournamentUser = await getDataWithToken(GETTOURNAMENTUSERBYID + tournamentId);
                if (tournamentUser && tournamentUser.users && tournamentUser.users.length > 0) {
                    setCountTournamentPlayer(tournamentUser.users.length);
                }
            }
        }
    }

    const achievementBudge = async () => {
        const badges = await getDataWithToken(GETBADGES);
        const sliceBadges = (badges) ? badges.slice(0, 3) : [];

        const userBadges = await getDataWithToken(GETUSERBADGES);
        const indexed = Object.fromEntries(userBadges.map(o => [o.badges, o]));
        const budgesCombined = sliceBadges.map(o => ({ ...o, ...indexed[o._id] }));
        setBadgeData(budgesCombined);
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            getWeekDatesBmi();

            const currentPointData = await getDataWithToken(GETUSERINFO);
            setCurrentPointData(currentPointData.currentPoint);

            const groupResponse = await getDataWithToken(GAME);
            const groupGameId = groupResponse._id;
            localStorage.setItem('groupGameId', groupGameId);

            const groupsUser = await updateGroupStatus(groupGameId, 'Online');
            setGroupsData(groupsUser);

            const userPosition = getUserPosition(groupsUser, userData._id);
            setUserPosition(userPosition);

            const bmiInfoData = await postDataWithToken(BMIINFOAVERAGE);
            if (bmiInfoData.data && bmiInfoData.data.length > 0) {
                setDailyBurned(bmiInfoData.data[0].burned);
                setTargetPercentage(bmiInfoData.data[0].percentage);
            }

            if (userData['levelData'] && (userData['levelData']['current'] !== 'Copper' || userData['levelData']['current'] !== 'Bronze')) {
                tournament();
            }

            if (userData['levelData'] && (userData['levelData']['current'] === 'Platinum')) {
                achievementBudge();
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Error fetching data:', error);
        }
    };
 
    const getWeekDatesBmi = async () => {
        const currentDate = moment().startOf('week');
        const endOfWeek = moment().endOf('week');
        const current = moment().format('D');
        const dates = [];
        let sumOfBurned = {};
        let sumOfTarget = {};

        let bmiData = {
            "datePeriod": "Month",
            "date": currentDate.format('MM/DD/YYYY')
        };

        if (userType && userType !== 'guest_user') {
            const bmiProgressionData = await postDataWithToken(BMIPROGRESSION, bmiData);

            if (bmiProgressionData.status == 200) {
                if (bmiProgressionData.data.length > 0) {
                    sumOfBurned = bmiProgressionData.data[0].sumOfBurned;
                    sumOfTarget = bmiProgressionData.data[0].sumOfTarget;
                }
            }
        }

        while (currentDate <= endOfWeek) {
            var date = currentDate.format('D');
            var average = 0;
            if (sumOfBurned && sumOfBurned[date] && sumOfTarget && sumOfTarget[date]) {
                average = ((sumOfBurned[date] / sumOfTarget[date]) * 100).toFixed(2);
            }

            dates.push({
                day: currentDate.format('dddd')[0],
                date: currentDate.format('DD'),
                selected: (current === date) ? 'day_selected' : '',
                average: average
            });
            currentDate.add(1, 'day');
        }
        setWeekDates(dates);
    }
    useEffect(() => {
        const userType = getUserType();
        if (userType && userType !== 'guest_user') {
            fetchData();
        } else {
            setLoading(false);
        }

        const intervalTime = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);

        const intervalGroups = setInterval(fetchData, 300000);

        return () => {
            clearInterval(intervalTime);
            clearInterval(intervalGroups);
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            const userType = getUserType();
            if (userType && userType !== 'guest_user') {
                setLoading(true);
                const groupGameId = localStorage.getItem('groupGameId');
                const status = document.visibilityState === 'visible' ? 'Online' : 'Away';
                updateGroupStatus(groupGameId, status)
                    .then(groupsUser => setGroupsData(groupsUser))
                    .finally(() => setLoading(false));
            }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [updateGroupStatus, setGroupsData, setLoading]);
    
    function getPositionSuffix(position) {
        const suffixes = ['st', 'nd', 'rd'];
        const remainder = position % 10;

        if (position === 11 || position === 12 || position === 13) {
            return `${position}th`;
        }

        return `${position}${suffixes[remainder - 1] || 'th'}`;
    }

    function calculateInitialTime() {
        const now = new Date();
        const midnight = new Date();
        midnight.setHours(24, 0, 0, 0);
        const initialTime = Math.floor((midnight - now) / 1000);
        return initialTime;
    }

    const handleBuddyGameJoin = async () => {
        const codeRef = document.getElementById("buddy_code");

        const errors = {};

        if (codeRef.value === "" || codeRef.value === null) {
            errors[codeRef.name] = `Code is required.`;
        }

        if (Object.keys(errors).length === 0) {
            try {
                const gameJoinData = {
                    "fname": (userData) ? (userData.fname) : '',
                    "lname": (userData) ? (userData.lname) : '',
                    "code": buddyCode,
                    "user": (userData) ? (userData._id) : '',
                }
                const buddydata = await postDataWithToken(BUDDYGAMEJOIN, gameJoinData);
                if (buddydata.status === 200) {
                    localStorage.setItem('joinGameId', buddydata.data._id);
                    localStorage.setItem('joinPlayerId', buddydata.data.currentPlayId);
                    setFormErrors({});
                    navigate(`/${BUDDYTOURNAMENTJOIN}`);
                } else {
                    errors[codeRef.name] = buddydata.message;
                }
            } catch (error) {
                setLoading(false);
                console.log('Error fetching data:', error);
            }
        }
        setFormErrors(errors);
    }

    const handleGoBurn = async () => {
        setLoading(true);
        const response = await getDataWithToken(GETGAMES);
        setGameData(response);
        setShowGameModal(true);
        setLoading(false);
        setLeagueGameComplated(false);
    }

    const closeGameModal = () => {
        setShowGameModal(false);
    };

    const playGame = async (gameTitle) => {
        setLoading(true);
        closeGameModal(false);
        // Group Status Update
        const groupGameId = localStorage.getItem('groupGameId');
        var data = {
            "gameId": groupGameId,
            "status": 'Playing'
        }
        await postDataWithToken(GAMEUPDATESTATUS, data);

        handleGameClosed(89, 25, 56, 4, 63);

        // window.location.href = `uniwebview://openGame?name=${gameTitle}`;
    }

    // End of the Game
    // window.onGameClosed = async function (score, time, heartbeat, accuracy) {
    //     handleGameClosed(score);
    // };

    const handleGameClosed = async (score , time, calories, heartbeat, accuracy) => {
        try {
            const groupGameId = localStorage.getItem('groupGameId');

            // Group Status Update
            const data = {
                "gameId": groupGameId,
                "status": 'Online'
            }
            await postDataWithToken(GAMEUPDATESTATUS, data);

            // Group Status score
            const userResult = {
                "gameId": groupGameId,
                "score": score,
                "burnedCal": calories,
                "accuracy" : accuracy,
                "time" : time,
                "heartbeat" : heartbeat
            }
            const response = await postDataWithToken(GAMEUPDATESCORE, userResult);
            if (response.status === 200) {
                setLoading(false);
                setLeagueGameComplated(true);
                setGameResult(userResult)
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const hours = Math.floor(time / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    const currentDate = moment().format('MMMM, YYYY');
    const AVTAR_IMAGE_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/avtar/`;
    const dailyTarget = (userData != null && userData.dailyTarget) ? userData.dailyTarget : 1000;
    const dailTargetPercentage = Math.round((dailyBurned / dailyTarget) * 100);
    const IMAGE_API_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/games/`;
    const BUGGES_IMAGE_API_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/badges/`;


    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            {(showGameModal) && <BackButton label={t('Profile')} closeFunction={closeGameModal} />}
            {(leagueGameComplated) && <BackButton label={t('Home')} closeFunction={() => setLeagueGameComplated(false)} />}
            <div className='home_wrapper'>
                <div className='profile_wrappar pb-3'>
                    <div className='row align-items-center justify-content-between flex-nowrap g-0'>
                        <div className='col-auto'>
                            <div className='row gx-3 align-items-center'>
                                <div className='col-auto'>
                                    {userData && userData.avtar ?
                                        <img src={AVTAR_IMAGE_URL + userData._id + "/" + userData.avtar} alt='Profile' className='rounded-circle profile_img user_profile border border-white bg-white' />
                                        :
                                        <div className='rounded-circle profile_img user_profile border border-white d-flex justify-content-center align-items-center avtar_alternate'>
                                            <h6 className='mb-0 text-white fw-bold text-uppercase'>{userData && userData.fname && userData.lname ? userData.fname[0] + userData.lname[0] : 'G'}</h6>
                                        </div>
                                    }
                                </div>
                                <div className='col-auto'>
                                    <h5 className='mb-0 text-white'>{t('Hello...')}</h5>
                                    <h4 className='mb-0 text-white fw-bold'>{userType === 'real_user' && userData != null ? userData.fname + ' ' + userData.lname : t('Guest')}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <div className='reward_price d-flex align-items-center gap-1'>
                                <span className='d-flex align-items-center justify-content-center fw-bold point_icon'>P</span>
                                <p className='mb-0 mx-auto text-white fw-bold'>{currentPoint}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={userType === 'guest_user' ? 'blury_block' : ''}>
                    <div className='reward_wrapper position-relative bg-primary'>
                        <FiArrowUpRight className='position-absolute text-light end-0 top-0 mt-2 me-2' onClick={() => navigate(`/${DAILYTARGET}`)} />
                        <h6 className='text-white opacity-75 fs-6 fw-bold mb-0 d-inline'>{t('Daily Target')}</h6>
                        <div className='d-flex align-items-center flex-wrap mb-2'>
                            <div className='d-flex justify-content-between align-items-end my-2 w-100'>
                                <div className='d-flex align-items-center '>
                                    <img src='../images/fireicon.svg' height={30} alt='fireicon'></img>
                                    <h2 className='ps-2 fw-bold text-light mb-0'><span className='text-warning text_shadow_green'>{dailyBurned}</span> / {(Math.round(dailyTarget)).toFixed(0)}</h2>
                                </div>
                                <h2 className='text-white fw-bold mb-0 opacity-75'>{(isNaN(dailTargetPercentage) || dailTargetPercentage == 0) ? 0 : dailTargetPercentage}%</h2>
                            </div>
                            <div className='progressbar_track position-relative mb-4 bg-light w-100'>
                                <div className='progressbar_thumb position-absolute start-0 top-0 bottom-0 bg-warning mw-100 rounded-1' style={{ width: targetPercentage + '%' }}></div>
                                <div className={`reward_divider position-absolute d-flex align-items-center ${targetPercentage <= 25 ? 'reward_pending_start' : 'reward_done_start'}`}>
                                    <span className='d-flex align-items-center justify-content-center rounded-circle point_icon'>{targetPercentage >= 25 ? < BsCheck color='black' /> : 'P'}</span>
                                    {targetPercentage <= 25 ? <p className='mb-0 text-white'>{(Math.round(dailyTarget) * 0.25).toFixed(0)}</p> : ''}
                                </div>
                                <div className={`reward_divider position-absolute d-flex align-items-center ${targetPercentage <= 60 ? 'reward_pending' : 'reward_done_middle'}`}>
                                    <span className='d-flex align-items-center justify-content-center rounded-circle point_icon'>{targetPercentage >= 60 ? < BsCheck color='black' size={20} /> : 'P'}</span>
                                    {targetPercentage <= 60 ? <p className='mb-0 text-white'>{(Math.round(dailyTarget) * 0.6).toFixed(0)}</p> : ''}
                                </div>
                                <div className={`reward_end reward_divider position-absolute d-flex align-items-center ${targetPercentage <= 100 ? 'reward_pending' : 'reward_done_end'}`}>
                                    <span className='d-flex align-items-center justify-content-center rounded-circle point_icon'>{targetPercentage >= 100 ? < BsCheck color='black' /> : 'P'}</span>
                                    {targetPercentage <= 100 ? <p className='mb-0 text-white'>{(Math.round(dailyTarget)).toFixed(0)}</p> : ''}
                                </div>
                            </div>
                        </div>
                        <div className='daily_reward pt-2'>
                            <h5 className='text-white ps-2 opacity-75'>{currentDate}</h5>
                            <div className='d-flex justify-content-around'>
                                {weekDates ? (<>
                                    {
                                        weekDates.map((item, index) => {
                                            return (
                                                <div className={`day reward_collected text-center position-relative ${item['selected']}`} key={index}>
                                                    <div className='date d-flex align-items-center justify-content-center bg-transparent rounded-circle position-relative'
                                                        role="progressbar"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        style={{ '--value': `${item['average']}` }}>
                                                        <p className='my-0 position-relative fw-bold day_name opacity-75 text-white'>{item['date']}</p>
                                                    </div>
                                                    <p className='day_name position-relative fw-bold mb-1'>{item['day']}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </>) : null}
                            </div>
                        </div>
                    </div>
                    <div className='reward_info_wrapper my-4'>
                        <div className={`reward_info_profile mx-4 position-relative bg-primary border-bottom-0 ${userData != null && userData.levelData ? userData.levelData.current === 'Copper' ? 'copper-class' : userData.levelData.current === 'Bronze' ? 'bronze-class' : userData.levelData.current === 'Silver' ? 'silver-class' : userData.levelData.current === 'Gold' ? 'gold-class' : userData.levelData.current === 'Platinum' ? 'platinum-class' : 'default-class' : 'default-class'}`}>
                            <div className='row justify-content-between'>
                                <div className='col-8'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h3 className='mb-0 fw-bold text-center'>{t(userData != null && userData.levelData ? userData.levelData.current === 'Copper' ? 'League Tier 1' : userData.levelData.current === 'Bronze' ? 'League Tier 2' : userData.levelData.current === 'Silver' ? 'League Tier 3' : userData.levelData.current === 'Gold' ? 'League Tier 4' : 'League Tier 5' : 'League Tier')}</h3>
                                        <BiInfoCircle size={18} className='text-white opacity-25 ms-1' />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <img src={userData != null && userData.levelData ?
                                        (userData.levelData.current === 'Copper' ? '../images/copperbadge.png' :
                                            userData.levelData.current === 'Bronze' ? '../images/bronzebadge.png' :
                                                userData.levelData.current === 'Silver' ? '../images/silverbadge.png' :
                                                    userData.levelData.current === 'Gold' ? '../images/goldbadge.png' :
                                                        '../images/platinumbadge.png') : '../images/copperbadge.png'
                                    } alt='Reward user' className='rounded-circle home_profile_img position-absolute profile_img' />
                                </div>
                            </div>
                        </div>
                        <div className={`reward_chart p-3 ${userData != null && userData.levelData ? userData.levelData.current === 'Silver' ? 'silver-chart' : userData.levelData.current === 'Gold' ? 'gold-chart' : userData.levelData.current === 'Platinum' ? 'platinum-chart' : 'default-chart' : 'default-chart'}`}>
                            <div className='row'>
                                <div className='col-8 px-3'>
                                    <h4 className='text-white mb-1 opacity-75'><span className='me-1'>Ends in</span>{hours}:{minutes}:{seconds}</h4>
                                </div>
                                <div className='col-12 px-3'>
                                    <p className='mb-2 text-white opacity-50 lh-1'>{t('You have still chance, Start burning your calorie you are at')}{" " + position + " "}{t('place')}</p>
                                </div>
                            </div>
                            <ul className='reward_list p-0 m-0'>
                                {groupsData ? (<>
                                    {
                                        groupsData.map((item, index) => {
                                            return (
                                                <li key={index} className={`d-flex align-items-center justify-content-between py-1 px-2 ${item.status === 'Online' ? 'active' : ''}`}>
                                                    <div className='content_part d-flex align-items-center w-100 flex-nowrap'>
                                                        <div className='d-block reward_item_wrapper count rounded-circle'>
                                                            <span className='rounded-circle text-white'>{index + 1}</span>
                                                        </div>
                                                        <div className='d-block reward_profile_wrapper'>
                                                            {item.avtar ? (
                                                                <img src={AVTAR_IMAGE_URL + item.user + "/" + item.avtar} alt='Reward user' className='rounded-circle profile_img' />
                                                            ) : (
                                                                <div className='rounded-circle profile_img text-center d-flex align-items-center justify-content-center avtar_alternate border border-white'>
                                                                    {item.name.split(' ').map((word, index) => (
                                                                        <h6 key={index} className='mb-0 text-white fw-bold text-uppercase'>
                                                                            {word[0]}
                                                                        </h6>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='user_info'>
                                                            <h5 className='fw-bold mb-0 text-white'>{item.name}</h5>
                                                            <p className={`mb-0 opacity-75 ${item.status === 'Online' ? 'yellow' : 'text-light'}`}>{t(item.status)}</p>
                                                        </div>
                                                        <div className='d-block reward_price_wrapper'>
                                                            <div className='reward_price d-flex align-items-center ms-auto p-1 gap-1'>
                                                                <span className='fw-bold d-flex align-items-center justify-content-center rounded-circle point_icon'>P</span>
                                                                <h4 className='my-0 fw-bold text-white m-auto'>{item.point}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='d-block reward_item_wrapper'>
                                                            {item.position === 'Highest' ? (
                                                                <IoIosArrowDropupCircle size={18} color='#10FF88' />
                                                            ) : item.position === 'Lowest' ? (
                                                                <IoIosArrowDropdownCircle size={18} color='#F55A68' />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </>) : null
                                }
                            </ul>
                            <button type='button' className='mx-auto cta_button d-flex align-items-center justify-content-center position-relative bg-warning mt-3' onClick={handleGoBurn}>
                                <span className='text-dark fw-bold'>{t('Go Burn Calories')}</span>
                                <IoIosArrowForward className='next-icon' />
                            </button>
                        </div>
                    </div>
                </div>
                {userType !== 'real_user' || (userData && userData.levelData && userData.levelData.current == 'Copper') ? (
                    <div className='buddy_tournament tournament_wrapper bg-secondary mb-3'>
                        <div className='d-flex align-items-center mb-3'>
                            <h3 className='mb-0 text-white fw-bold pe-2'>{t('Buddy Tournament')} <BiInfoCircle className='text-light' /></h3>
                        </div>
                        <form action='/' method='post' className='d-flex' autoComplete='off'>
                            <div className='form_field position-relative me-2 w-100'>
                                <input type='text' className='w-100 text-white bg-transparent' id='buddy_code' name='buddy_code' placeholder={t('Paste your buddy ID')} onChange={(e) => (setBuddyCode(e.target.value), setFormErrors({}))} />
                                <p className='field_error'>{t(formErrors['buddy_code'])}</p>
                            </div>
                            <button type='button' className='bg-info go_button text-white d-flex align-items-center justify-content-center border-0' disabled={(buddyCode) ? false : true} onClick={() => handleBuddyGameJoin()}>
                                {t('Go')}<BiRightArrowAlt size={25} color="white" />
                            </button>
                        </form>
                    </div>
                ) : (
                    <div className={`tournament_wrapper mb-3
                    ${userData.levelData.current === 'Bronze' ? 'verus_tournament'
                            : userData.levelData.current === 'Silver' ? 'paid_tournament'
                                : userData.levelData.current === 'Gold' ? 'free_tornament'
                                    : userData.levelData.current === 'Platinum' ? 'achievement_tournament'
                                        : 'bg-primary'}`}>
                        <div className='d-flex justify-content-between'>
                            <div className='tournament p-0 pb-2 position-relative'>
                                <div className='d-flex'>
                                    <h3 className='mb-0 text-white fw-bold pe-2'>{t('Buddy Tournament')} <BiInfoCircle className='text-light' /></h3>
                                </div>
                                <span className='league verus_league'>{userData && userData.levelData ? t(userData.levelData.current) : ""}</span>
                            </div>
                        </div>

                        <form action='/' method='post' autoComplete='off'>
                            <div className='form_field position-relative w-100'>
                                <input type='text' className='w-100 text-white bg-transparent border border-light' id='buddy_code' name='buddy_code' placeholder={t('Paste your buddy ID')} onChange={(e) => (setBuddyCode(e.target.value), setFormErrors({}))} />
                                <p className='field_error'>{t(formErrors['buddy_code'])}</p>
                            </div>
                            <div className='d-flex justify-content-between unlock_lvl mt-4 gap-3 flex-wrap'>
                                <button type='button' className='mx-auto d-flex align-items-center justify-content-center position-relative border-0 text-white bg-info' onClick={() => navigate(`/${BUDDYTOURNAMENTCREATE}`)}>
                                    <span>{t('Create Lobby')}</span>
                                </button>
                                <button type='button' className='mx-auto d-flex align-items-center justify-content-center position-relative border-0 text-white go_button' disabled={(buddyCode) ? false : true} onClick={() => handleBuddyGameJoin()}>
                                    <span className='text-white'>{t('Go Burn Together')}</span>
                                    <IoIosArrowForward className='next-icon text-white' />
                                </button>
                            </div>
                        </form>
                    </div>)}

                {/* Versus Games */}
                {userData && userData.levelData && userData.levelData.current !== 'Copper' ? (
                    <div className='tournament verus_tournament mb-3 bg-secondary position-relative overflow-hidden'>
                        <div className='d-flex'>
                            <h3 className='mb-0 text-white fw-bold pe-2'>{t('Versus Games')} <BiInfoCircle className='text-light' /></h3>
                            <span onClick={() => navigate(`/${VERSUS}`)} className='arrow_top_right text-warning ms-auto text-end'>{t('Play')}<FiArrowUpRight />
                            </span>
                        </div>
                        <span className='league verus_league'>{t('Bronze')}</span>
                        <p className='mt-3 mb-0'>
                            {t('Go challenge other player and same time burn your calories in competitive game')}
                        </p>
                        <img src='../images/1v1.png' alt='1v1' className='position-absolute bottom-0 end-0 pe-none'></img>
                    </div>
                ) : (
                    <div className='tournament verus_tournament mb-3 bg-secondary position-relative overflow-hidden'>
                        <div className='overlay d-flex align-items-center justify-content-between versus_overlay'>
                            <div className='overlay_info pe-3'>
                                <h3 className='mb-0 fw-bold mb-2'>{t('Versus Games')}</h3>
                                <span className='mb-0 py-1 px-2'>{t('Unlocked in Bronze')}</span>
                            </div>
                            <span className='lock_icon text-center d-block rounded-circle'>
                                <BiSolidLock color='515157' size={25} className=' my-1' />
                            </span>
                        </div>
                    </div>
                )}

                {/* Tournament (Paid) */}
                {userData !== null && userData.levelData && (userData.levelData.current == 'Silver' || userData.levelData.current == 'Gold' || userData.levelData.current == 'Platinum') ? (<>
                    <div className='tournament paid_tournament mb-3 position-relative overflow-hidden'>
                        <div className='d-flex'>
                            <h3 className='mb-0 text-white fw-bold pe-2'>{t('Paid Tournament')} <BiInfoCircle className='text-light' /></h3>
                            {tournamentData ? <span className='arrow_top_right text-warning ms-auto text-end' onClick={() => navigate(`/${TOURNAMENT}`, { state: { tournamentIs: 'paid', tournamentData: tournamentData } })}>{t('Play')}<FiArrowUpRight /></span> : null}
                        </div>
                        <span className='league paid_league'>{t('Silver')}</span>
                        {
                            tournamentData ?
                                <>
                                    <div className='tournament_cost_chart d-flex align-items-center py-2 px-3 my-2'>
                                        <div className='text-center'>
                                            <span className='d-block text-light'>{t('Prize Pool')}</span>
                                            <h2 className='text-white mb-0'>${tournamentData.prizeAmount}</h2>
                                        </div>
                                        <div className='text-center ps-3 ms-3'>
                                            <span className='d-block text-light'>{t('Players')}</span>
                                            <h2 className='text-white mb-0'>{countTournamentPlayer}</h2>
                                        </div>
                                        <div className='text-center ps-3 ms-3'>
                                            <span className='d-block text-light'>{t('Entry fee')}</span>
                                            <h2 className='text-white mb-0'>${tournamentData.tournamentFee}</h2>
                                        </div>
                                    </div>
                                    <p className='mb-0'>{t('Go challenge other player and same time burn your calories in competitive game')}</p>
                                </> :
                                <div className='coming_soon tournament_cost_chart py-3 px-4 position-absolute inset-0 w-100 d-flex align-items-center justify-content-center'>
                                    <h2 className='mb-0 text-white fs-4 opacity-75'>{t('Coming Soon')}<DotsLoading /></h2>
                                </div>
                        }
                        <img src='../images/trophy.png' alt='trophy' className='position-absolute bottom-0 end-0 pe-none'></img>
                    </div>
                </>) : (<>
                    <div className='tournament paid_tournament mb-3 position-relative overflow-hidden'>
                        <div className='overlay d-flex align-items-center justify-content-between'>
                            <div className='overlay_info pe-3 text-start'>
                                <h3 className='mb-0 fw-bold mb-2'>{t('Paid Tournament')}</h3>
                                <span className='mb-0 py-1 px-2'>{t('Unlocked in Silver')}</span>
                            </div>
                            <span className='lock_icon text-center d-block rounded-circle'>
                                <BiSolidLock color='515157' size={25} className=' my-1' />
                            </span>
                        </div>
                    </div>
                </>)}

                {/* Tournament (Free) */}
                {userData != null && userData.levelData && (userData.levelData.current == 'Gold' || userData.levelData.current == 'Platinum') ? (<>
                    <div className='tournament free_tornament mb-3 position-relative overflow-hidden'>
                        <div className='d-flex'>
                            <h3 className='mb-0 text-white fw-bold pe-2'>{t('Free Tournament')} <BiInfoCircle className='text-light' /></h3>
                            {tournamentData ? <span className='arrow_top_right text-warning ms-auto text-end' onClick={() => navigate(`/${TOURNAMENT}`, { state: { tournamentIs: 'free', tournamentData: tournamentData } })}>{t('Play')}<FiArrowUpRight /></span> : null}
                        </div>
                        <span className='league free_league'>{t('Gold')}</span>
                        {
                            tournamentData ?
                                <>
                                    <div className='tournament_cost_chart d-flex align-items-center py-2 px-3 my-2'>
                                        <div className='text-center'>
                                            <span className='d-block text-light'>{t('Prize Pool')}</span>
                                            <h2 className='text-white mb-0'>${tournamentData.prizeAmount}</h2>
                                        </div>
                                        <div className='text-center ps-3 ms-3'>
                                            <span className='d-block text-light'>{t('Players')}</span>
                                            <h2 className='text-white mb-0'>{countTournamentPlayer}</h2>
                                        </div>
                                    </div>
                                    <p className='mb-0'>{t('Go challenge other player and same time burn your calories in competitive game')}</p>
                                </> :
                                <div className='coming_soon tournament_cost_chart py-3 px-4 position-absolute inset-0 w-100 d-flex align-items-center justify-content-center'>
                                    <h2 className='mb-0 text-white fs-4 opacity-75'>{t('Coming Soon')}<DotsLoading /></h2>
                                </div>
                        }
                        <img src='../images/trophy.png' alt='trophy' className='position-absolute bottom-0 end-0 pe-none'></img>
                    </div>
                </>) : (<>
                    <div className='tournament free_tornament mb-3 position-relative overflow-hidden'>
                        <div className='overlay d-flex align-items-center justify-content-between'>
                            <div className='overlay_info pe-3 text-start'>
                                <h3 className='mb-0 fw-bold mb-2'>{t('Free Tournament')}</h3>
                                <span className='mb-0 py-1 px-2'>{t('Unlocked in Gold')}</span>
                            </div>
                            <span className='lock_icon text-center d-block rounded-circle'>
                                <BiSolidLock color='515157' size={25} className=' my-1' />
                            </span>
                        </div>
                    </div>
                </>)}

                {/* Achivements Badges */}
                {userData != null && userData.levelData && userData.levelData.current == 'Platinum' ? (<>
                    <div className='tournament achievement_tournament bg-secondary achivement position-relative overflow-hidden'>
                        <div className='tournament p-0 pb-2 position-relative overflow-hidden'>
                            <div className='d-flex'>
                                <h3 className='mb-0 text-white fw-bold pe-2'>{t('Achivements Badges')} <BiInfoCircle className='text-light' /></h3>
                                {(badgeData.length > 0) ?
                                    <span className='arrow_top_right text-warning ms-auto text-end' onClick={() => navigate(`/${ACHIEVEMENT}`)}><FiArrowUpRight /></span> : ''}
                            </div>
                            <span className='league achievement_league'>{t('Platinum')}</span>
                        </div>
                        {(badgeData.length > 0) ?
                            <div className='achivements_chart d-flex justify-content-around gap-3'>
                                {
                                    badgeData.map((badge, index) => (
                                        <div key={index} className={`text-center ${badge.isCompleted ? "complated" : badge.badges ? '' : 'lock'}`}>
                                            <img src={badge.badges ? BUGGES_IMAGE_API_URL + badge.image : '../images/lock-badge.png'} alt={badge.title + "badge"} />
                                            <h6 className='mt-2 mb-1 text-white'>{badge.title}</h6>
                                            <p className='mb-0 text-white opacity-75 lh-1'>{(badge.userTarget ? badge.userTarget : 0)}/{badge.target}</p>
                                        </div>
                                    ))
                                }
                            </div> :
                            <div className='coming_soon tournament_cost_chart py-3 px-4 position-absolute inset-0 w-100 d-flex align-items-center justify-content-center'>
                                <h2 className='mb-0 text-white fs-4 opacity-75'>{t('Coming Soon')} <DotsLoading /></h2>
                            </div>}
                    </div>
                </>) : (<>
                    <div className='tournament achievement_tournament position-relative overflow-hidden'>
                        <div className='overlay d-flex align-items-center justify-content-between'>
                            <div className='overlay_info pe-3 text-start'>
                                <h3 className='mb-0 fw-bold mb-2'>{t('Achivements Badges')}</h3>
                                <span className='mb-0 py-1 px-2'>{t('Unlocked in Platinum')}</span>
                            </div>
                            <span className='lock_icon text-center d-block rounded-circle'>
                                <BiSolidLock color='515157' size={25} className=' my-1' />
                            </span>
                        </div>
                    </div>
                </>)}
            </div>

            {/* GAME modal */}
            <div className={`modal ${showGameModal ? 'show' : ''}`} style={{ display: showGameModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content tournament_modal">
                        <div className="modal-body p-3">
                            <h3 className='mb-3 fw-bold text-white'>{t('Game List')}</h3>
                            {gamesData.map((game, index) => (
                                userData != null && userData.levelData != null && ((userData.levelData.current == 'Copper' && game.minlevel === 'Copper') || (userData.levelData.current === 'Bronze' && (game.minlevel === 'Copper' || game.minlevel === 'Bronze')) || (userData.levelData.current === 'Silver' && (game.minlevel !== 'Gold' || game.minlevel !== 'Platinum')) || (userData.levelData.current === 'Gold' && game.minlevel !== 'Platinum') || (userData.levelData.current === 'Platinum')) ?
                                    <div key={game._id} className={`d-flex game_wrapper align-items-start justify-content-end flex-wrap px-0 ${index + 1 < gamesData.length ? '' : 'border-0'}`}>
                                        <img src={(IMAGE_API_URL + game.image)} alt="Logo" />
                                        <div className='game_info w-100'>
                                            <h5 className='mb-0 text-capitalize'>{game.title}</h5>
                                            <h6 className={`text-white mb-1
                                            ${(game.minlevel === 'Bronze' || game.minlevel === 'Copper') ? 'verus_tournament'
                                                    : game.minlevel === 'Silver' ? 'paid_tournament'
                                                        : game.minlevel === 'Gold' ? 'free_tornament'
                                                            : game.minlevel === 'Platinum' ? 'achievement_tournament'
                                                                : 'bg-primary'}`}>{t(game.minlevel)}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: game.description }}></div>
                                        </div>
                                        <div className='play_button_Wrapper'>
                                            <button type='button' onClick={() => playGame(game.title)}>{t('Play')}< IoIosArrowForward /></button>
                                        </div>
                                    </div>
                                    : ''
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* RESULT modal */}
            <div className={`modal ${leagueGameComplated ? 'show' : ''}`} style={{ display: leagueGameComplated ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content versus_result_modal bg-transparent">
                        <div className="modal-body p-0">
                            <div className='bmi_profile d-flex justify-content-center align-items-stretch text-white position-relative bg-secondary w-100 p-2 rounded-4 mb-4'>
                                <div className='text-center p-2 d-flex justify-content-between flex-column'>
                                    <h6 className='mb-2 mt-1 text-light'>{t('Score')}</h6>
                                    <h5 className='mb-1 fw-bold'>{gameResult.score}</h5>
                                </div>
                                <div className='text-center p-2 d-flex justify-content-between flex-column'>
                                    <h6 className='mb-2 mt-1 text-light'>{t('Calorie Burn')}</h6>
                                    <h5 className='mb-1 fw-bold'><img className='me-2' src='../images/fireicon.svg' height={20} alt='fireicon' />{gameResult.burnedCal}</h5>
                                </div>
                                <div className='text-center p-2 d-flex justify-content-between flex-column'>
                                    <h6 className='mb-2 mt-1 text-light'>{t('Avg Accuracy')}</h6>
                                    <h5 className='mb-1 fw-bold'>{gameResult.accuracy}%</h5>
                                </div>
                                <div className='text-center p-2 d-flex justify-content-between flex-column'>
                                    <h6 className='mb-2 mt-1 text-light'>{t('Time')}</h6>
                                    <h5 className='mb-1 fw-bold'>{gameResult.time} <span className='opacity-50'>min</span></h5>
                                </div>
                                <div className='text-center p-2 d-flex justify-content-between flex-column'>
                                    <h6 className='mb-2 mt-1 text-light'>{t('Heart Rate Avg')}</h6>
                                    <h5 className='mb-1 fw-bold'>{gameResult.heartbeat}</h5>
                                </div>
                            </div>
                            <div className='d-flex align-items-center gap-sm-4 gap-3 justify-content-center'>
                                <button className='cta_button fw-bold border-0 text-primary bg-warning' onClick={handleGoBurn}>Play Again</button>
                                <button className='cta_button fw-bold border-0 text-white bg-danger' onClick={() => setLeagueGameComplated(false)}>Home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(showGameModal || leagueGameComplated) && <div className="modal-backdrop show"></div>}
            <Bottombar />
        </div>
    );
}

export default Home;