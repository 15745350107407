import React, { useState, useEffect } from 'react';
import { GETGAMES, GAMEUPDATESTATUS, GAMEUPDATESCORE } from "../helpers/Constant";
import { getDataWithToken, postDataWithToken } from '../api/api';
import { IoIosArrowForward } from 'react-icons/io';
import { getUserInfo } from '../utils/common';
import { BiSolidLock } from 'react-icons/bi';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import Bottombar from '../common/bottombar';


function Game() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const [gamesData, setGameData] = useState([]);
    const userData = getUserInfo();
    const [loading, setLoading] = useState(true);
    const IMAGE_API_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/games/`;

    useEffect(() => {
        async function fetchGameData() {
            try {
                const response = await getDataWithToken(GETGAMES);
                setGameData(response);
                setLoading(false);
            } catch (error) {
                console.log('Error fetching data:', error);
                setLoading(false);
            }
        }
        fetchGameData()
    }, []);

    const premiumGamesData = [];
    const gamePlay = async (title) => {
        setLoading(true);

        // Group Status Update
        const groupGameId = localStorage.getItem('groupGameId');
        var data = {
            "gameId": groupGameId,
            "status": 'Playing'
        }
        await postDataWithToken(GAMEUPDATESTATUS, data);
        window.location.href = `uniwebview://openGame?name=${title}`;
    }

    // End of the Game
    window.onGameClosed = async function (score , time, calories, heartbeat, accuracy) {
        handleGameClosed(score ,time, calories, heartbeat, accuracy);
    };

    const handleGameClosed = async (score , time, calories, heartbeat, accuracy) => {
        try {
            const groupGameId = localStorage.getItem('groupGameId');

            // Group Status Update
            const data = {
                "gameId": groupGameId,
                "status": 'Online'
            }
            await postDataWithToken(GAMEUPDATESTATUS, data);

            // Group Status score
            const pointData = {
                "gameId": groupGameId,
                "score": score,
                "burnedCal": calories,
                "accuracy" : accuracy,
                "time" : time,
                "heartbeat" : heartbeat
            }
            await postDataWithToken(GAMEUPDATESCORE, pointData);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const levelNames = ["Copper", "Bronze", "Silver", "Gold", "Platinum"];
    const levelMapping = {};
    let userLevel = 0;
    levelNames.forEach((level, index) => {
        levelMapping[level] = index + 1;
        if (userData) {
            if (userData.levelData.top === level) {
                userLevel = index + 1;
            }
        }
    });

    return (
        <>
            <div className='wrapper h-100'>
                {loading ?
                    <div className="loader">
                        <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                    </div>
                    : ""}
                <div className="tabs-container h-100">
                    <div className="tabs d-flex">
                        <button className={`tab border-0 ${activeTab === 0 ? 'active' : 'text-white bg-transparent '}`} onClick={() => setActiveTab(0)}>{t('Games')}</button>
                        <button className={`tab border-0 ${activeTab === 1 ? 'active' : 'text-white bg-transparent '}`} onClick={() => setActiveTab(1)}>{t('Premium games')}</button>
                    </div>
                    <div className="tab-content text-white">
                        {activeTab === 0 && (
                            <>
                                {gamesData.map((game) => (
                                    <div key={game._id} className='d-flex game_wrapper align-items-start justify-content-end flex-wrap'>
                                        <img src={(IMAGE_API_URL + game.image)} alt="Logo" />
                                        <div className='game_info'>
                                            <h5 className='mb-0'>{game.title}</h5>
                                            <h6 className='text-white'>{t('League')}{levelMapping[game.minlevel] < 10 ? " " + `0${levelMapping[game.minlevel]}` : " " + levelMapping[game.minlevel]}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: game.description }}></div>
                                        </div>
                                        <div className='play_button_Wrapper'>
                                            {
                                                userLevel >= levelMapping[game.minlevel] ?
                                                    <button className='w-100' type='button' onClick={() => gamePlay(game.title)}>{t('Play')}< IoIosArrowForward /></button> :
                                                    <span className='lock_icon text-center d-inline-block rounded-circle'>
                                                        <BiSolidLock color='515157' size={22} className=' my-1' />
                                                    </span>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        {activeTab === 1 && (
                            <>
                                {premiumGamesData.map((game) => (
                                    <div key={game.id} className='d-flex game_wrapper'>
                                        <div className='game_image_wrapper'>
                                            {game.imageCount === 1 ? (
                                                <img src={game.imageUrls[0]} width='100%' alt="Logo" />
                                            ) : (
                                                game.imageUrls.map((imageUrl, index) => (
                                                    <img key={index} src={imageUrl} width={75} height={75} alt="Logo" />
                                                ))
                                            )}
                                        </div>
                                        <div className='game_info ps-3 me-4 d-flex flex-column justify-content-center'>
                                            <h5 className='mb-0'>{game.name}</h5>
                                            <p className='mb-0'>{game.description}</p>
                                            <button type='button' className='w-auto mt-2 px-4'>$37</button>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                <Bottombar />
            </div>
        </>
    );
}
export default Game