import React, { useEffect, useRef, useState } from 'react';
import BackButton from '../common/backButton';
import { FaCrown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getDataWithToken, postDataWithToken } from '../api/api';
import { HOME, BUDDYGAMEUPDATE, BUDDYGAMEUPDATESTATUS, BUDDYGAMEUPDATESCORE, BUDDYGAMEBYID, GAMEUPDATESTATUS } from '../helpers/Constant';
import { Oval } from 'react-loader-spinner';
import { FaCheck } from 'react-icons/fa'
import { IoMdCopy } from 'react-icons/io';
import { getUserInfo } from '../utils/common';
import { useTranslation } from 'react-i18next';
import { GrFormNext } from 'react-icons/gr';

function BuddyTournamentJoin() {
    const { t } = useTranslation();
    const [buddyCode, setBuddyCode] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userGameIsCompleted, setUserGameIsCompleted] = useState(false);
    const [gameTitle, setGameTitle] = useState('');
    const joinBuddyId = localStorage.getItem('joinGameId');
    const playerId = localStorage.getItem('joinPlayerId');
    const groupGameId = localStorage.getItem('groupGameId');
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const userData = getUserInfo();
    const [playersCount, setPlayersCount] = useState(0);
    const [isCompleted, setIsCompleted] = useState();


    useEffect(() => {
        async function playerData() {
            try {
                if (joinBuddyId) {
                    const joinPlayer = await getDataWithToken(BUDDYGAMEBYID + joinBuddyId);
                    if (joinPlayer) {
                        var users = joinPlayer.users;
                        setGameTitle(joinPlayer.game.title);
                        if (users) {
                            const highestPoint = Math.max(
                                ...users
                                    .filter(user => user.status === 'Ready')
                                    .map(user => user.burnedCal)
                            );

                            const updatedUsers = users.map(user => ({
                                ...user,
                                highest: user.status === 'Ready' && user.burnedCal === highestPoint,
                            }));

                            if (userData) {
                                users.forEach((user) => {
                                    if (user.user === userData._id && user.status === 'Ready') {
                                        setUserGameIsCompleted(true);
                                    }
                                });
                            }
                            setUsers(updatedUsers);
                            setBuddyCode(joinPlayer.code);
                            setPlayersCount(updatedUsers.length);

                        }
                    } else {
                        setUsers([]);
                        setBuddyCode('');
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log('Error fetching data:', error);
            }
        }
        playerData();

        const intervalId = setInterval(playerData, 3000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleReady = async () => {
        // Group Status Update
        var data = {
            "gameId": groupGameId,
            "status": 'Playing'
        }
        await postDataWithToken(GAMEUPDATESTATUS, data);

        const userStatusData = {
            buddyGameId: joinBuddyId,
            user: {
                id: playerId,
                status: 'Ready'
            }
        };
        await postDataWithToken(BUDDYGAMEUPDATESTATUS, userStatusData);

        var gameName = document.getElementById('gameTitle');
        const playerName = userData.fname + '_' + userData.lname;
        window.location.href = `uniwebview://openGame?name=${gameName}&room=${buddyCode}&playerName=${playerName}`;
    }

    const burnedCalories = [350, 350, 345];

    const handleGoBurn = async () => {
        const payloadBuddyData = {
            buddyGameId: joinBuddyId,
            burnedCalories: burnedCalories,
        };

        const buddyPlayerUpdateScore = await postDataWithToken(BUDDYGAMEUPDATESCORE, payloadBuddyData);
        console.log(buddyPlayerUpdateScore);
        setIsCompleted(true);
    }

    // End of the Game
    window.onGameClosed = async function (score, time, heartbeat, accuracy) {
        handleGameClosed(score);
    };
    const handleGameClosed = async (score) => {
        try {
            const payloadBuddyData = {
                buddyGameId: joinBuddyId,
                user: {
                    id: playerId,
                    burnedCal: score,
                    status: "Ready"
                }
            }

            // Group Status Update
            var data = {
                "gameId": groupGameId,
                "status": 'Online'
            }
            await postDataWithToken(GAMEUPDATESTATUS, data);

            const buddyPlayerUpdateScore = await postDataWithToken(BUDDYGAMEUPDATE, payloadBuddyData);
            setUserGameIsCompleted(true);
            const playerData = buddyPlayerUpdateScore.data['users'];
            const allComplete = playerData.every(player => player.status === "Ready");
            if (allComplete) {
                const completedStatus = {
                    buddyGameId: joinBuddyId,
                    isCompleted: true
                }
                await postDataWithToken(BUDDYGAMEUPDATE, completedStatus);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleCopyCode = (event) => {
        inputRef.current.select();
        document.execCommand('copy');
        inputRef.current.setSelectionRange(0, 0);
    };

    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            <BackButton label={t('Home')} closeFunction={() => navigate(`/${HOME}`)} />
            <div className='buddy_tournament_wrapper'>
                <h3 className='fw-bold mt-3 text-white opacity-75'>{t('Buddy Tournament')}</h3>
                <p className='mb-0 pb-2 text-white opacity-50'>The standard Lorem Ipsum passage, used since the 1500s <br /> "Lorem ipsum dolor sit amet, consectetur adipiscing elit, The standard Ipsum passage, used since the 1500s</p>
                <div className='mt-4 mb-3 position-relative'>
                    <input type='text' className='w-100 text-light px-3' id='buddyId' name='BuddyId' placeholder={t('Your buddy ID')} value={buddyCode} ref={inputRef} readOnly />
                    <span className='bg-info copy position-absolute rounded-circle'>
                        <IoMdCopy size={18} className='text-white' onClick={handleCopyCode} />
                    </span>
                </div>
            </div>
            {/* GAME RESULT */}
            <div className='lobby_wrapper'>
                <h3 className='text-white fw-bold mb-3 text-white opacity-75'>{t('Lobby Members')}</h3>
                <div className='members_list px-4 py-3'>
                    {users.map((user, index) => {
                        return (
                            <div key={user._id} className={`item d-flex align-items-center gap-2`}>
                                <div className='member_profile rounded-circle position-relative'>
                                    <h6 className='text-white mb-0'>{user.user !== null ? user.name.split(" ").slice(0, 2).map(word => word[0].toUpperCase()).join("") : 'P'}</h6>
                                </div>
                                <p className='mb-0 text-white user_name'>{user.user !== null ? user.name : 'Player_' + index}</p>
                                <p className='mb-0 ms-auto user_status opacity-75'>{(user.status !== 'Completed') ? user.userStatus : user.Completed}</p>
                                <div className={`d-flex align-items-center justify-content-center gap-1 game_status ${(user.status == 'Waiting') ? 'waiting' : ''}`}>
                                    {(user.status !== 'Waiting') ? <span className='rounded-circle'>< FaCheck /></span> : null}
                                    <p className='mb-0'>{(user.status == 'Waiting') ? t('Waiting...') : t(user.status)}</p>
                                </div>

                            </div>
                        )
                    })}
                </div>

                <input type='hidden' id='gameTitle' value={gameTitle} />
                {!userGameIsCompleted ? (
                    <button
                        type='button'
                        className='mx-auto mt-4 cta_button d-flex align-items-center justify-content-center position-relative border-0 bg-warning'
                        onClick={handleReady}
                    >
                        <span className='fw-bold'>{t('Ready')}</span>
                    </button>
                ) : <button
                    type='button'
                    className='mx-auto mt-4 cta_button d-flex align-items-center justify-content-center position-relative border-0 bg-warning'
                    onClick={handleGoBurn}
                    disabled={playersCount < 3 || !users.every(user => user.status === 'Ready')}
                >
                    <span className='fw-bold'>{t('Go Burn Calories')}</span>
                    <GrFormNext size={20} />
                </button>}
                <p className='mb-0 mt-4 pt-2 text-warning text-center'>
                    {(playersCount === true) ? t('Waiting for result...') : (playersCount < 3) ? t('Waiting for players to join...') : ''}
                </p>
            </div>

            <div className={`modal ${isCompleted ? 'show' : ''}`} style={{ display: isCompleted ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className='members_list px-4 py-3'>
                        {
                            users.map((user, index) => {
                                return (
                                    <div key={user._id} className={`item d-flex align-items-center gap-2 ${user.highest ? 'toper_user' : ''}`}>
                                        <p className='mb-0 text-white user_name'>{user.name}</p>
                                        <p className='mb-0 ms-auto user_status'>{user.burnedCal}</p>
                                        {(user.highest) ? <FaCrown className='position-absolute' /> : ''}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {isCompleted && (
                <div className="modal-backdrop show"></div>
            )}

        </div>
    );
}

export default BuddyTournamentJoin;