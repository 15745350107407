import React, { useEffect, useState, useRef } from 'react';
import { IoMdCopy } from 'react-icons/io';
import { FiShare } from 'react-icons/fi';
import { FaCrown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { postDataWithToken, getDataWithToken, getData } from '../api/api';
import { BUDDYGAME, GETGAMES, HOME, BUDDYGAMEUPDATE, BUDDYGAMEUPDATESTATUS, BUDDYGAMEUPDATESCORE, BUDDYGAMEJOIN, BUDDYGAMEBYID, GAMEUPDATESTATUS, GAMEUSER } from '../helpers/Constant';
import { getUserInfo } from '../utils/common';
import { Oval } from 'react-loader-spinner';
import { IoIosArrowForward } from 'react-icons/io'
import { FaCheck } from 'react-icons/fa'
import { GrFormNext } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import BackButton from '../common/backButton';


function BuddyTournamentCreate() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [buddyCode, setBuddyCode] = useState('');
    const [users, setUsers] = useState([]);
    const [gamesData, setGameData] = useState([]);
    const [showGameModal, setShowGameModal] = useState(false);
    const [lobbyName, setLobbyName] = useState('');
    const [loading, setLoading] = useState(true);
    const [gameIsCompleted, setGameIsCompleted] = useState(false);
    const [gameStatusMsg, setGameStatusMsg] = useState();
    const [gameTitle, setGameTitle] = useState('');
    const inputRef = useRef(null);
    const IMAGE_API_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/games/`;
    const userData = getUserInfo();
    const buddyId = localStorage.getItem('buddyId');
    const groupGameId = localStorage.getItem('groupGameId');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredGames, setFilteredGames] = useState([]);
    const [playersCount, setPlayersCount] = useState(0);
    const [playerDetails, setPlayerDetails] = useState([]);
    const [isCompleted, setIsCompleted] = useState();

    

    useEffect(() => {
        async function fetchGameData() {
            try {
                setLoading(true);
                if (buddyId) {
                    const response = await getDataWithToken(BUDDYGAMEBYID + buddyId);
                    if (response) {
                        const isCompleted = response.isCompleted;
                        setGameTitle(response.game.title);
                        if (isCompleted || isCompleted === undefined) {
                            if (isCompleted) {
                                setGameIsCompleted(true);
                                setLoading(false);
                                return false;
                            }
                            localStorage.removeItem("buddyId");
                            const response = await getData(GETGAMES);
                            setGameData(response);
                            setFilteredGames(response);
                            setShowGameModal(true);
                        } else {
                            setBuddyCode(response.code);
                            setLobbyName(response.name);
                            fetchPlayerData(buddyId);
                            setGameStatusMsg(false);
                            response['users'].forEach((user) => {
                                if (user.user === userData._id) {
                                    if (user.status === 'Ready') {
                                        setGameStatusMsg(true);
                                    }
                                }
                            });
                        }
                    }
                } else {
                    const response = await getData(GETGAMES);
                    setGameData(response.data);
                    setFilteredGames(response.data);
                    setShowGameModal(true);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Error fetching data:', error);
            }
        }
        fetchGameData();

        const fetchJoinDataInterval = () => {
            const buddyId = localStorage.getItem('buddyId');
            if (buddyId) {
                fetchPlayerData(buddyId);
            }
        };

        if (!gameIsCompleted) {
            const intervalId = setInterval(fetchJoinDataInterval, 3000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, []);

    async function fetchPlayerData(buddyId) {
        try {
            const joinPlayer = await getDataWithToken(BUDDYGAMEBYID + buddyId);
            var users = joinPlayer.users;
            if (users) {
                const highestPoint = Math.max(
                    ...users
                        .filter(user => user.status === 'Ready')
                        .map(user => user.burnedCal)
                );

                const updatedUsers = users.map(user => ({
                    ...user,
                    highest: user.status === 'Ready' && user.burnedCal === highestPoint,
                }));
                setUsers(updatedUsers);
                setPlayersCount(updatedUsers.length);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const createLobby = async (gameId) => {
        setLoading(true);
        try {
            closeGameModal();
            if (gameId) {
                const gameData = {
                    "game": gameId,
                    "name": lobbyName
                }
                const response = await postDataWithToken(BUDDYGAME, gameData);
                if (response.status === 200) {
                    localStorage.setItem("buddyId", response.data._id);
                    fetchPlayerData(response.data._id);
                    setBuddyCode(response.data.code);
                    setLobbyName(response.data.name);
                    setGameTitle(response.data.game.title);
                }
                setLoading(false);
            } else {
                const response = await getData(GETGAMES);
                setGameIsCompleted(false);
                setUsers([]);
                setGameData(response.data);
                setFilteredGames(response.data);
                setShowGameModal(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('Error fetching data:', error);
        }
    };

    const burnedCalories = [350, 350, 345];

    const handleGoBurn = async () => {

        const payloadBuddyData = {
            buddyGameId: buddyId,
            burnedCalories: burnedCalories,
        };

        const buddyPlayerUpdateScore = await postDataWithToken(BUDDYGAMEUPDATESCORE, payloadBuddyData);
        console.log(buddyPlayerUpdateScore);
        setIsCompleted(true);

        var data = {
            "gameId": groupGameId,
            "status": 'Playing'
        }
        await postDataWithToken(GAMEUPDATESTATUS, data);

        var gameName = document.getElementById('gameTitle').value;
        const playerName = userData.fname + '_' + userData.lname;
        window.location.href = `uniwebview://openGame?name=${gameName}&room=${buddyCode}&playerName=${playerName}`;
    }

    // End of the Game
    window.onGameClosed = async function (score, time, heartbeat, accuracy) {
        handleGameClosed(score);
    };

    const shareCode = () => {
        window.location.href = `uniwebview://shareCode?room=${buddyCode}`;
    };

    const handleGameClosed = async (score) => {
        try {
            // Update User Status 
            var userScore = {};
            userScore.id = userData._id;
            userScore.burnedCal = score;
            userScore.status = 'Ready';
            setGameStatusMsg(true);

            // Group Status Update
            const data = {
                "gameId": groupGameId,
                "status": 'Online'
            }
            await postDataWithToken(GAMEUPDATESTATUS, data);

            const payloadBuddyData = {
                buddyGameId: buddyId,
                user: userScore,
            }
            const buddyPlayerUpdateScore = await postDataWithToken(BUDDYGAMEUPDATE, payloadBuddyData);
            const playerData = buddyPlayerUpdateScore.data['users'];
            const allComplete = playerData.every(player => player.status === "Ready");

            // IsComplete Update 
            if (allComplete) {
                const completedStatus = {
                    buddyGameId: buddyId,
                    isCompleted: true
                }
                setGameIsCompleted(true);
                setLoading(false);
                fetchPlayerData(buddyId);
                setGameStatusMsg(false);
                localStorage.removeItem("buddyId");
                await postDataWithToken(BUDDYGAMEUPDATE, completedStatus);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const closeGameModal = () => {
        setShowGameModal(false);
    };


    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        const filtered = gamesData.filter(game => game.title.toLowerCase().includes(e.target.value.toLowerCase()));
        setFilteredGames(filtered);
    }

    const handleCopyCode = (event) => {
        inputRef.current.select();
        document.execCommand('copy');
        inputRef.current.setSelectionRange(0, 0);
    };

    const currentUser = users.find(user => user.user === userData._id);
    const userStatus = currentUser ? currentUser.status : null;

    const handleReady = async () => {
        try {
            const updatedData = {
                buddyGameId: buddyId,
                user: {
                    id: currentUser._id,
                    status: 'Ready',
                },
            };
            await postDataWithToken(BUDDYGAMEUPDATESTATUS, updatedData);

        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            <BackButton label={t('Home')} closeFunction={() => navigate(`/${HOME}`)} />
            <div className='buddy_tournament_wrapper'>
                <h3 className='fw-bold mt-3 text-white opacity-75'>{t('Buddy Tournament')}</h3>
                <p className='mb-0 pb-2 text-white opacity-50'>The standard Lorem Ipsum passage, used since the 1500s <br /> "Lorem ipsum dolor sit amet, consectetur adipiscing elit, The standard Ipsum passage, used since the 1500s</p>
                <div className='mt-4 mb-3 position-relative'>
                    <input type='text' className='w-100 text-light px-3' id='buddyId' name='BuddyId' placeholder={t('Your buddy ID')} value={buddyCode} ref={inputRef} readOnly />
                    <span className='bg-info copy position-absolute rounded-circle'>
                        <IoMdCopy size={18} className='text-white' onClick={handleCopyCode} />
                    </span>
                </div>
                <button type='button' className='cta_button text-white bg-info d-flex align-items-center justify-content-center border-0 px-3' onClick={shareCode} >
                    <FiShare size={18} />
                    <span className='ms-2'>{t('Share invite')}</span>
                </button>
            </div>
            {/* GAME RESULT */}
            <div className='lobby_wrapper'>
                <h3 className='text-white fw-bold mb-3 text-white opacity-75'>{(lobbyName) ? lobbyName.charAt(0).toUpperCase() + lobbyName.slice(1).toLowerCase() : ''}{t('Lobby Members')}</h3>
                <div className='members_list px-4 py-3'>
                    {users.map((user, index) => {
                        return (
                            <div key={user._id} className={`item d-flex align-items-center gap-2`}>
                                <div className='member_profile rounded-circle position-relative'>
                                    <h6 className='text-white mb-0'>{user.user !== null ? user.name.split(" ").slice(0, 2).map(word => word[0].toUpperCase()).join("") : 'P'}</h6>
                                </div>
                                <p className='mb-0 text-white user_name'>{user.user !== null ? user.name : 'Player_' + index}</p>
                                <p className='mb-0 ms-auto user_status opacity-75'>{(user.status !== 'Completed') ? user.userStatus : user.burnedCal}</p>
                                <div className={`d-flex align-items-center justify-content-center gap-1 game_status ${(user.status == 'Waiting') ? 'waiting' : ''}`}>
                                    {(user.status !== 'Waiting') ? <span className='rounded-circle'>< FaCheck /></span> : null}
                                    <p className='mb-0'>{(user.status == 'Waiting') ? t('Waiting...') : t(user.status)}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {gameIsCompleted ? (
                    <button type='button' className='mx-auto mt-4 cta_button d-flex align-items-center justify-content-center position-relative border-0 bg-warning'>
                        <span className='fw-bold'>{t('Create Lobby')}</span>
                        <GrFormNext size={20} />
                    </button>
                ) : (
                    (userStatus === 'Waiting' ? (
                        <button
                            type='button'
                            className='mx-auto mt-4 cta_button d-flex align-items-center justify-content-center position-relative border-0 bg-warning'
                            onClick={handleReady}
                        >
                            <span className='fw-bold text-dark'>{t('Ready')}</span>
                        </button>
                    ) : (
                        <button
                            type='button'
                            className='mx-auto mt-4 cta_button d-flex align-items-center justify-content-center position-relative border-0 bg-warning'
                            onClick={handleGoBurn}
                            disabled={playersCount < 3 || !users.every(user => user.status === 'Ready')}
                        >
                            <span className='fw-bold text-dark'>{t('Go Burn Calories')}</span>
                            <GrFormNext size={20} />
                        </button>
                    ))
                )}

                <p className='mb-0 mt-4 pt-2 text-warning text-center'>
                    {(playersCount === true) ? t('Waiting for result...') : (playersCount < 3) ? t('Waiting for players to join...') : ''}
                </p>
            </div>

            {/* GAME modal */}
            <div className={`modal ${showGameModal ? 'show' : ''}`} style={{ display: showGameModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal_content tournament_modal">
                        <div className="modal-body p-3">
                            <h3 className='mb-3 fw-bold text-white'>{t('Game List')}</h3>
                            <div className='form_field position-relative mb-2'>
                                <input type="text" name="lobby_name" value={searchTerm} onChange={handleInputChange} className='w-100 px-3' placeholder={t('Game name')} />
                            </div>
                            {filteredGames.length > 0 ? (
                                filteredGames.map((game, index) => (
                                    userData != null && userData.levelData != null && ((userData.levelData.current == 'Copper' && game.minlevel === 'Copper') || (userData.levelData.current === 'Bronze' && (game.minlevel === 'Copper' || game.minlevel === 'Bronze')) || (userData.levelData.current === 'Silver' && (game.minlevel !== 'Gold' || game.minlevel !== 'Platinum')) || (userData.levelData.current === 'Gold' && game.minlevel !== 'Platinum') || (userData.levelData.current === 'Platinum')) ?
                                        <div key={game._id} className={`d-flex game_wrapper align-items-start justify-content-end flex-wrap px-0 ${index + 1 < gamesData.length ? '' : 'border-0'}`}>
                                            <img src={(IMAGE_API_URL + game.image)} alt="Logo" />
                                            <div className='game_info w-100'>
                                                <h5 className='mb-0 text-capitalize'>{game.title}</h5>
                                                <h6 className={`text-white mb-1
                                            ${(game.minlevel === 'Bronze' || game.minlevel === 'Copper') ? 'verus_tournament'
                                                        : game.minlevel === 'Silver' ? 'paid_tournament'
                                                            : game.minlevel === 'Gold' ? 'free_tornament'
                                                                : game.minlevel === 'Platinum' ? 'achievement_tournament'
                                                                    : 'bg-primary'}`}>{t(game.minlevel)}</h6>
                                                <div dangerouslySetInnerHTML={{ __html: game.description }}></div>
                                            </div>
                                            <div className='play_button_Wrapper'>
                                                <button type='button' onClick={() => createLobby(game._id)}>{t('Play')}< IoIosArrowForward /></button>
                                            </div>
                                        </div>
                                        : ''
                                ))
                            ) : (
                                <div className="text-center text-warning my-5">{t('Game Not found')}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Result modal */}
            <div className={`modal ${isCompleted ? 'show' : ''}`} style={{ display: isCompleted ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className='members_list px-4 py-3'>
                        {
                            users.map((user, index) => {
                                return (
                                    <div key={user._id} className={`item d-flex align-items-center gap-2 ${user.highest ? 'toper_user' : ''}`}>
                                        <p className='mb-0 text-white user_name'>{user.name}</p>
                                        <p className='mb-0 ms-auto user_status'>{user.burnedCal}</p>
                                        {(user.highest) ? <FaCrown className='position-absolute' /> : ''}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <input type='hidden' id='gameTitle' value={gameTitle} />
            {(showGameModal || isCompleted) && <div className="modal-backdrop show"></div>}
        </div>
    );
}

export default BuddyTournamentCreate;