import React, { useState, useEffect } from 'react';
import Bottombar from '../common/bottombar';
import { FaEdit } from 'react-icons/fa';
import { LOGIN, UPDATEAVTAR, UPDATEUSER } from '../helpers/Constant';
import { FiArrowUpRight } from 'react-icons/fi';
import { Line } from "react-chartjs-2";
import { IoIosArrowForward, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { BiInfoCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { getUserType, setSession, getUserInfo, checkAuth } from '../utils/common';
import { GETDAILYBMI, GETDAILYREPORT, BMICALCULATOR, GETLEVELREPORT, GETUSERACCOUNTHISTORIES, DETAILED } from '../helpers/Constant';
import { postDataWithToken, updateData, getDataWithToken } from '../api/api';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BackButton from '../common/backButton';

function Profile() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userType = getUserType();
    const AVTAR_IMAGE_URL = `${process.env.REACT_APP_BASE_PATH}/admin/public/avtar/`;
    const [formErrors, setFormErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState('');
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const [steps, setSteps] = useState(0);
    const [targetAverage, setTargetAverage] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [levels, setLevels] = useState([]);
    const [winningAmount, setWinningAmount] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showLevelInfoModal, setShowLevelInfoModal] = useState(false);
    const [physicalActivity, setPhysicalActivity] = useState(0);
    const [physicalActivityTimeFormat, setPhysicalActivityTimeFormat] = useState('min');
    const [selectedLocation, setSelectedLocation] = useState();
    const [activityIcon, setActivityIcon] = useState(false);
    const year = moment().format('YYYY');
    const currentMonth = moment().format('M');
    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const user = getUserInfo();
        setUserData(user);
        async function fetchData() {
            if (user) {
                const location = (user && user.address1) ? user.address1 : '';
                setSelectedLocation(location);

                const levelNames = ["Copper", "Bronze", "Silver", "Gold", "Platinum"];
                const levelMapping = {};
                levelNames.forEach((level, index) => {
                    levelMapping[level] = index + 1;
                });

                const userlevelNumber = levelMapping[user.levelData.current];
                setCurrentLevel(userlevelNumber);

                const limit = { limit: 0 };
                const userAccounts = await postDataWithToken(GETUSERACCOUNTHISTORIES, limit);
                if (userAccounts.status == 200) {
                    const data = userAccounts['data'];
                    if (data.length > 0) {
                        setWinningAmount(data[0]['winningAmount']);
                    }
                }

                const data = {
                    "date": moment().format('MM-DD-YYYY')
                };

                const stepsData = await postDataWithToken(GETDAILYBMI, data);
                if (stepsData.status == 200) {
                    if (stepsData.data) {
                        const physicalActivity = stepsData.data.physicalActivity;
                        const hours = Math.floor(physicalActivity / 3600);
                        if (hours !== 0) {
                            var actityTime = hours;
                            var activityTimeFormat = (actityTime <= 1) ? 'hr' : 'hrs';
                        } else {
                            var actityTime = physicalActivity;
                            var activityTimeFormat = (actityTime <= 1) ? 'min' : 'mins';
                            if (actityTime >= 30) {
                                setActivityIcon(true);
                            }
                        }
                        setPhysicalActivityTimeFormat(activityTimeFormat);
                        setPhysicalActivity(actityTime);
                        setSteps(stepsData.data.steps);
                    }
                } else {
                    console.log(stepsData.message);
                }

                const dailyTargetData = await getDataWithToken(GETDAILYREPORT);
                if (dailyTargetData.length > 0) {
                    var averageTarget = [];
                    for (var i = 0; i < currentMonth; i++) {
                        var sumOfTarget = 0;
                        var targetSum = 0;
                        var firstDateOfMonth = moment({ year: year, month: i }).format('MM-DD-YYYY');
                        dailyTargetData.forEach((data) => {
                            const date = moment(data.createdAt).format('MM-DD-YYYY');
                            const isCurrentMonthAndYear = moment(date).isSame(firstDateOfMonth, 'month') && moment(date).isSame(firstDateOfMonth, 'year');
                            if (isCurrentMonthAndYear) {
                                sumOfTarget += data.target;
                                targetSum += 1;
                            }
                        });

                        const average = targetSum === 0 ? 0 : (sumOfTarget / targetSum).toFixed(2);
                        averageTarget.push(average);
                    }
                    setTargetAverage(averageTarget);
                }

                const userLevelData = await getDataWithToken(GETLEVELREPORT);
                if (userLevelData.length > 0) {
                    var levelsAllData = [];
                    var levelsCharts = [];
                    for (var i = 0; i < currentMonth; i++) {
                        var levelsData = [];
                        for (const data of userLevelData) {
                            const date = moment(data.createdAt).format('MM-DD-YYYY');
                            var firstDateOfMonth = moment({ year: year, month: i }).format('MM-DD-YYYY');
                            const isCurrentMonthAndYear = moment(date).isSame(firstDateOfMonth, 'month') && moment(date).isSame(firstDateOfMonth, 'year');
                            if (isCurrentMonthAndYear) {
                                levelsData.push(data);
                            }
                        }
                        levelsAllData.push(levelsData);
                    }
                    for (const levels of levelsAllData) {
                        var levelNumber = 0;
                        if (levels.length > 0) {
                            const platinumRecords = levels.filter(record => record.level === "Platinum");
                            if (platinumRecords.length > 0) {
                                levelNumber = levelMapping[platinumRecords[0].level];
                            } else {
                                const GoldRecords = levels.filter(record => record.level === "Gold");
                                if (GoldRecords.length > 0) {
                                    levelNumber = levelMapping[GoldRecords[0].level];
                                } else {
                                    const silverRecords = levels.filter(record => record.level === "Silver");
                                    if (silverRecords.length > 0) {
                                        levelNumber = levelMapping[silverRecords[0].level];
                                    } else {
                                        const bronzeRecords = levels.filter(record => record.level === "Bronze");
                                        if (bronzeRecords.length > 0) {
                                            levelNumber = levelMapping[bronzeRecords[0].level];
                                        } else {
                                            const copperRecords = levels.filter(record => record.level === "Copper");
                                            if (copperRecords.length > 0) {
                                                levelNumber = levelMapping[copperRecords[0].level];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        levelsCharts.push(levelNumber);
                    }
                    setLevels(levelsCharts);
                }

                setLoading(false);
            } else {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const openEditModal = () => {
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const openLevelInfoModal = () => {
        setShowLevelInfoModal(true);
    };

    const closeLevelInfoModal = () => {
        setShowLevelInfoModal(false);
    };

    const handleNavigation = () => {
        navigate(`/${DETAILED}`);
    }

    const handleGuestLogin = () => {
        setSession();
        navigate(`/${LOGIN}`);
    }

    const handleProfileChange = (e) => {
        setSelectedImage(e.target.files[0]);
    }

    const handleUpdateProfileForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const fnameRef = document.getElementById("fname");
        const lnameRef = document.getElementById("lname");
        const locationRef = document.getElementById("location");

        const formFields = [
            { ref: fnameRef, name: fnameRef.name, errorMsgName: "First name" },
            { ref: lnameRef, name: lnameRef.name, errorMsgName: "Last name" },
            { ref: locationRef, name: locationRef.name, errorMsgName: "Location" },
        ];

        const errors = {};

        formFields.forEach((field) => {
            if (field.ref.value === "" || field.ref.value === null) {
                errors[field.name] = `${field.errorMsgName} is required.`;
            }
        });

        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            setLoading(true);

            var userId = userData._id;

            if (selectedImage) {
                const avtarData = new FormData();
                avtarData.append('avtar', selectedImage);

                await postDataWithToken(UPDATEAVTAR + userId, avtarData);
            }
            let data = {
                "user": userId,
                "fname": fnameRef.value,
                "lname": lnameRef.value,
                "address1": locationRef.value
            }
            const response = await updateData(UPDATEUSER, data);
            if (response.status === 200) {
                setFormErrors({});
                const userInfo = await checkAuth();
                setUserData(userInfo);
                setLoading(false);
                closeEditModal();
            } else {
                setLoading(false);
                console.log(response.message);
            }
        }
    }

    const handleCalibrateTarget = () => {
        navigate(`/${BMICALCULATOR}`);
    }

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const bmiData = {
        labels: labels,
        datasets: [
            {
                data: targetAverage,
            },
        ],
    };

    const levelData = {
        labels: labels,
        datasets: [
            {
                data: levels,
            },
        ],
    };
    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            {userType === 'guest_user' &&
                <div className='guest_login_cta d-flex align-items-center justify-content-center h-100'>
                    <button className='mx-3 mb-5 mt-0 fw-bold text-secondary bg-warning' type='button' onClick={handleGuestLogin}>{t('Login / Sign Up')}</button>
                </div>}
            {userType === 'real_user' &&
                <>
                    {showEditModal || showLevelInfoModal ? (
                        <BackButton
                            label={t('Profile')}
                            className="position-absolute"
                            closeFunction={showEditModal ? closeEditModal : closeLevelInfoModal}
                        />
                    ) : null}
                    <div className='profile_screen_wrapper'>
                        <div className='profile_Section d-flex flex-column justify-content-center align-items-center position-relative bg-secondary'>
                            {userData && userData.avtar ?
                                <img src={AVTAR_IMAGE_URL + userData._id + "/" + userData.avtar} alt='Profile' className='profile_image rounded-circle border border-white bg-secondary' />
                                :
                                <div className='rounded-circle profile_image home_profile_img border border-white d-flex justify-content-center align-items-center avtar_alternate'>
                                    {userData.fname && userData.lname && (
                                        <h3 className='mb-0 text-white fw-bold text-uppercase'>{userData.fname[0]}{userData.lname[0]}</h3>
                                    )}
                                </div>
                            }
                            <h5 className='pt-2 fw-bold text-capitalize'>{userData != null ? userData.fname + ' ' + userData.lname : ''}</h5>
                            <h6 className="mb-2 text-light">{userData != null ? t(userData.address1) : ''}</h6>
                            <button className='edit-btn position-absolute text-light end-0 border-0 rounded-circle top-0 mt-2 me-2 text-center' onClick={openEditModal} ><FaEdit className='mb-1' size={16} /></button>
                        </div>
                        <div className='bmi_profile d-flex justify-content-center align-items-stretch text-white position-relative bg-secondary w-100 p-2 pt-4 rounded-3 mt-3'>
                            <div className='step-1 w-xs-50 text-center w-25 px-1 border-end d-flex justify-content-between flex-column'>
                                <h6 className='mb-0 mt-1 text-light'>{t('BMI')}</h6>
                                <div className='d-flex align-items-center justify-content-center gap-1'>
                                    <h5 className='my-2 fw-bold'>{userData.bmi}</h5>
                                    {(userData.bmi >= 25) ? <IoIosArrowDown size={14} color='F55A68' /> : <IoIosArrowUp size={14} className='text-success' />}
                                </div>
                            </div>
                            <div className='step-2 w-xs-50 text-center w-25 px-1 d-flex justify-content-between flex-column'>
                                <h6 className='mb-0 mt-1 text-light'>{t('Money Won')}</h6>
                                <div className='d-flex align-items-center justify-content-center gap-1'>
                                    <h5 className='my-2 fw-bold'>${(winningAmount).toFixed(2)}</h5>
                                    <IoIosArrowUp size={14} className='text-success d-none' />
                                </div>
                            </div>
                            <div className='step-3 w-xs-50 text-center w-25 px-1 border-end border-start d-flex justify-content-between flex-column'>
                                <h6 className='mb-0 mt-1 text-light'>{t('Steps')}</h6>
                                <div className='d-flex align-items-center justify-content-center gap-1'>
                                    <h5 className='my-2 fw-bold'>{steps}</h5>
                                    {(steps <= 5000) ? <IoIosArrowDown size={14} color='F55A68' /> : <IoIosArrowUp size={14} className='text-success' />}
                                </div>
                            </div>
                            <div className='step-4 w-xs-50 text-center w-25 px-1 d-flex justify-content-between flex-column'>
                                <h6 className='mb-0 mt-1 text-light'>{t('Avg Activity time')}</h6>
                                <div className='d-flex align-items-center justify-content-center gap-1'>
                                    <h5 className='my-2 fw-bold'>{physicalActivity}<span className='ms-1 text-light'>{physicalActivityTimeFormat}</span></h5>
                                    {(activityIcon === true) ? <IoIosArrowDown size={14} color='F55A68'/> : <IoIosArrowUp size={14} className='text-success' />}
                                </div>
                            </div>
                            <button className='position-absolute top-0 end-0 border-0 bg-transparent text-light'><FiArrowUpRight size={20} className='edit-icon' onClick={handleNavigation} /></button>
                        </div>
                        <div className='profile_content_wrapper your_daily_target position-relative bg-secondary mt-3'>
                            <div className='d-flex align-items-start justify-content-between gap-3'>
                                <div className='mb-3'>
                                    <h5 className='mb-1 text-white fw-bold opacity-75'>{t('Your Daily Target')}</h5>
                                    <h6 className='text-white opacity-75 px-2 mb-0'>{year}</h6>
                                </div>
                                <span className='m-0 text-warning fw-bold text_shadow_green d-flex align-items-center'><img src='../images/fireicon.svg' height={25} alt='fireicon' className='me-1'></img>{(Math.round(userData.dailyTarget)).toFixed(0)}</span>
                            </div>
                            <Line data={bmiData} options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltips: {
                                        enabled: false,
                                    },
                                },
                                elements: {
                                    point: {
                                        radius: 4,
                                        backgroundColor: '#daff00'
                                    },
                                    line: {
                                        backgroundColor: "black",
                                        borderColor: "#daff00",
                                        borderWidth: 2
                                    }
                                },
                                scales: {
                                    y: {
                                        border: {
                                            width: 1,
                                            color: '#daff00',
                                        },
                                        grid: {
                                            drawBorder: false,
                                            display: false,
                                        },
                                        ticks: {
                                            color: '#fff'
                                        },
                                        min: 1,
                                    },
                                    x: {
                                        border: {
                                            width: 1,
                                            color: '#daff00',
                                        },
                                        ticks: {
                                            color: (label, index) => {
                                                return (currentMonth > label.index) ? '#fff' : '#707070';
                                            },
                                        },
                                    }
                                },
                            }} />
                            <button type='button' className='d-block position-relative w-100 text-center mt-2 go_button text-white border-0 px-4' disabled={(userData.isTrialComplete) ? true : false} onClick={handleCalibrateTarget} >{t('Calibrate Daily Target')}<IoIosArrowForward color="white" size={22} className='arrow-icon position-absolute' /></button>
                        </div>
                        <div className='profile_content_wrapper level_progression position-relative bg-secondary mt-3'>
                            <div className='d-flex align-items-center mb-2'>
                                <h5 className='mb-0 text-white fw-bold opacity-75 text-nowrap'>{t('League Progression')}
                                    <BiInfoCircle className='text-light ms-2' onClick={openLevelInfoModal} />
                                </h5>
                                <img src={userData != null && userData.levelData ?
                                    (userData.levelData.current === 'Copper' ? '../images/copperbadge.png' :
                                        userData.levelData.current === 'Bronze' ? '../images/bronzebadge.png' :
                                            userData.levelData.current === 'Silver' ? '../images/silverbadge.png' :
                                                userData.levelData.current === 'Gold' ? '../images/goldbadge.png' :
                                                    '../images/platinumbadge.png') : '../images/platinumbadge.png'
                                } alt='Reward user' className='rounded-circle home_profile_img position-absolute profile_img mt-2' />
                            </div>
                            <h6 className='text-white opacity-75 mb-3 px-2'>{year}</h6>
                            <Line data={levelData} options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltips: {
                                        enabled: false,
                                    },
                                },
                                elements: {
                                    point: {
                                        radius: 4,
                                        backgroundColor: '#daff00'
                                    },
                                    line: {
                                        backgroundColor: "black",
                                        borderColor: "#daff00",
                                        borderWidth: 1
                                    }
                                },
                                scales: {
                                    y: {
                                        border: {
                                            width: 1,
                                            color: '#daff00',
                                        },
                                        min: 1,
                                        max: 5,
                                        ticks: {
                                            stepSize: 1,
                                            callback: function (value) {
                                                return `Leg 0${value}`
                                            },
                                            color: '#fff'
                                        },
                                    },
                                    x: {
                                        border: {
                                            width: 1,
                                            color: '#daff00',
                                        },
                                        ticks: {
                                            color: (label, index) => {
                                                return (currentMonth > label.index) ? '#fff' : '#707070';
                                            },
                                        },
                                    }
                                }
                            }} />
                        </div>
                    </div>
                    <div className={`modal ${showEditModal ? 'show' : ''}`} style={{ display: showEditModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content modal_content edit_profile_modal text-center bg-secondary p-3">
                                <div className="modal-body p-0">
                                    <h4 className='fw-bold mb-4 text-white'>{t('Edit Profile')}</h4>
                                    <form className='mt-4' onSubmit={handleUpdateProfileForm}>
                                        <div className='form_field position-relative profile_image_label my-4'>
                                            <label htmlFor='profileImage' >
                                                {userData && userData.avtar || selectedImage ?

                                                    <img
                                                        className="profile_image"
                                                        src={(selectedImage) ? URL.createObjectURL(selectedImage) : userData && userData.avtar ? AVTAR_IMAGE_URL + userData._id + "/" + userData.avtar : '../images/profile-user.png'}
                                                        width={110}
                                                        height={110}
                                                        alt="Profile"
                                                    />
                                                    :
                                                    <div className='rounded-circle  home_profile_img border border-white d-flex justify-content-center align-items-center avtar_alternate'>
                                                        {userData && userData.fname && userData.lname && (
                                                            <h5 className='mb-0 text-white fw-bold text-uppercase'>
                                                                {userData.fname[0] + userData.lname[0]}
                                                            </h5>
                                                        )}
                                                    </div>
                                                }
                                                <input
                                                    name='profileImage'
                                                    type="file"
                                                    id='profileImage'
                                                    className='d-none'
                                                    accept=".jpg, .jpeg, .png, .heic, image/heic"
                                                    onChange={handleProfileChange}
                                                />
                                                <p className='field_error'>{formErrors['profileImage']}</p>
                                            </label>
                                        </div>
                                        <div className='profile_form'>
                                            <div className='form_field position-relative'>
                                                <input type="text" className='w-100 ps-3 mb-1' id="fname" name="fname" defaultValue={(userData && userData.fname) ? userData.fname : ''} placeholder='First name' />
                                                <p className='field_error'>{formErrors['fname']}</p>
                                            </div>
                                            <div className='form_field position-relative'>
                                                <input type="text" className='w-100 ps-3 mb-1' id="lname" name="lname" defaultValue={(userData && userData.lname) ? userData.lname : ''} placeholder='Last name' />
                                                <p className='field_error'>{formErrors['lname']}</p>
                                            </div>
                                            <div className='form_field position-relative mb-4'>
                                                <select className="form-select form_select w-100 ps-3" id="location" name="location" value={selectedLocation} onChange={handleLocationChange}>
                                                    <option value="" disabled>{t('Location')}</option>
                                                    <option value="India">{t('India')}</option>
                                                </select>
                                                <p className='field_error'>{formErrors['location']}</p>
                                            </div>
                                            <button type="submit" className='w-100 fw-bold bg-warning text-dark cta_button' disabled={loading}>{t('Submit')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal ${showLevelInfoModal ? 'show' : ''}`} style={{ display: showLevelInfoModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content modal_content info_modal bg-secondary p-3 pb-4">
                                <div className="modal-body p-0">
                                    <h4 className='fw-bold mb-2 text-white text-center'>{t('How levels work?')}</h4>
                                    <p className='text-light mb-0'>The standard Lorem Ipsum passage, used since the 1500s "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(showLevelInfoModal || showEditModal) && <div className="modal-backdrop show"></div>}
                </>}
            <Bottombar />
        </div>
    )
}

export default Profile