export const VERSION = "v1";
export const API_URL = `${process.env.REACT_APP_BASE_PATH}/api/${VERSION}/`;

export const LOGIN = `login`;
export const HOME ='';
export const BMICALCULATOR = "bmicalculator";
export const TARGETRESULT ='targetresult';
export const TRAILASSESMENT ='trailassessment';
export const CREATEPROFILE ='createprofile';
export const GAME ='game';
export const FEEDS ='feeds';
export const PROFILE ='profile';
export const DETAILED ='detailed';
export const SETTING ='setting';
export const BUDDYTOURNAMENTCREATE ='buddytournamentcreate';
export const BUDDYTOURNAMENTJOIN ='buddytournamentjoin';
export const VERSUS ='versus';
export const TOURNAMENT ='tournament';
export const ACHIEVEMENT ='achievement';
export const DAILYTARGET ='dailytarget';
export const ACCOUNT ='account';
export const PAYMENTSUCCESS ='paymentSuccess';

export const LOGINWITHSOCIAL = `auth/loginwithsocial`;
export const GETUSERINFO = `user`;
export const LOGOUT = `auth/logout`;
export const DELETE = `auth/delete`;
export const CALCBMI = `user/calculateBMI`;
export const ADDTRAILASSESMENTDATA = `user/trialAssesment`;
export const BMIINFOAVERAGE ='bmiInfo/getAverage';
export const BMIPROGRESSION = 'bmiInfo/progression';
export const BUDDYGAME = 'buddygame';
export const BUDDYGAMEBYID = 'buddygame/';
export const BUDDYGAMEJOIN = 'buddygame/join';
export const BUDDYGAMEUPDATE = 'buddygame/update';
export const BUDDYGAMEUPDATESTATUS = 'buddygame/updatestatus';
export const BUDDYGAMEUPDATESCORE = 'buddygame/updatescore';
export const VERSUSFINDPLAYER = 'versusgame/findPlayer';
export const VERSUSCANCEL = 'versusgame/cancel';
export const VERSUSSCOREUPDATE = 'versusgame/update';
export const GETVERSESGAME = `versusgame/game/`;
export const GETGAMES ='settings/games';
export const GETBADGES ='settings/badges';
export const UPDATEUSER = `user/update`;
export const UPDATEUSERDETAIL = `user/updatedetail`;
export const GAMEUSER = `user/gameUsers`;
export const USERSTATUS = `auth/status`;
export const GETTRIALINFO = `settings/trialinfo`;
export const UPDATEAVTAR = `user/updateAvtar/`;
export const GETDAILYBMI = `bmiInfo/get/`;
export const GETDAILYREPORT = `report/bmidata/`;
export const GETLEVELREPORT = `report/levelProgress/`;
export const GAMEUPDATESTATUS = `game/updateStatus/`;
export const GAMEUPDATESCORE = `game/updateScore/`;
export const GETTOURNAMENT = `tournaments/filter/`;
export const GETTOURNAMENTBYID = `tournaments/tornament/`;
export const GETTOURNAMENTUSERBYID = `tournaments/leadeboard/`;
export const TOURNAMENTJOIN = `tournaments/join/`;
export const TOURNAMENTUPDATE = `tournaments/update/`;
export const GETUSERBADGES = `achivementBadges`;
export const GETUSERACCOUNTHISTORIES = `transactions/userAccountHistories`;
export const WITHDRAWREQUEST = `transactions/withdrawAmountRequest`;
export const PAYMENTINTENT = `transactions/paymentIntent`;
export const UPDATEPAYMENTINTENT = `transactions/paymentIntentUpdate`;
export const JOINCONTESTWALLET = `transactions/joinContestWallet`;
export const CHECKOUT = `transactions/checkout`;
export const AMOUNTDEPOSITREQUEST = `transactions/amountDepositRequest`;
export const GOOGLEFITAUTH = `syncApps/syncGoogleFit`;
export const GOOGLEFITDATA = `syncApps/syncGoogleFitData`;
export const FITBITAUTH = `syncApps/syncFitbit`; 
export const FITBITDATA = `syncApps/syncFitbitdata`;
export const GARMINAUTH = `syncApps/syncGarmin`;
export const GARMINDATA = `syncApps/syncGarmindata`;
export const STARVAAUTH = `syncApps/syncStarva`;
export const STARVADATA = `syncApps/syncStarvadata`;
