import { getUser, postDataWithToken } from "../api/api";
import {  GETUSERINFO, USERSTATUS } from "../helpers/Constant";

export const setSession = (user) => {
    if (user) {
        if (user.token) {
            localStorage.setItem("user_token", user.token.replaceAll('"', ''));
        }
        if (user._id) {
            localStorage.setItem("user_id", user._id);
        }
        localStorage.setItem("user_Trial", user.isTrialComplete);
        localStorage.setItem('user_info', JSON.stringify(user));
        localStorage.setItem('user_lng', user.language);
    } else {
        localStorage.removeItem("user_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem("user_id");
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_Trial');
    };
};

export const getToken = () => {
    return localStorage.getItem("user_token");
};

export const getUserID = () => {
    return localStorage.getItem("user_id");
}

export const getUserType = () => {
    return localStorage.getItem("user_type");
};

export const getUserTrial = () => {
    return localStorage.getItem("user_Trial");
};

export const setUserType = (type) => {
    localStorage.setItem("user_type", type);
};

export const getLanguage = () => {
    return localStorage.getItem('user_lng');
};
 
export const getUserInfo = (type) => {
    return JSON.parse(localStorage.getItem("user_info"));
};
export const checkAuth = async () => {
    var value = getToken();
    if(!value){
        setSession(null);
        return null;
    }else{
        const user = await getUser(GETUSERINFO);
        if (user.status !== 200) {
            setSession(null);
            return null;
        } else {
            setSession(user.data);
            return user.data;
        }
    }
};

export const updateStatus = async (status) => {
    await postDataWithToken(USERSTATUS, status);
}