import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { GrFormNext } from "react-icons/gr";
import { BsBank2 } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { IoMdExit } from "react-icons/io";
import { BiInfoCircle } from 'react-icons/bi';
import BackButton from '../common/backButton';
import { GETUSERACCOUNTHISTORIES, WITHDRAWREQUEST, PROFILE, HOME } from '../helpers/Constant';
import { getUserID, getUserInfo } from '../utils/common';
import { postDataWithToken } from '../api/api';
import { Oval } from 'react-loader-spinner';
import { CHECKOUT } from "../helpers/Constant";
import { postData } from "../api/api";
import { IoCloseSharp } from "react-icons/io5";

function Account() {
    const user = getUserInfo();
    const { t } = useTranslation();
    const [accountHistories, setAccountHistories] = useState([]);
    const [currentAmount, setCurrentAmount] = useState(0);
    const [winningAmount, setWinningAmount] = useState(0);
    const [requestFormErrors, setRequestFormErrors] = useState({});
    const [addMoneyFormErrors, setAddMoneyFormErrors] = useState({});
    const [showAddMoneyModal, setShowAddMoneyModal] = useState(false);
    const [AccountHistoriesModal, setAccountHistoriesModal] = useState(false);
    const [showWithdrawMoneyModal, setShowWithdrawMoneyModal] = useState(false);
    const [withdrawErrorMsg, setWithdrawErrorMsg] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [amount, setAmount] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    let tournamentIs = (location.state) ? location.state.tournamentIs : false;
    let tournamentFee = (location.state) ? location.state.amount : '';
    const accountHistoriesData = async () => {
        const userAccounts = await postDataWithToken(GETUSERACCOUNTHISTORIES);
        if (userAccounts.status == 200) {
            const data = userAccounts['data'];
            if (data.length > 0) {
                setCurrentAmount(data[0]['realAmount']);
                setWinningAmount(data[0]['winningAmount']);
                const userAccountHistories = data[0]['userAccountHistoryData'];
                if (userAccountHistories.length > 0) {
                    setAccountHistories(userAccountHistories);
                }
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (tournamentIs == true) {
                setShowAddMoneyModal(true);
            }
            if (user) {
                accountHistoriesData();
            }
        }
        fetchData();
    }, []);

    const closeAddMoneyModal = () => {
        setAddMoneyFormErrors({});
        setAmount('');
        setShowAddMoneyModal(false);

        window.history.replaceState(location.state, null);
        location.state = null;
    }
    const openAddMoneyModal = async () => {
        setShowAddMoneyModal(true);
    }

    const closeAccountHistoriesModal = () => {
        setAccountHistoriesModal(false);
    }

    const closeWithdrawMoneyModal = () => {
        setRequestFormErrors({});
        setAmount('');
        setShowWithdrawMoneyModal(false);
        setWithdrawErrorMsg(false);
    }

    const handleWithdrawRequestForm = async (e) => {
        e.preventDefault();
        const errors = {};

        const holderNameRef = document.getElementById("holderName");
        const accountNumberRef = document.getElementById("accountNumber");
        const reEnterAccountNoRef = document.getElementById("reEnterAccountNumber");
        const codeRef = document.getElementById("code");
        const amountRef = document.getElementById("amount");
        const ifscPattern = /^[A-Z]{4}[0][A-Z0-9]{6}$/;

        const formFields = [
            { ref: holderNameRef, name: holderNameRef.name, errorMsgName: "Enter account holder's name" },
            { ref: accountNumberRef, name: accountNumberRef.name, errorMsgName: "Enter the bank account number" },
            { ref: reEnterAccountNoRef, name: reEnterAccountNoRef.name, errorMsgName: "Enter the bank account number again" },
            { ref: codeRef, name: codeRef.name, errorMsgName: "Enter the IFSC code" },
            { ref: amountRef, name: amountRef.name, errorMsgName: "Enter the amount" },
        ];

        formFields.forEach((field) => {
            if (field.ref.value === "" || field.ref.value === null) {
                errors[field.name] = `${field.errorMsgName}`;
            }
        });
        if (accountNumberRef.value !== reEnterAccountNoRef.value) {
            errors[reEnterAccountNoRef.name] = `${"Account number does not match"}`;
        }

        if (amountRef.value !== "" && amountRef.value < 1) {
            errors[amountRef.name] = "Amount must be at least $1";
            errors[amountRef.name] = "Amount must be at least $1";
        }

        if (codeRef.value !== "" && !ifscPattern.test(codeRef.value)) {
            errors[codeRef.name] = "IFSC should be 4 uppercase letters followed by 7 letters or digits";
        }

        setRequestFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsDisabled(true);
            let data = {
                "accountNo": accountNumberRef.value,
                "code": codeRef.value,
                "holder_name": holderNameRef.value,
                "amount": amountRef.value
            }
            const userAccounts = await postDataWithToken(WITHDRAWREQUEST, data);

            if (userAccounts.status === 200) {
                accountHistoriesData();
                setRequestFormErrors({});
                closeWithdrawMoneyModal();
                setIsDisabled(false);
            } else {
                setIsDisabled(false);
                setWithdrawErrorMsg(userAccounts.message);
            }
        }
    };

    const handlerCode = async (e) => {
        const value = e.target.value;
        const errors = {};
        if (value) {
            const ifscPattern = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
            if (ifscPattern.test(value)) {
                errors[e.target.name] = '';
            } else {
                errors[e.target.name] = `${"IFSC should be 4 uppercase letters followed by 7 letters or digits"}`;
            }
        }
        setRequestFormErrors(errors);
    };

    const handlerAccountNumber = async (e) => {
        const value = e.target.value;
        const errors = {};
        if (value) {
            const accountNumberPattern = /^[0-9]{9,18}$/;
            if (accountNumberPattern.test(value)) {
                errors[e.target.name] = '';
            } else {
                errors[e.target.name] = `${"Account number is not valid"}`;
            }
        }
        setRequestFormErrors(errors);
    };

    const handleAddMoney = async (e) => {
        e.preventDefault();

        const amountRef = document.getElementById("amount");

        if (amountRef.value === "" || amountRef.value === null) {
            setAddMoneyFormErrors({ [amountRef.name]: "Enter the amount" });

        } else if (amountRef.value < 1) {
            setAddMoneyFormErrors({ [amountRef.name]: "Amount must be at least $1" });

        } else {
            setAddMoneyFormErrors({});
            const data = {
                user_id: getUserID(),
                amount: amountRef.value
            }
            const response = await postData(CHECKOUT, data);
            if (response.url) {
                window.location.href = response.url;
            }
        }
    };

    const amountHandleChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^\d.]+/g, "");

        if (value.indexOf('.') > 0) {
            const parts = value.split('.');
            value = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }

        const isValid = /^\d*\.?\d{0,2}$/.test(value);

        if (!isValid) {
            return false;
        }

        setAmount(value);
    };

    return (
        <div className='wrapper h-100 overflow-hidden'>
            <div className='d-flex flex-column h-100'>
                {(!isDisabled) ?
                    (showWithdrawMoneyModal || AccountHistoriesModal) ?
                        <BackButton className="position-relative" label={t('Account')}
                            closeFunction={showWithdrawMoneyModal ? closeWithdrawMoneyModal : closeAccountHistoriesModal} />
                        :
                        <div className='d-flex align-items-center position-relative'>
                            {tournamentIs ?
                                <BackButton className="position-relative pb-0" closeFunction={() => navigate(`/${HOME}`)} /> :
                                <BackButton className="position-relative pb-0" closeFunction={() => navigate(`/${PROFILE}`)} />
                            }
                            <h5 className='mb-0 text-warning fw-bold position-absolute w-100 text-center'>{t('FitX')} <span className='text-white fs-6 opacity-75 fw-medium'>{t('Wallet')}</span></h5>
                        </div>
                    :
                    <div className="loader">
                        <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                    </div>
                }
                <div className='account_wrapper'>
                    <div className='row justify-content-between balance_box bg-secondary gx-2 px-2 py-4 my-4 mx-0'>
                        <div className='col-12'>
                            <div className='d-flex gap-3 justify-content-between align-items-center'>
                                <h6 className='text-white mb-0'>{t('Total Cash')} <BiInfoCircle size={15} className='text-white opacity-75' /></h6>
                                <p className='text-warning mb-0' onClick={() => setAccountHistoriesModal(true)}>{t('Transaction History')} <GrFormNext size={16} /></p>
                            </div>
                        </div>
                        <div className='col-12 my-4 py-2'>
                            <div className='d-flex gap-2 align-items-center'>
                                <div className='green_box h-auto bg-transparent rounded-circle d-flex align-items-center justify-content-center'>
                                    <div className='rounded-circle d-flex align-items-center justify-content-center'><h4 className='mb-0 text-primary fw-bold'>$</h4></div>
                                </div>
                                <h3 className='mb-0 text-success fw-bold lh-1'>{(currentAmount + winningAmount).toFixed(2)}</h3>
                            </div>
                        </div>
                        <div className='col-12 mb-4'>
                            <div className='d-flex justify-content-between align-items-center gap-3'>
                                <div className='d-flex align-items-center gap-2'>
                                    <div className='blue_box rounded-circle d-flex align-items-center justify-content-center'>
                                        <BsBank2 />
                                    </div>
                                    <div>
                                        <p className='mb-1 text-white opacity-50'>{t('Deposit Cash')}</p>
                                        <h6 className='text-white mb-0'>${(currentAmount).toFixed(2)}</h6>
                                    </div>
                                </div>
                                <div className='account_btn'>
                                    <button type='button' onClick={() => openAddMoneyModal()} className='cta_button bg-info d-flex align-items-center justify-content-center position-relative border-0'>
                                        <span className='fw-medium text-white'><FiPlus className='me-1 text-white' size={17} />{t('Add Cash')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center gap-3'>
                                <div className='d-flex align-items-center gap-2'>
                                    <div className='yellow_box rounded-circle d-flex align-items-center justify-content-center'>
                                        <FaCrown />
                                    </div>
                                    <div>
                                        <p className='mb-1 text-white opacity-50'>{t('Winnings Cash')}</p>
                                        <h6 className='text-white mb-0'>${(winningAmount).toFixed(2)}</h6>
                                    </div>
                                </div>
                                <div className='account_btn'>
                                    <button type='button' onClick={() => setShowWithdrawMoneyModal(true)} className='cta_button bg-warning d-flex align-items-center justify-content-center position-relative border-0 d-none'>
                                        <span className='fw-bold'><IoMdExit className='me-1' size={17} />{t('Withdraw')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* add money modal */}
                <div className={`d-flex flex-grow-1 align-items-end add_cash_form ${showAddMoneyModal ? 'open' : ''}`}>
                    <form className='profile_form modal_content m-0 p-4 position-absolute start-0 end-0' autoComplete='off' onSubmit={handleAddMoney}>
                        <div className='d-flex align-items-start justify-content-between gap-3 mb-4'>
                            <h6 className='text-white mb-0'>{(tournamentIs === true) ? `${t('To continue, Add')} $${tournamentFee} ${t('in your wallet.')}` : t('Enter amount to add In wallet.')}</h6>
                            <IoCloseSharp onClick={() => closeAddMoneyModal()} size={20} color='#FFFFFF99' />
                        </div>
                        <div className='d-flex align-items-end gap-3'>
                            <div className='position-relative w-100'>
                                <input type="text" className='w-100 px-3' id="amount" name="amount" placeholder={t('Amount')} value={(tournamentIs == true) ? tournamentFee : amount} readOnly={tournamentIs} onChange={amountHandleChange} />
                                <p className='field_error'>{t(addMoneyFormErrors['amount'])}</p>
                            </div>
                            <button type='submit' className='cta_button bg-info text-white'>{t('Add')}</button>
                        </div>
                    </form>
                </div>

                {/* Withdraw money modal */}
                {(showWithdrawMoneyModal) && <div className={`modal balance_modal ${showWithdrawMoneyModal ? 'show' : ''}`} style={{ display: showWithdrawMoneyModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content modal_content p-3 account_modal">
                            <div className="modal-body p-0">
                                <h4 className="fw-bold mb-0 text-white opacity-75 mt-2 mb-4 text-center">{t('Withdraw money')}</h4>
                                <form className='profile_form gx-3' onSubmit={handleWithdrawRequestForm}>
                                    <div className='row g-3'>
                                        <div className='col-12'>
                                            <div className='position-relative'>
                                                <input type="text" className='w-100 px-3' id="holderName" name="holderName" placeholder='Holder name' />
                                                <p className='field_error'>{t(requestFormErrors['holderName'])}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='position-relative'>
                                                <input type="text" className='w-100 px-3' id="accountNumber" name="accountNumber" placeholder='Account number' onChange={(e) => handlerAccountNumber(e)} />
                                                <p className='field_error'>{t(requestFormErrors['accountNumber'])}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='position-relative'>
                                                <input type="text" className='w-100 px-3' id="reEnterAccountNumber" name="reEnterAccountNumber" placeholder='Re-enter account number' />
                                                <p className='field_error'>{t(requestFormErrors['reEnterAccountNumber'])}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='position-relative'>
                                                <input type="text" className='w-100 px-3 pe-2' id="code" name="code" placeholder='IFCE code' onChange={(e) => handlerCode(e)} />
                                                <p className='field_error'>{t(requestFormErrors['code'])}</p>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='position-relative'>
                                                <input type="text" className='w-100 px-3' id="amount" name="amount" placeholder='Amount' value={amount} onChange={amountHandleChange} />
                                                <p className='field_error'>{t(requestFormErrors['amount'])}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 mb-3 mt-4 pt-2 text-center'>
                                            <button type='submit' className='fw-bold' disabled={(isDisabled) ? isDisabled : false}>{t('Request sent')}</button>
                                        </div>
                                        {(withdrawErrorMsg) && <div className='col-12 text-center my-2'><p className='mb-0 text-white'>{t(withdrawErrorMsg)}</p></div>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>}

                {/* Account Histories modal */}
                {(AccountHistoriesModal) && <div className={`modal balance_modal ${AccountHistoriesModal ? 'show' : ''}`} style={{ display: AccountHistoriesModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content modal_content p-3 account_modal">
                            <div className="modal-body p-0">
                                <h4 className="fw-bold mb-0 text-white opacity-75 mt-2 mb-4 text-center">{t('Transaction History')}</h4>
                                <div className="transaction_section d-flex flex-column">
                                    {
                                        (accountHistories.length > 0) ?
                                            <div>
                                                {accountHistories.map((accountHistory) => (
                                                    <div key={accountHistory._id} className="d-flex gap-1 rounded mb-2">
                                                        <span className="text-white opacity-25">{moment(accountHistory.createdAt).format('h:mm A')}</span>
                                                        <span className="text-white opacity-25">{moment(accountHistory.createdAt).format('DD/MM/YYYY')}</span>
                                                        <span className="text-white opacity-50 transaction_detail">${accountHistory.amount} {t(accountHistory.description)}</span>
                                                        <span className={`text-end ms-auto text-capitalize ${accountHistory.status === 'Failed' ? 'failed' : accountHistory.status === 'Pending' ? 'pending' : 'text-success'}`}>{t(accountHistory.status)}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="d-flex align-items-center justify-content-center">
                                                <h6 className="text-light text-center my-5 pb-4">{t("You haven't made any transactions yet.")}</h6>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {(showWithdrawMoneyModal || AccountHistoriesModal) && <div className="modal-backdrop show"></div>}
            </div >
        </div >
    );
}

export default Account;