import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegUser } from 'react-icons/fa';
import { IoGameControllerOutline } from 'react-icons/io5'
import { LiaHomeSolid } from 'react-icons/lia'
import { FiSettings } from 'react-icons/fi'
import { GAME, HOME, PROFILE, SETTING, DETAILED } from '../helpers/Constant'
import { useTranslation } from 'react-i18next';


const BottomBar = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="bottombar justify-content-evenly position-absolute bottom-0 w-100 start-0 d-flex bg-primary">
            <TabItem to={`/${GAME}`} icon={<IoGameControllerOutline size={28} />}  label={t('Games')} active={location.pathname === `/${GAME}`} />
            <TabItem to={`/${HOME}`} icon={<LiaHomeSolid size={28} />} label={t('Home')}  active={location.pathname === `/${HOME}`} />
            <TabItem to={`/${PROFILE}`} icon={<FaRegUser size={28} />}  label={t('Profile')} active={location.pathname === `/${PROFILE}` || location.pathname === `/${DETAILED}`} />
            <TabItem to={`/${SETTING}`} icon={<FiSettings size={28} />} label={t('Settings')} active={location.pathname === `/${SETTING}`} />
        </div>
    );
};

const TabItem = ({ to, icon, label, active }) => ( 
    <Link to={to} className={`tab-item ${active ? 'active' : ''} d-flex flex-column justify-content-center align-items-center text-decoration-none w-25`}>
        {icon}
        <span className='text-nowrap overflow-hidden text-truncate w-75 text-center'>{label}</span>
    </Link>
);

export default BottomBar;
