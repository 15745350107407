import React, { useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import { BMIPROGRESSION } from '../helpers/Constant';
import { getUserType } from '../utils/common';
import { postDataWithToken } from '../api/api';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import BackButton from '../common/backButton';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const currentMonthNumber = moment().format('M');
const currentDate = moment().format('D');

function DailyTarget() {
    const { t } = useTranslation();
    const [ismonth, setIsMonth] = useState(true);
    const [today, setToday] = useState(0);
    const [thisMonth, setThisMonth] = useState(0);
    const [thisYear, setThisYear] = useState(0);
    const [average, setAverage] = useState([]);
    const [label, setLabel] = useState([]);
    const [averagePercentage, setAveragePercentage] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateArray, setDateArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const userType = getUserType();

    useEffect(() => {
        averageToggle(ismonth, selectedDate);
        calanderAverage(moment());
        setLoading(false);
    }, []);

    const yAxisCallback = (value) => `${value}%`;

    const formatNumber = (value) => {
        if (value < 1000) {
          return value.toString();
        } else if (value >= 10000) {
          return (value / 1000).toFixed(1) + 'k';
        } else {
          return Math.floor(value / 1000) + 'k';
        }
    }
      
    const averageToggle = async (ismonth, date) => {
        try {
            if (userType && userType !== 'guest_user') {
                let datePeriod = 'Month';
                let current = moment(date).endOf('month').format('D');
                const endOfMonth = moment().endOf('month').format('D');
                var labels = Array.from({ length: endOfMonth }, (_, index) => index + 1);

                if (ismonth) {
                    datePeriod = 'Year';
                    current = 12;
                    labels = Array.from({ length: 12 }, (_, index) => index + 1);
                }
                setLabel(labels);

                let bmiData = {
                    "datePeriod": datePeriod,
                    "date": moment(date).format('MM-DD-YYYY')
                };

                const bmiProgressionData = await postDataWithToken(BMIPROGRESSION, bmiData);
                if (bmiProgressionData.status === 200) {
                    var averageArr = [];
                    var averagePer = 0;
                    var burnedCount = 0;
                    if (bmiProgressionData.data.length > 0) {
                        var sumOfBurned = bmiProgressionData.data[0].sumOfBurned;
                        var sumOfTarget = bmiProgressionData.data[0].sumOfTarget;

                        setToday(bmiProgressionData.data[0].today);
                        setThisMonth(formatNumber(bmiProgressionData.data[0].current_month));
                        setThisYear(formatNumber(bmiProgressionData.data[0].current_year));
                    } else {
                        setToday(0);
                        setThisMonth(0);
                        setThisYear(0);
                    }

                    for (var i = 1; i <= current; i++) {
                        var average = 0;
                        if (bmiProgressionData.data.length > 0) {
                            if (sumOfBurned[i] && sumOfTarget[i]) {
                                average = ((sumOfBurned[i] / sumOfTarget[i]) * 100).toFixed(2);
                                burnedCount += 1;
                            }
                        }
                        averagePer += parseFloat(average);
                        averageArr.push(average);
                    }

                    setAveragePercentage(parseFloat(averagePer / burnedCount).toFixed(2));
                    setAverage(averageArr);
                } else {
                    console.log(bmiProgressionData.message);
                }
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    const calanderAverage = async (date) => {
        let bmiData = {
            "datePeriod": 'Month',
            "date": moment(date).format('MM-DD-YYYY')
        };
        const current = moment(date).endOf('month').format('D');
        const bmiProgressionData = await postDataWithToken(BMIPROGRESSION, bmiData);
        if (bmiProgressionData.status === 200) {
            var calanderDateArr = [];
            if (bmiProgressionData.data.length > 0) {
                var sumOfBurned = bmiProgressionData.data[0].sumOfBurned;
                var sumOfTarget = bmiProgressionData.data[0].sumOfTarget;
            }
            for (var i = 1; i <= current; i++) {
                if (bmiProgressionData.data.length > 0) {
                    if (sumOfBurned[i] && sumOfTarget[i]) {
                        var average = ((sumOfBurned[i] / sumOfTarget[i]) * 100).toFixed(2);
                        calanderDateArr.push({ 'date': i, 'average': average });
                    }
                }
            }
            setDateArray(calanderDateArr);
        } else {
            console.log(bmiProgressionData.message);
        }
    }

    const handleToggle = () => {
        setIsMonth(!ismonth);
        averageToggle(!ismonth, selectedDate)
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        averageToggle(ismonth, date);
    };

    const handleViewChange = ({ action, activeStartDate, value, view }) => {
        calanderAverage(activeStartDate);
    };

    const tileContentFunction = ({ date, view }) => {
        const day = moment(date).format('D');
        const data = dateArray.find((data) => data.date === parseInt(day) && view === 'month');
      
        if (data) {
            return (
                <div className='date rounded-circle position-absolute' role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ '--value': `${data.average}` }} />
            );
        }

        return null;
    };

    const minData = {
        labels: label,
        datasets: [
            {
                data: average,
            },
        ],
    };

    const currentMonth = moment(selectedDate).format('MMMM');
    const currentYear = moment(selectedDate).format('YYYY');
    
    return (
        <div className='wrapper h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            <BackButton label={t('Home')}  />
            <div className='calorie_section_wrapper mt-1'>
                <h6 className='mb-3 text-white opacity-75 fw-bold'>{t('Overall calories burn')}</h6>
                <div className='calorie_section row position-relative text-center'>
                    <div className='col-4'>
                        <h6 className='text-light mb-0'>{t('Today')}</h6>
                        <span className='text-white pt-2 pb-3 fw-bold d-flex align-items-center justify-content-center gap-2 position-relative'>
                            <img src='../images/fireicon.svg' height={20} alt='fireicon' />
                            {today}
                        </span>
                    </div>
                    <div className='col-4 position-relative'>
                        <h6 className='text-light mb-0'>{t('This month')}</h6>
                        <span className='text-white pt-2 pb-3 fw-bold d-flex align-items-center justify-content-center gap-2 position-relative'>
                            <img src='../images/fireicon.svg' height={20} alt='fireicon' />
                            {thisMonth}
                        </span>
                        <h6 className='text-light mb-0 bottom-0 end-0 mb-2 me-2 position-absolute'>{t('Avg')}</h6>
                    </div>
                    <div className='col-4 position-relative'>
                        <h6 className='text-light mb-0'>{t('This year')}</h6>
                        <span className='text-white pt-2 pb-3 fw-bold d-flex align-items-center justify-content-center gap-2 position-relative'>
                            <img src='../images/fireicon.svg' height={20} alt='fireicon' />
                            {thisYear}
                        </span>
                        <h6 className='text-light mb-0 bottom-0 end-0 mb-2 me-2 position-absolute'>{t('Avg')}</h6>
                    </div>
                </div>
            </div>
            <div className='profile_content_wrapper mt-3 month_target_chart position-relative'>
                <div className='row'>
                    <div className='col-8'>
                        <h5 className="mb-1 text-white fw-bold opacity-75">{t('Avg Daily Target')}</h5>
                        <h6 className='text-white opacity-75 mb-3 px-2'>{currentMonth + ' - ' + currentYear}</h6>
                    </div>
                    <div className='col-4 text-end'>
                        <span className='m-0 text-warning fw-bold text_shadow_green'>{(isNaN(averagePercentage)) ? 0 : averagePercentage}%</span>
                    </div>
                </div>
                <Bar data={minData} options={{
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: false,
                        },
                    },
                    elements: {
                        bar: {
                            backgroundColor: (label, index) => {
                                const value = label.raw;
                                if (value < 25) {
                                    return `#F55A68`;
                                } else if (value <= 65) {
                                    return `#FFD35D`;
                                } else {
                                    return `#10FF88`;
                                }
                            },
                            width: '2px'
                        },
                    },
                    scales: {
                        y: {
                            border: {
                                width: 2,
                                color: '#ffffff1a',
                            },
                            ticks: {
                                callback: yAxisCallback,
                            },
                        },
                        x: {
                            border: {
                                width: 2,
                                color: '#ffffff1a',
                            },
                            ticks: {
                                color: (label, index) => {
                                    return (ismonth ? currentMonthNumber : currentDate) > label.index ? '#fff' : '#707070';
                                },
                            },
                        }
                    }
                }} />
                <div className='toggle_button_wrapper  mt-2 text-end'>
                    <label className="toggle_button">
                        <input
                            type="checkbox"
                            checked={!ismonth}
                            onChange={handleToggle}
                        />
                        <div className={`slider ${!ismonth ? 'first' : 'second'}`}>
                            <div className="label text-white">Year</div>
                            <div className="label text-white">Month</div>
                        </div>
                    </label>
                </div>
            </div>
            <div className='calendar-container mt-3'>
                <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    tileContent={tileContentFunction}
                    selected={selectedDate}
                    onActiveStartDateChange={handleViewChange}
                />
            </div>
        </div >
    )
}

export default DailyTarget