import React from 'react';
import Login from '../auth/Login';
import BmiCalculator from '../pages/BmiCalculator';
import TraillAssessment from '../pages/TraillAssessment';
import TargetResult from '../pages/TargetResult';
import GameScreen from '../pages/Game';
import HomeScreen from '../pages/Home';
import BuddyTournamentCreate from '../pages/BuddyTournamentCreate';
import BuddyTournamentJoin from '../pages/BuddyTournamentJoin';
import VersusScreen from '../pages/Versus';
import ProfileScreen from '../pages/Profile';
import DetailedScreen from '../pages/Detailed';
import SettingScreen from '../pages/Setting';
import Tournament from '../pages/Tournament';
import Achievement from '../pages/Achivement';
import Dailytarget from '../pages/DailyTarget';
import Createprofile from '../pages/CreateProfile';
import Privateroute from '../routes/privateroute';
import Account from '../pages/Account';
import Bmiroute from './bmiroute';
import { Route, Routes } from 'react-router-dom';
import { LOGIN, BMICALCULATOR, TARGETRESULT, TRAILASSESMENT, CREATEPROFILE, GAME, PROFILE, DETAILED, SETTING, BUDDYTOURNAMENTCREATE, BUDDYTOURNAMENTJOIN, VERSUS, TOURNAMENT, ACHIEVEMENT, DAILYTARGET, ACCOUNT, PAYMENTSUCCESS } from '../helpers/Constant';
import PaymentSuccess from '../pages/PaymentSuccess';


const HomeRoutes = () => {
    return (
        <Routes>
            <Route path={`/${LOGIN}`} element={<Login />} />
            <Route element={< Privateroute />}>
                <Route element={<Bmiroute />}>
                    <Route path={`/${BMICALCULATOR}`} element={<BmiCalculator />} />
                    <Route path={`/${TARGETRESULT}`} element={<TargetResult />} />
                    <Route path={`/${TRAILASSESMENT}`} element={<TraillAssessment />} />
                    <Route path={`/${CREATEPROFILE}`} element={<Createprofile />} />
                </Route>
                <Route path={`/`} element={<HomeScreen />} />
                <Route path={`/${GAME}`} element={<GameScreen />} />
                <Route path={`/${PROFILE}`} element={<ProfileScreen />} />
                <Route path={`/${DETAILED}`} element={<DetailedScreen />} />
                <Route path={`/${SETTING}`} element={<SettingScreen />} />
                <Route path={`/${BUDDYTOURNAMENTCREATE}`} element={<BuddyTournamentCreate />} />
                <Route path={`/${BUDDYTOURNAMENTJOIN}`} element={<BuddyTournamentJoin />} />
                <Route path={`/${VERSUS}`} element={<VersusScreen />} />
                <Route path={`/${TOURNAMENT}`} element={<Tournament />} />
                <Route path={`/${ACHIEVEMENT}`} element={<Achievement />} />
                <Route path={`/${DAILYTARGET}`} element={<Dailytarget />} />
                <Route path={`/${ACCOUNT}`} element={<Account />} />
                <Route path={`/${PAYMENTSUCCESS}`} element={<PaymentSuccess />} />
            </Route>
        </Routes>
    );
}

export default HomeRoutes;