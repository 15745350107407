import React, { useState, useRef, useEffect } from 'react';
import StepProgressBar from '../common/stepBar';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CALCBMI, TRAILASSESMENT } from '../helpers/Constant';
import { getUserID } from '../utils/common';
import { postDataWithToken } from '../api/api';
import { Oval } from 'react-loader-spinner';
import DotsLoading from '../common/DotsLoading';
import { useTranslation } from 'react-i18next';

function BmiCalculator() {
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const [activeStep, setActiveStep] = useState(1);
    const [pendingStep, setPendingStep] = useState(2);

    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const [age, setAge] = useState(1);

    const [islbs, setIslbs] = useState(false);
    const [iscm, setIsCm] = useState(false);
    const [bmiCategory, setBmiCategory] = useState(null);
    const [isCalculating, setIsCalculating] = useState(true);
    const [bmiRes, setBmiRes] = useState(false);
    const [userData, setUserData] = useState(null);
    const [weightAPI, setWeightAPI] = useState();
    const [heightAPI, setHeightAPI] = useState();
    const [loading, setLoading] = useState(false);

    const setBmiInfoApi = async () => {
        setLoading(true);
        setIsCalculating(false);
        const userID = getUserID();
        let userBmiInfo = {
            "user": userID,
            "weight": JSON.stringify(weightAPI),
            "height": JSON.stringify(heightAPI),
            "age": JSON.stringify(age)
        };
        try {
            const response = await postDataWithToken(CALCBMI, userBmiInfo);
            if (response.status === 200) {
                const timer = setTimeout(() => {
                    setUserData(response.data);
                    setBmiRes(true);
                    clearTimeout(timer);
                    setActiveStep(2);
                    setPendingStep(0);
                }, 2000);
            } else {
                console.log(response.message);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const ages = Array.from({ length: 120 }, (_, index) => index + 1);

    const splideRef = useRef(null);

    const getAge = () => {
        if (splideRef.current) {
            const age = splideRef.current.splide.index + 1;
            setAge(age);
        }
    };

    useEffect(() => {
        if (splideRef.current) {
            splideRef.current.splide.on('move', () => {
                getAge();
            });
        }
    }, []);


    const calculateBMI = () => {
        setIsCalculating(true);
        setBmiRes(false);
        setBmiCategory(null);
        let weightInKg = islbs ? weight * 0.453592 : weight;
        let heightInCm = iscm ? height : height * 30.48;
        setWeightAPI(parseInt(weightInKg));
        setHeightAPI(parseInt(heightInCm));
    };

    useEffect(() => {
        if (userData !== null) {
            if (userData.bmi < 18.5) {
                setBmiCategory(t('You are Underweight'));
            } else if (userData.bmi >= 18.5 && userData.bmi < 24.9) {
                setBmiCategory('good');
            } else if (userData.bmi >= 25 && userData.bmi < 29.9) {
                setBmiCategory(t('You are Overweight'));
            } else {
                setBmiCategory(t('You are Obese'));
            }
        }
    }, [userData]);


    useEffect(() => {
        if (height > 0 && weight > 0) {
            calculateBMI();
        }
    }, [height, weight, islbs, iscm]);

    useEffect(() => {
        setActiveStep(1);
        setPendingStep(2);
    }, [isCalculating]);

    const handleHeightToggle = () => {
        setIsCm(!iscm);
        if (iscm) {
            setHeight((height / 30.48).toFixed(2));
        } else {
            setHeight((height * 30.48).toFixed(2));
        }
        calculateBMI();
    };

    const handleToggle = () => {
        setIslbs(!islbs);
        if (islbs) {
            setWeight((weight / 2.20462).toFixed(2));
        } else {
            setWeight((weight * 2.20462).toFixed(2));
        }
        calculateBMI();
    };

    const containerRef = useRef(null);

    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const containerWidth = container.offsetWidth;
            let scrollLeft = container.scrollLeft;

            if (scrollLeft + containerWidth >= container.scrollWidth) {
                container.scrollLeft = 0;
                scrollLeft = 0;
            } else if (scrollLeft === 0) {
                container.scrollLeft = container.scrollWidth - containerWidth;
                scrollLeft = container.scrollWidth - containerWidth;
            }

            const center = scrollLeft + 6 / 2;
            const newValue = Math.floor(center / 8);
            setWeight(newValue + 1);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const containerHeightRef = useRef();

    const handleHeightScroll = () => {
        const containerDiv = containerHeightRef.current;
        const containerHeight = containerDiv.clientHeight;
        const scrollTop = containerDiv.scrollTop;

        const centerPosition = scrollTop + containerHeight / 2;

        const elements = containerDiv.querySelectorAll('.scale-number');
        let closestIndex = 0;
        let closestDistance = Math.abs(centerPosition - elements[0].offsetTop);

        elements.forEach((element, index) => {
            const distance = Math.abs(centerPosition - element.offsetTop);
            if (distance < closestDistance) {
                closestIndex = index;
                closestDistance = distance;
            }
        });

        const selectedValue = elements[closestIndex].textContent;
        setHeight(selectedValue)
    };


    return (
        <div className='wrapper bg_paper px-0 pb-0 h-100'>
            {loading ?
                <div className="loader">
                    <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
                </div>
                : ""}
            <div className='d-flex flex-column'>
                <div className='px-3'>
                    <div className='d-flex justify-content-center'>
                        <StepProgressBar activeStep={activeStep} pendingStep={pendingStep} />
                    </div>
                    <div className='d-flex flex-column jutify-content-center'>
                        <div className='bmi_info_section'>
                            <h3 className='text-white fw-bold text-uppercase'>{t('BMI info')}</h3>
                            <p className='text-white opacity-75'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            </p>
                        </div>
                        <div className='select_weight_section mb-3 position-relative'>
                            <div className='d-flex justify-content-between'>
                                <h6 className='text-white fw-bold opacity-75'>{t('Weight')}</h6>
                                <h5 className='selected_result fw-bold'> {islbs ? `${weight} lbs` : `${weight} kg`}</h5>
                            </div>
                            <div className='position-absolute toggle_button_wrapper'>
                                <label className="toggle_button">
                                    <input
                                        type="checkbox"
                                        checked={!islbs}
                                        onChange={handleToggle}
                                    />
                                    <div className={`slider ${!islbs ? 'first' : 'second'}`}>
                                        <div className="label text-white">lbs</div>
                                        <div className="label text-white">kg</div>
                                    </div>
                                </label>
                            </div>

                            <div
                                className="number-container text-white"
                                ref={containerRef}
                                style={{
                                    width: '100%',
                                    overflowX: 'scroll',
                                    whiteSpace: 'nowrap',
                                    padding: '4px',
                                }}

                            >
                                <div
                                    className="number-list"
                                    style={{
                                        display: 'inline-block',
                                        width: '100%',
                                        paddingTop: '52px'
                                    }}
                                >
                                    {Array.from({ length: 150 }, (_, i) => i + 1).map((num) => (
                                        <div
                                            key={num}
                                            className='scale-number'
                                            style={{
                                                display: 'inline-block',
                                                width: '8px',
                                                textAlign: 'center',
                                                padding: '0px',
                                                paddingTop: '5px',
                                            }}
                                        >
                                            {num % 5 === 0 || num === 1 ? num : ''}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex gap-3'>
                            <div className='select_height_section position-relative'>
                                <h6 className='text-white fw-bold mb-1 opacity-75'>{t('Height')}</h6>
                                <h5 className='selected_result fw-bold'>{iscm ? `${height} cm` : `${height} ft`}</h5>
                                <div className='position-absolute toggle_button_wrapper'>
                                    <label className="toggle_button">
                                        <input
                                            type="checkbox"
                                            checked={!iscm}
                                            onChange={handleHeightToggle}
                                        />
                                        <div className={`slider ${!iscm ? 'first' : 'second'}`}>
                                            <div className="label text-white">cm</div>
                                            <div className="label text-white">ft</div>
                                        </div>
                                    </label>
                                </div>
                                <div
                                    className="number-container text-white position-absolute"
                                    ref={containerHeightRef}
                                    style={{
                                        width: '100%',
                                        overflowY: 'auto',
                                        whiteSpace: 'nowrap',
                                        padding: '4px',
                                        height: '158px',
                                    }}
                                    onScroll={handleHeightScroll}
                                >
                                    <div
                                        className="number-list"
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                        }}
                                    >
                                        {Array.from({ length: iscm ? 275 : 81 }, (_, i) => {
                                            if (iscm) {
                                                return i + 1;
                                            } else {
                                                return (i + 10) / 10;
                                            }
                                        }).map((num) => (<div
                                            key={num}
                                            className='scale-number'
                                            style={{
                                                textAlign: 'end',
                                                padding: '0px',
                                                padding: '1px 0',
                                                height: '8px',
                                                lineHeight: '8px',
                                                color: iscm ? (num % 5 === 0 || num === 1 || num === 275 ? 'white' : 'transparent') : (num % 0.5 === 0 || num === 1 ? 'white' : 'transparent'),
                                            }}
                                        >
                                            {num}
                                        </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                            <div className='select_age_section position-relative'>
                                <h6 className='text-white fw-bold position-absolute opacity-75'>{t('Age')}</h6>
                                <Splide ref={splideRef} className='p-0 age-slider' aria-label="My Favorite Images" options={{ direction: 'ttb', height: '12rem', rewind: true, pagination: false, arrows: false, type: 'loop', padding: '2rem', focus: 'center', }} >
                                    {ages.map(age => (
                                        <SplideSlide key={age} >
                                            <h1 className='m-0'>{age}</h1>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    height !== 0 && weight !== 0 ?
                        isCalculating ?
                            <div className='mt-4 text-center'>
                                <button className='next_button bg-warning fw-bold text-primary' onClick={() => setBmiInfoApi()}>{t('Calculate BMI')}</button>
                            </div>
                            :
                            <div className='bmi_result_wrapper bg-primary p-4 mt-3 flex-grow-1'>
                                <div className='bmi_result text-center'>
                                    <h5 className='text-light mb-0 pb-2'>{t('Your BMI')}</h5>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {bmiRes ?
                                            <h1 className='mb-0 text-white fw-bold'>{userData.bmi}</h1>
                                            :
                                            <span className='ring_loading'></span>
                                        }
                                    </div>
                                </div>
                                <h3 className={`mb-0 py-2 text-white ${bmiCategory !== 'good' ? 'opacity-75' : ''}`}>
                                    {bmiCategory === null ?
                                        <>{t('Calculating')}< DotsLoading width={20} color={'#c5c5c5'} /></> :
                                        bmiCategory !== 'good' ?
                                            bmiCategory :
                                            <span>{t('GOOD, Keep it up')}<img src='../images/thumbIcon.png' alt='Thumb Icon' /></span>
                                    }
                                </h3>
                                <p> {
                                    bmiRes ?
                                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim' :
                                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'}</p>
                                <button className='next_button bg-info fw-bold text-white d-block ms-auto' onClick={() => navigate(`/${TRAILASSESMENT}`)}><span className='me-3' >{t('Next')}</span><FaArrowRight className='position-absolute next_arrow text-white' size={14} /></button>
                            </div>
                        : null
                }
            </div>
        </div>

    )
}

export default BmiCalculator;