import React, { useEffect, useState } from 'react';
import { checkAuth, getUserType } from '../utils/common';
import { Outlet, useNavigate } from 'react-router-dom';
import { LOGIN, BMICALCULATOR } from '../helpers/Constant';

function Privateroute() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    let userType = getUserType();

    const checkLogin = async () => {
        if(userType !== "guest_user") {
            const user = await checkAuth();
            if (!user) {
                navigate(`/${LOGIN}`);
            } else {
                if(user.isTrialComplete === false){
                    navigate(`/${BMICALCULATOR}`);
                }
                setUserData(user);
            }
        }
    }
    
    useEffect(() => {        
        checkLogin();
    }, []);

    if (userData || userType === "guest_user") {
        return <Outlet />;
    } else {
        navigate(`/${LOGIN}`);
    }
}

export default Privateroute;

